import React from "react";
import "../../App.css";
import {
	getAllVessels,
	getAllTrips,
	getCatchSummary,
} from "../../api/summaries";

import { getUser } from "../../utils/loggedUser";

import {
	useLocation,
	useNavigate,
	useParams,
	useOutletContext,
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		let outletContext = useOutletContext();
		return (
			<Component
				{...props}
				router={{ location, navigate, params, outletContext }}
			/>
		);
	}
	return ComponentWithRouterProp;
}

class SummayPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			ShowForm: false,
			ShowDeleteForm: false,
			ShowOpenSiteForm: false,
			ShowSiteUsers: false,
			SelectedSite: null,
			ForcedRefresh: props.router.outletContext.refreshstate,
			loadedjs: 0,
		};
	}

	componentDidUpdate = () => {
		if (
			this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate
		) {
			this.setState({
				ForcedRefresh: this.props.router.outletContext.refreshstate,
				ShowForm: false,
				ShowDeleteForm: false,
				//ShowOpenSiteForm: false,
				loading: true,
				user: null,
			});
		}

		if (this.state.loading) {
			this.LoadAll();
			return;
		}

		if (this.state.loadedjs === 0) {
			this.loadjs();
		}
	};

	componentDidMount = async () => {
		if (this.state.loading) {
			this.LoadAll();
		}

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	};

	componentWillUnmount = async () => {
		window.$("#TweenMax-sites").remove();
		this.unloadjs();
	};

	unloadjs = () => {
		try {
			var script = document.createElement("script");
			script.id = "remove-scripts-sites";
			script.innerHTML = "jQuery('#table-1').DataTable().destroy();";
			document.body.appendChild(script);

			document.getElementById("custom-table-render-sites").remove();
			document.getElementById("remove-scripts-sites").remove();
			window.jQuery(".modal-backdrop")[0].remove();
		} catch (error) {}
	};

	loadjs = async () => {
		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.src = "/assets/js/table-custom-summary.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1,
		});
	};

	LoadAll = async () => {
		try {
			//get user
			var user = getUser();

			var vessels = await getAllVessels();

			this.setState({
				loading: false,
				vessels: vessels,
				trips: null,
				summary: null,
				ShowDeleteForm: false,
				user: user,
			});
		} catch (error) {
			console.log("Error.", error);
		}
	};

	LoadTrips = async (vesselID) => {
		try {
			//get user
			document.getElementById("tripSelect").selectedIndex = "0";

			this.unloadjs();
			var user = getUser();

			var trips = await getAllTrips(vesselID);
			this.setState({
				trips: trips,
				summary: null,
				ShowDeleteForm: false,
				user: user,
			});
			this.loadjs();
		} catch (error) {
			console.log("Error.", error);
		}
	};

	FilterSummary = async (vesselID, tripNumber) => {
		try {
			this.unloadjs();
			var user = getUser();

			var summary = await getCatchSummary(vesselID, tripNumber);

			this.setState({
				summary: summary,
				ShowDeleteForm: false,
				user: user,
			});

			this.loadjs();
		} catch (error) {
			console.log("Error.", error);
		}
	};

	CloseForm = async () => {
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseFormNoChanges = async () => {
		this.setState({
			ShowForm: false,
		});
	};

	CloseSiteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseDeleteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};
	CloseDeleteFormNoChanges = async () => {
		console.log("close->delete");
		this.setState({
			ShowDeleteForm: false,
		});
	};

	render() {
		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading Information</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "center", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		if (this.state.denied) {
			return (
				<div>
					<p>You do not have access to this page.</p>
				</div>
			);
		}

		// console.log("sites render!")

		return (
			<>
				<h2>Trip Catches Summary</h2>
				<div
					className="d-flex"
					style={{ marginTop: 40, marginBottom: 30, display: "flex" }}
				>
					<select
						type="text"
						className="form-control"
						id="vesselSelect"
						placeholder="Select Vessel"
						onChange={(e) => {
							this.setState({
								vessel: {
									...this.state.vessel,
									vesselID: e.target.value,
								},
							});

							this.LoadTrips(e.target.value);
						}}
						onKeyDown={(e) => this._handleKeyDown(e.key)}
					>
						<option value="0">Select Vessel</option>
						{this.state.vessels &&
							this.state.vessels.map((vessel) => (
								<option key={vessel.id} value={vessel.id}>
									{vessel.name}
								</option>
							))}
					</select>

					<select
						type="text"
						className="form-control"
						id="tripSelect"
						placeholder="Select Trip"
						onChange={(e) => {
							this.FilterSummary(this.state.vessel.vesselID, e.target.value);
						}}
						onKeyDown={(e) => this._handleKeyDown(e.key)}
					>
						<option value="0">Select Trip</option>
						{this.state.trips &&
							this.state.trips.map((trip) => (
								<option key={trip.tripNumber} value={trip.tripNumber}>
									{trip.tripNumber}
								</option>
							))}
					</select>
				</div>
				<table className="table table-bordered datatable" id="table-1">
					<thead>
						<tr>
							<th>Zone</th>
							<th>Set</th>
							<th>Specie</th>
							<th>Size</th>
							<th>Sampling Weight (kg)</th>
							<th>Num Units</th>
							<th>Percentage</th>
							<th>Weight (ton)</th>
						</tr>
					</thead>
					<tbody>
						{this.state.summary &&
							this.state.summary.length > 0 &&
							this.state.summary.map((data, index) => {
								return (
									<tr
										role={"row"}
										className={index % 2 === 0 ? "odd" : "even"}
										key={"formrow_" + index}
									>
										{/* <div key={"SiteRow_"+site.id} className={`d-flex align-items-center p-3 custom-border-bottom `} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite()}> */}
										<td>{data.FishingZone}</td>
										<td>{data.FishingSet}</td>
										<td>{data.Specie}</td>
										<td>{data.Size}</td>
										<td>{data.WeightSampling}</td>
										<td>{data.NumUnits}</td>
										<td>{data.Percentage}</td>
										<td>{data.Weight}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</>
		);
	}
}

export default withRouter(SummayPage);

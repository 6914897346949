//import react
import React from 'react';
import {setFormType, getFormType, getSite, setSite} from "../../utils/sitesForm"
import {savePort} from "../../api/ports"
import { ErrorNotification, SuccessNotification } from "../../utils/notifications"
import {getUser} from "../../utils/loggedUser"
import { saveFishSize } from '../../api/fishsizes';

class FormTemplate extends React.Component{
    constructor(props) {
		super(props);
		this.state = {
            loading: true,
            fishsize: {
                name: "",
                minWeight: "",
                maxWeight: "",
                WeightUnitCode: "Kg",
                minLength: "",
                maxLength: "",
                LengthUnitCode:"Cm",
                id: 0                
            },
            FormType: "", // "Create" or "Edit"
		}
	}

	componentDidUpdate = () => {
		if(this.state.loading)
		    this.LoadAll();	


        window.jQuery('#modal-6').modal('show', {backdrop: 'static'});
        
        // var script = document.getElementById("edit-script-modal-sites");

        // if(script){
        //     console.log("remove script!")
        //     script.remove();
        // }
        // var scriptx = document.createElement("script");
        // scriptx.id = "edit-script-modal-sites"
        // scriptx.innerHTML = "jQuery('#modal-6').modal('show', {backdrop: 'static'});";
        // document.body.appendChild(scriptx);
	}

	componentDidMount = () => {
        if(this.state.loading)
		    this.LoadAll();	
	}

	LoadAll = async () => {
        // this.PortNameInputText.focus();
        var FormType = getFormType();
        if(FormType === "Edit"){
            var site = getSite();
            this.setState({
                PortName: site.name,
                PortID: site.id,
            });
        }

        this.setState({
            loading: false,
            FormType: FormType
        });
	}

    CloseForm = async () => {
        //reset state
        this.setState({
            loading: true,
            fishsize: {},
            FormType: "", // "Create" or "Edit"
        });

        try {
            var scriptx = document.createElement("script");
            scriptx.id = "closemodal"
            scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
            document.body.appendChild(scriptx);

            document.findElementById("closemodal").remove();
        } catch (error) {
            
        }
        this.props.CloseForm();
    }

    Submit = async () => {
        var FormType = getFormType();

        await this.Save();
    }

    Save = async () => {

        if(this.state.fishsize.name === ""){
            ErrorNotification("error", "Name is required");
            return;
        }
        
        // if(this.state.fishsize.minWeight === ""){
        //     ErrorNotification("error", "Fao Code is required");
        //     return;
        // }

        // if(this.state.fishsize.maxWeight === ""){
        //     ErrorNotification("error", "Scientific Name is required");
        //     return;
        // }

        // if(this.state.fishsize.minLength === ""){
        //     ErrorNotification("error", "Minimum Length is required");
        //     return;
        // }

        // if(this.state.fishsize.maxLength === ""){
        //     ErrorNotification("error", "Maximum Length is required");
        //     return;
        // }

        await saveFishSize(
            this.state.fishsize,
            getUser().userID
        ).then(async (response) => {
            this.props.CloseForm();
        }).catch(async (error) => {
            ErrorNotification("error", error);
        })
    }

    PortNameChanged = (value) => {

        if(value.length > 35){
            ErrorNotification("error", "Site name cannot be more than 35 characters");
            return;
        }

        this.setState({
            PortName: value
        });
    }

    _handleKeyDown = (key) => {
		if (key === 'Enter') {
			this.Submit();
		}
	}



    render(){
        return (
			<div className="modal fade" id="modal-6">
				<div className="modal-dialog">
					<div className="modal-content">
						
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.props.CloseFormNoChanges}>&times;</button>
							<h4 className="modal-title">{this.state.FormType} Fish size</h4>
						</div>
						
						<div className="modal-body">
							<div className="row">
                                <div className="col-md-12">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Name:</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" value={this.state.fishsize.name} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishsize,
                                                    name: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginTop:10}}>
                                <div className="col-md-6">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Min Weight:</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Minimum weight" value={this.state.fishsize.minWeight} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishsize,
                                                    minWeight: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Max Weight:</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Maximum weight" value={this.state.fishsize.maxWeight} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishsize,
                                                    maxWeight: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row" style={{marginTop:10}}>
                                <div className="col-md-12">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Weight Unit:</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Code 3A" value={this.state.fishsize.minWeight} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishspecie,
                                                    minWeight: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="row" style={{marginTop:10}}>
                                <div className="col-md-6">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Min length:</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Minimum Length" value={this.state.fishsize.minLength} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishsize,
                                                    minLength: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Max length:</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Minimum Length" value={this.state.fishsize.maxLength} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishsize,
                                                    maxLength: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                            </div>
                            
						</div>
						
						<div className="modal-footer">
							<button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.props.CloseFormNoChanges}>Close</button>
							<button type="button" className="btn btn-info" onClick={this.Submit}>Save </button>
						</div>
					</div>
				</div>
			</div>
		)

        return (
            <div className={`modal modal-bg`} style={{display:'block', backgroundColor:'rgba(0,0,0,0.5)'}} tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered" style={{top:'30%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{float:'left'}}>{this.state.FormType} Site</h5>
                            <button type="button" style={{float:'right', width:20, height:20, padding: 0, margin:0}} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.CloseForm}>X</button>
                        </div>
                        <div className="modal-body custom-light-gray">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Name:</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" ref={(input) => this.PortNameInputText = input} value={this.state.PortName} onChange={(e) => this.PortNameChanged(e.target.value)} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-esip-blue py-2 px-4" onClick={this.Submit}>{"Save"}</button>
                        <button type="button" className="btn btn-secondary py-2 px-4" data-bs-dismiss="modal" onClick={this.CloseForm}>Close</button>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default (FormTemplate);
//import react
import React from "react";
import "../../App.css";
import FormTemplate from "./FormTemplate";
import SiteForm from "./SiteForm";
import DeleteForm from "./DeleteForm";
import {
	setFormType,
	setSite,
} from "../../utils/sitesForm";
import { getVessels } from "../../api/vessels";
import moment from "moment";
import { getUser } from "../../utils/loggedUser";
import SiteUsers from "./SiteUsers";
import {
	useLocation,
	useNavigate,
	useParams,
	useOutletContext,
	Navigate,
} from "react-router-dom";
import { AgGridReact } from "ag-grid-react";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		let outletContext = useOutletContext();
		return (
			<Component
				{...props}
				router={{ location, navigate, params, outletContext }}
			/>
		);
	}
	return ComponentWithRouterProp;
}

class VesselsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			sites: [],
			ShowForm: false,
			ShowDeleteForm: false,
			ShowOpenSiteForm: false,
			VesselHatchRedirect: false,
			ShowSiteUsers: false,
			SelectedSite: null,
			ForcedRefresh: props.router.outletContext.refreshstate,
			loadedjs: 0,
		};
	}

	componentDidUpdate = () => {
		// console.log("Refresh ", this.state.ForcedRefresh, " ->", this.props.router.outletContext.refreshstate);
		if (
			this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate
		) {
			this.setState({
				ForcedRefresh: this.props.router.outletContext.refreshstate,
				ShowForm: false,
				ShowDeleteForm: false,
				ShowOpenSiteForm: false,
				loading: true,
				user: null,
			});
		}

		if (this.state.loading) {
			this.LoadAll();
			return;
		}

		if (this.state.loadedjs === 0) {
			this.loadjs();
		}
	};

	componentDidMount = async () => {
		if (this.state.loading) {
			this.LoadAll();
		}

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	};

	componentWillUnmount = async () => {
		this.unloadjs();
	};

	unloadjs = () => {
		try {
			var script = document.createElement("script");
			script.id = "remove-scripts-sites";
			script.innerHTML = "jQuery('#table-1').DataTable().destroy();";
			document.body.appendChild(script);

			document.getElementById("custom-table-render-sites").remove();
			document.getElementById("remove-scripts-sites").remove();

			window.jQuery(".modal-backdrop")[0].remove();
		} catch (error) {}
	};

	loadjs = async () => {
		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.src = "/assets/js/table-custom.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1,
		});
	};

	LoadAll = async () => {
		try {
			//get user
			var user = getUser();

			var AllVessels = await getVessels();
			//console.log("AllVessels", AllVessels);

			var columns = [
				"Vessel Name",
				"Captain Name",
				"Vessel Length",
				"Crew Number",
				"License Number",
				"IMO Number",
				"AIS Code",
				"Avg Trip Days",
				"Avg Fishing Days",
				"Avg Coast Distance",
				"Fuel Capacity",
				"Water Capacity",
				"Generator 1 Capacity",
				"Generator 2 Capacity",
				"Created At",
				"Creator",
				"Activation Code",
				"Hatches",
				"Actions",
			];
			var gridcolumns = [];
			var avgColumns = [];
			var gridrows = [];
			var codesColumns = [];
			var creationColumns = [];
			var vesselColumns = [];
			var capacityColumns = [];
			for (var i = 0; i < columns.length; i++) {
				if (columns[i] === "Hatches") {
					gridcolumns.push({
						headerName: columns[i],
						field: columns[i],
						width: 150,
						resizable: true,
						cellRenderer: (params) => {
							return (
								<>
									<a
										href="#"
										className="btn btn-success btn-sm btn-icon icon-left"
										onClick={() => {
											console.log("params value ->", params.data.Hatch);
											this.VesselHatches(params.data.Hatch);
											// this.setState({
											// 	VesselHatchRedirect: true,
											// 	VesselToHatch: params.data.Hatch,
											// });
										}}
									>
										<i className="entypo-inbox"></i>
										Hatches
									</a>
								</>
							);
						},
					});
					continue;
				}

				if (columns[i] === "Actions") {
					gridcolumns.push({
						headerName: columns[i],
						field: columns[i],
						width: 250,
						resizable: true,
						cellRenderer: (params) => {
							return (
								<>
									<a
										href="#"
										className="btn btn-info btn-sm btn-icon icon-left"
										onClick={() => this.EditSite(params.value)}
									>
										<i className="entypo-pencil"></i>
										Edit
									</a>
									<a
										href="#"
										className="btn btn-danger btn-sm btn-icon icon-left"
										style={{ marginLeft: 10 }}
										onClick={() => this.DeleteSite(params.value)}
									>
										<i className="entypo-trash"></i>
										Delete
									</a>
								</>
							);
						},
					});
					continue;
				}

				if ((columns[i] + "").includes("Avg")) {
					avgColumns.push({
						field: columns[i],
						resizable: true,
						enableRowGroup: true,
						sortable: true,
						filter: true,
						columnGroupShow: avgColumns.length === 0 ? "" : "open",
					});

					if (avgColumns.length === 2) {
						//done
						gridcolumns.push({
							headerName: "Averages",
							children: avgColumns,
						});
					}

					continue;
				}

				if (
					(columns[i] + "").includes("License Number") ||
					(columns[i] + "").includes("IMO Number") ||
					(columns[i] + "").includes("AIS Code")
				) {
					codesColumns.push({
						field: columns[i],
						resizable: true,
						sortable: true,
						enableRowGroup: true,
						filter: true,
						columnGroupShow: codesColumns.length === 0 ? "" : "open",
					});

					if (codesColumns.length === 2) {
						//done
						gridcolumns.push({
							headerName: "Codes",
							children: codesColumns,
						});
					}

					continue;
				}

				if ((columns[i] + "").includes("Creat")) {
					creationColumns.push({
						field: columns[i],
						resizable: true,
						enableRowGroup: true,
						sortable: true,
						filter: true,
						columnGroupShow: creationColumns.length === 0 ? "" : "open",
					});

					if (creationColumns.length === 2) {
						//done
						gridcolumns.push({
							headerName: "Creation",
							children: creationColumns,
						});
					}

					continue;
				}

				if (
					(columns[i] + "").includes("Captain Name") ||
					(columns[i] + "").includes("Vessel Length") ||
					(columns[i] + "").includes("Crew Number")
				) {
					vesselColumns.push({
						field: columns[i],
						resizable: true,
						enableRowGroup: true,
						sortable: true,
						filter: true,
						columnGroupShow: vesselColumns.length === 0 ? "" : "open",
					});

					if (vesselColumns.length === 3) {
						//done
						gridcolumns.push({
							headerName: "Vessel",
							children: vesselColumns,
						});
					}

					continue;
				}

				if ((columns[i] + "").includes("Capacity")) {
					capacityColumns.push({
						field: columns[i],
						resizable: true,
						enableRowGroup: true,
						sortable: true,
						filter: true,
						columnGroupShow: capacityColumns.length === 0 ? "" : "open",
					});

					if (capacityColumns.length === 4) {
						//done
						gridcolumns.push({
							headerName: "Capacity",
							children: capacityColumns,
						});
					}

					continue;
				}

				gridcolumns.push({
					field: columns[i],
					resizable: true,
					enableRowGroup: true,
					sortable: true,
					filter: true,
				});
			}

			for (var i = 0; i < AllVessels.length; i++) {
				gridrows.push({
					"Vessel Name": AllVessels[i].name,
					"Captain Name": AllVessels[i].captainName,
					"License Number": AllVessels[i].licenseNumber,
					"IMO Number": AllVessels[i].imoNumber,
					"AIS Code": AllVessels[i].aisCode,
					"Vessel Length": AllVessels[i].vesselLength,
					"Avg Trip Days": AllVessels[i].avgTripDays,
					"Avg Fishing Days": AllVessels[i].avgFishingDays,
					"Avg Coast Distance": AllVessels[i].avgCoastDistance,
					"Fuel Capacity": AllVessels[i].fuelCapacity,
					"Water Capacity": AllVessels[i].waterCapacity,
					"Generator 1 Capacity": AllVessels[i].generator1Capacity,
					"Generator 2 Capacity": AllVessels[i].generator2Capacity,
					"Crew Number": AllVessels[i].crewNumber,
					"Created At": moment(AllVessels[i].createdDate).format("DD/MM/YYYY"),
					Creator: AllVessels[i].createdByName,
					"Activation Code": AllVessels[i].activationCode,
					Hatch: AllVessels[i].id,
					Actions: AllVessels[i].id,
				});
			}

			this.setState({
				loading: false,
				gridcolumns: gridcolumns,
				gridrows: gridrows,
				vessels: AllVessels,
			});
		} catch (error) {
			console.log("Error.", error);
		}
	};

	CloseForm = async () => {
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true,
			loadedjs: 0,
			vessel: null,
		});
	};

	CloseFormNoChanges = async () => {
		this.setState({
			ShowForm: false,
			vessel: null,
		});
	};

	CloseSiteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseDeleteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};
	CloseDeleteFormNoChanges = async () => {
		console.log("close->delete");
		this.setState({
			ShowDeleteForm: false,
			vessel: null,
		});
	};

	DeleteSite = async (id) => {
		//Find site
		var vessel = this.findVesselByID(id);

		//Set site on variable to delete
		setSite(vessel);

		//Show delete form
		this.setState({
			ShowDeleteForm: true,
		});
	};

	EditSite = async (id) => {
		//Find site
		var vessel = this.findVesselByID(id);

		setSite(vessel);
		setFormType("Edit");

		//Show delete form
		this.setState({
			ShowForm: true,
			vessel: vessel,
		});
	};

	OpenUsers = async (id) => {
		//Find site
		var site = this.findVesselByID(id);

		//Set site on variable to delete
		setSite(site);

		this.setState({
			ShowSiteUsers: true,
		});
	};

	CloseSiteUsers = async () => {
		this.unloadjs();
		this.setState({
			ShowSiteUsers: false,
		});
	};

	findVesselByID = (id) => {
		return this.state.vessels.find((vessel) => {
			return vessel.id === id;
		});
	};

	SelectedSiteChanged = (id) => {
		this.setState({
			SelectedSite: this.findVesselByID(id),
		});
	};

	OpenSite = () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: true,
		});
	};

	onGridReady = (params) => {
		params.api.sizeColumnsToFit();
		this.setState({
			gridApi: params.api,
			gridColumnApi: params.columnApi,
		});
	};

	VesselHatches = async (id) => {
		this.setState({
			VesselHatchRedirect: true,
			VesselToHatch: id,
		});
	};

	render() {
		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading Vessels</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "center", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		if (this.state.VesselHatchRedirect) {
			return (
				<Navigate
					to="/home/hatch"
					state={{ VesselID: this.state.VesselToHatch }}
				/>
			);
		}

		if (this.state.ShowOpenSiteForm) {
			return (
				<React.Fragment>
					<SiteForm
						CloseForm={this.CloseSiteForm}
						Site={this.state.SelectedSite}
					/>
				</React.Fragment>
			);
		}

		if (this.state.denied) {
			return (
				<div>
					<p>You do not have access to this page.</p>
				</div>
			);
		}

		// console.log("sites render!")

		return (
			<>
				<h2>Vessels</h2>
				<div className="d-flex" style={{ marginBottom: 10, display: "flex" }}>
					<button
						key={"NewVessel"}
						type="button"
						className="btn"
						style={{ backgroundColor: "green", color: "white" }}
						onClick={() => {
							setFormType("Create");
							this.setState({
								ShowForm: true,
							});
						}}
					>
						<i className="fa fa-plus-square"></i> Create Vessel
					</button>
					<button
						key={"Refresh"}
						type="button"
						style={{
							marginLeft: 10,
							backgroundColor: "#59bfff",
							color: "white",
						}}
						className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
						onClick={() => {
							this.unloadjs();
							this.setState({ loading: true, loadedjs: 0 });
						}}
					>
						<i className="fa fa-refresh"></i> Refresh
					</button>
				</div>
				<div
					className="ag-theme-alpine"
					style={{ width: "100%", height: "90%" }}
				>
					<AgGridReact
						defaultColDef={{
							sortable: true,
							filter: true,
						}}
						rowDragManaged={true}
						rowDragMultiRow={true}
						suppressExcelExport={true}
						sideBar={{
							toolPanels: ["columns", "filters"],
						}}
						onGridReady={this.onGridReady}
						pivotMode={false}
						rowGroupPanelShow={"always"}
						rowSelection={"multiple"}
						pagination={true}
						paginationAutoPageSize={true}
						animateRows={true}
						getContextMenuItems={(params) => {
							return [
								{
									name: "Edit",
									action: (e) => {
										console.log("les mes params ", params);
										console.log("id", params.node.data.Actions);
										this.EditSite(params.node.data.Actions);
									},
								},
								{
									name: "Delete",
									action: (e) => {
										console.log(
											"params.node.data.Actions",
											params.node.data.Actions
										);
										this.DeleteSite(params.node.data.Actions);
									},
								},
								{
									name: "Hatches",
									action: (e) => {
										console.log(
											"params.node.data.Actions",
											params.node.data.Actions
										);
										this.VesselHatches(params.node.data.Actions);
									},
								},
							];
						}}
						rowData={this.state.gridrows}
						columnDefs={this.state.gridcolumns}
					></AgGridReact>
				</div>
				{/* <table className="table table-bordered datatable" id="table-1">
					<thead>
						<tr>
							<th>Vessel Name</th>
							<th>Captain Name</th>
							<th>Licence Number</th>

							<th>IMO Number</th>
							<th>AIS Code</th>

							<th>Vessel Length</th>
							<th>Avg Trip Days</th>

							<th>Avg Fishing Days</th>
							<th>Avg Coast Distance</th>

							<th>Crew Number</th>

							<th data-hide="phone">Created At</th>
							<th data-hide="phone,tablet">Creator</th>
							<th>Activation Code</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{this.state.vessels &&
							this.state.vessels.map((vessel, index) => {
								return (
									<tr
										role={"row"}
										className={index % 2 === 0 ? "odd" : "even"}
										key={"formrow_" + index}
									>
										<td>{vessel.name}</td>
										<td>{vessel.captainName}</td>
										<td>{vessel.licenseNumber}</td>

										<td>{vessel.imoNumber}</td>
										<td>{vessel.aisCode}</td>

										<td style={{ textAlign: "right" }}>
											{vessel.vesselLength}
										</td>
										<td style={{ textAlign: "right" }}>{vessel.avgTripDays}</td>

										<td style={{ textAlign: "right" }}>
											{vessel.avgFishingDays}
										</td>
										<td style={{ textAlign: "right" }}>
											{vessel.avgCoastDistance}
										</td>

										<td style={{ textAlign: "right" }}>{vessel.crewNumber}</td>
										<td>
											{moment(new Date(vessel.createdDate)).format(
												"YYYY/MM/DD HH:MM"
											)}
										</td>
										<td>{vessel.createdByName}</td>
										<td>{vessel.activationCode}</td>
										<td style={{ display: "flex", justifyContent: "end" }}>
											<a
												href="#"
												className="btn btn-info btn-sm btn-icon icon-left"
												style={{ marginLeft: 10 }}
												onClick={() => this.EditSite(vessel.id)}
											>
												<i className="entypo-pencil"></i>
												Edit
											</a>
											<a
												href="#"
												className="btn btn-danger btn-sm btn-icon icon-left"
												style={{ marginLeft: 10 }}
												onClick={() => this.DeleteSite(vessel.id)}
											>
												<i className="entypo-trash"></i>
												Delete
											</a>
										</td>
									</tr>
								);
							})}
					</tbody>
					<tfoot>
						<tr>
							<th>Vessel Name</th>
							<th>Captain Name</th>
							<th>Licence Number</th>

							<th>IMO Number</th>
							<th>AIS Code</th>

							<th>Vessel Length</th>
							<th>Avg Trip Days</th>

							<th>Avg Fishing Days</th>
							<th>Avg Coast Distance</th>

							<th>Crew Number</th>

							<th>Created At</th>
							<th>Creator</th>
							<th>Activation Code</th>
							<th></th>
						</tr>
					</tfoot>
				</table> */}
				{this.state.ShowForm ? (
					<FormTemplate
						CloseForm={this.CloseForm}
						CloseFormNoChanges={this.CloseFormNoChanges}
					/>
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm
						CloseForm={this.CloseDeleteForm}
						CloseDeleteFormNoChanges={this.CloseDeleteFormNoChanges}
					/>
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</>
		);

		return (
			<React.Fragment>
				<h2 className="mb-4">Sites</h2>
				<div className="d-flex">
					{this.state.user?.isAdmin ? (
						<>
							<button
								key={"NewSite"}
								type="button"
								className="btn btn-esip-blue py-2 px-4 mb-4 me-4 custom-letter-spacing"
								onClick={() => {
									setFormType("Create");
									this.setState({
										ShowForm: true,
									});
								}}
							>
								<i className="entypo-pencil"></i>Create Site
							</button>
							<button
								key={"Refresh"}
								type="button"
								style={{ marginLeft: 10 }}
								className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
								onClick={() => {
									this.setState({ loading: true });
								}}
							>
								<i className="entypo-pencil"></i>Refresh
							</button>
						</>
					) : null}
				</div>
				<div
					className="p-0"
					style={{
						borderBottom: 0,
						borderLeft: 0,
						borderRight: 0,
						height: "85%",
					}}
				>
					<div
						className="d-flex flex-column justify-content-between align-items-center"
						style={{ height: "100%" }}
					>
						<div
							className="w-100 dataTables_wrapper no-footer"
							style={{ overflowY: "auto", marginTop: 25 }}
						>
							{/* <table className='table table-bordered table-striped datatable dataTable no-footer' style={{marginBottom:0}} key={"table"}> */}
							<table
								className="table table-bordered datatable dataTable"
								id="table-1"
								role="grid"
								aria-describedby="table-1_info"
							>
								<thead>
									<tr role={"row"}>
										<th
											data-hide="phone"
											className="sorting_desc"
											tabIndex="0"
											aria-controls="table-1"
											rowSpan="1"
											colSpan="1"
											aria-label="Rendering engine: activate to sort column ascending"
											aria-sort="descending"
										>
											Rendering engine
										</th>
										{/* <th className="sorting" tabIndex="0" aria-controls="table-2" rowSpan="1" colSpan="1" aria-label="Student Name: activate to sort column ascending" >{"Site Name"}</th> */}
										<th
											className="sorting"
											style={{ width: 200 }}
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Average Grade: activate to sort column ascending"
										>
											{"Created At"}
										</th>
										<th
											className="sorting"
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Curriculum / Occupation: activate to sort column ascending"
										>
											{"Creator"}
										</th>
										<th
											className="sorting"
											style={{ width: "25%" }}
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Actions: activate to sort column ascending"
										>
											{"Actions"}
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.sites.map((site, index) => {
										return (
											<tr
												role={"row"}
												className={index % 2 === 0 ? "odd" : "even"}
												key={"formrow_" + index}
												onClick={() => this.SelectedSiteChanged(site.id)}
												onDoubleClick={() => this.OpenSite()}
											>
												{/* <div key={"SiteRow_"+site.id} className={`d-flex align-items-center p-3 custom-border-bottom `} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite()}> */}
												<td>{site.name}</td>
												<td>
													{"" + moment(site.createdAt).format("YYYY/MM/DD")}
												</td>
												<td>
													{site.creator !== null
														? "" + site.creator?.username
														: ""}
												</td>
												<td className="sorting_1">
													<a
														href="#"
														className="btn btn-default btn-sm btn-icon icon-left"
														onClick={() => this.OpenUsers()}
													>
														<i className="entypo-pencil"></i>
														Open Users
													</a>
													<a
														href="#"
														className="btn btn-info btn-sm btn-icon icon-left"
														onClick={() => this.EditSite(site.id)}
													>
														<i className="entypo-info"></i>
														Edit Site
													</a>
													<a
														href="#"
														className="btn btn-danger btn-sm btn-icon icon-left"
														onClick={() => this.DeleteSite(site.id)}
													>
														<i className="entypo-cancel"></i>
														Delete Site
													</a>
												</td>
												{/* <td > */}
												{/* <button key={"openusers_"+site.id} type="button" title='Open Users' className="btn btn-esip-yellow delete-btn me-2" onClick={() => this.OpenUsers()} style={{ margin: "auto" }}>
														<i className="fas fa-user"></i>
													</button>
													<button key={"editsite_"+site.id} type="button" title='Edit Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.EditSite(site.id)}>
														<i className="fas fa-edit"></i>
													</button> */}
												{/* <a key={"delsite_"+site.id} type="button" title='Delete Site' className="btn btn-danger btn-sm btn-icon icon-left" onClick={() => this.DeleteSite(site.id)}>
														<i className="entypo-cancel"></i>
													</a> */}
												{/* <button key={"opensite_"+site.id} type="button" title='Open Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.OpenSite()}>
														<i className="fas fa-arrow-right"></i>
													</button> */}
												{/* </td> */}
												{/* </div> */}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{this.state.ShowForm ? (
					<FormTemplate CloseForm={this.CloseForm} />
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm CloseForm={this.CloseDeleteForm} />
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

export default withRouter(VesselsPage);

import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getVessels = async () => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/Vessel/getAllVessels`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const getVesselTrips = async (vesselid) => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(
				`${process.env.REACT_APP_API_URL}/Trip/getAllTrips?vesselID=${vesselid}`,
				{
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				}
			)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const getVessel = async (vesselId) => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/Vessel/getVessel?id=${vesselId}`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const saveVessel = async (vessel, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		var time = "";

		if (vessel.notificationHour)
			time =
				vessel.notificationHour.getHours() +
				":" +
				vessel.notificationHour.getMinutes();

		var vesselData = {
			name: vessel.Name,
			id: vessel.ID,
			captainName: vessel.captainName,
			aisCode: vessel.aisCode, //string
			imoNumber: vessel.ImoNumber, //string
			avgTripDays: vessel.avgTripDays, //int
			vesselLength: vessel.vesselLength, //int
			avgFishingDays: vessel.avgFishingDays, //int
			avgCoastDistance: vessel.avgCoastDistance, //int
			licenseNumber: vessel.LicenseNumber,
			createdBy: userid,
			modifiedBy: userid,
			crewNumber: vessel.crewNumber,
			fuelCapacity: vessel.fuelCapacity,
			waterCapacity: vessel.waterCapacity,
			generator1Capacity: vessel.generator1Capacity,
			generator2Capacity: vessel.generator2Capacity,
			deletedBy: userid,
			notificationHour: time,
		};

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/Vessel/save`, vesselData, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error.response.data.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};

export const deleteVessel = async (vessel, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		// console.log("vessel", vessel);
		var vesselData = {
			id: vessel.id,
		};

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/Vessel/delete`, vesselData, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					ErrorNotification(error.response.data.message);
					return reject(error);
				});
		} catch (error) {
			return reject(error);
		}
	});
};

//import react
import React from "react";
import {
	getFormType,
	getSite,
} from "../../utils/sitesForm";
import {
	ErrorNotification,
} from "../../utils/notifications";
import { getUser } from "../../utils/loggedUser";
import { saveFishSize } from "../../api/fishsizes";
const { REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE } = process.env;

function isNumber(value) {
	'use strict';
	value = value.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
	try {
		value = value.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
		return !isNaN(parseFloat(value)) && isFinite(value);
	}
	catch {
		return false;
	}

}
class FormTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			fishsize: {
				name: "",
				minWeight: null,
				maxWeight: null,
				weightUnitCode: "Kg",
				minLength: null,
				maxLength: null,
				lengthUnitCode: "Cm",
				id: 0,
				abbreviation: null
			},
			FormType: "", // "Create" or "Edit"
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		window.jQuery("#modal-6").modal("show", { backdrop: "static" });

		// var script = document.getElementById("edit-script-modal-sites");

		// if(script){
		//     console.log("remove script!")
		//     script.remove();
		// }
		// var scriptx = document.createElement("script");
		// scriptx.id = "edit-script-modal-sites"
		// scriptx.innerHTML = "jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		// document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		// this.PortNameInputText.focus();
		var FormType = getFormType();
		if (FormType === "Edit") {
			var fishsize = getSite();
			this.setState({
				fishsize: {
					name: fishsize.name,
					minWeight: fishsize.minWeight,
					maxWeight: fishsize.maxWeight,
					weightUnitCode: "Kg",
					minLength: fishsize.minLength,
					maxLength: fishsize.maxLength,
					lengthUnitCode: "Cm",
					pieces: fishsize.numberOfPieces,
					id: fishsize.id,
					abbreviation: fishsize.abbreviation
				},
			});
		}

		this.setState({
			loading: false,
			FormType: FormType,
		});
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			fishsize: {},
			FormType: "", // "Create" or "Edit"
		});

		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
			document.body.appendChild(scriptx);

			document.findElementById("closemodal").remove();
		} catch (error) {}
		this.props.CloseForm();
	};

	Submit = async () => {
		var FormType = getFormType();

		await this.Save();
	};

	Save = async () => {
		if (this.state.fishsize.name === "") {
			ErrorNotification("error", "Name is required");
			return;
		}
		if (this.state.fishsize.abbreviation === "") {
			ErrorNotification("error", "Abbreviation is required");
			return;
		}


		if (this.state.fishsize.minWeight != undefined && this.state.fishsize.minWeight != null && this.state.fishsize.minWeight != "") {
			var minWeight = isNumber(this.state.fishsize.minWeight);
			if (!minWeight) {
				ErrorNotification("error", "Min weight is incorrect");
				return;
			}
			this.state.fishsize.minWeight = this.state.fishsize.minWeight.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);

			if (isNaN(this.state.fishsize.minWeight) || this.state.fishsize.minWeight <= 0) {
				ErrorNotification("error", "Min weight must be equal or greater than zero");
				return;
			}
		}
		else
			this.state.fishsize.minWeight = null;


			if (this.state.fishsize.maxWeight != undefined && this.state.fishsize.maxWeight != null && this.state.fishsize.maxWeight != "") {
				var maxWeight = isNumber(this.state.fishsize.maxWeight);
				if (!maxWeight) {
					ErrorNotification("error", "Max weight is incorrect");
					return;
				}
				this.state.fishsize.maxWeight = this.state.fishsize.maxWeight.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
				if (isNaN(this.state.fishsize.maxWeight) || this.state.fishsize.maxWeight <= 0) {
					ErrorNotification("error", "Max weight must be equal or greater than zero");
					return;
				}
				
			}
			else
				this.state.fishsize.maxWeight = null;	

				if (this.state.fishsize.minLength != undefined && this.state.fishsize.minLength != null && this.state.fishsize.minLength != "") {
					var minLength = isNumber(this.state.fishsize.minLength);
					if (!minLength) {
						ErrorNotification("error", "Min length is incorrect");
						return;
					}
					this.state.fishsize.minLength = this.state.fishsize.minLength.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
		
					if (isNaN(this.state.fishsize.minLength) || this.state.fishsize.minLength <= 0) {
						ErrorNotification("error", "Min length must be equal or greater than zero");
						return;
					}
				}
				else
					this.state.fishsize.minLength = null;

					if (this.state.fishsize.maxLength != undefined && this.state.fishsize.maxLength != null && this.state.fishsize.maxLength != "") {
						var maxLength = isNumber(this.state.fishsize.maxLength);
						if (!maxLength) {
							ErrorNotification("error", "Max length is incorrect");
							return;
						}
						this.state.fishsize.maxLength = this.state.fishsize.maxLength.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
						if (isNaN(this.state.fishsize.maxLength) || this.state.fishsize.maxLength <= 0) {
							ErrorNotification("error", "Max length must be equal or greater than zero");
							return;
						}
						this.state.fishsize.maxLength = this.state.fishsize.maxLength.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
					}
					else
						this.state.fishsize.maxLength = null;			

		// if (this.state.fishsize.minWeight === "") {
		// 	ErrorNotification("error", "Min Weight is required");
		// 	return;
		// }

		// if (this.state.fishsize.maxWeight === "") {
		// 	ErrorNotification("error", "Max Weight is required");
		// 	return;
		// }

		// if (this.state.fishsize.minLength === "") {
		// 	ErrorNotification("error", "Min Length is required");
		// 	return;
		// }

		// if (this.state.fishsize.maxLength === "") {
		// 	ErrorNotification("error", "Max Length is required");
		// 	return;
		// }

		await saveFishSize(this.state.fishsize, getUser().userID)
			.then(async (response) => {
				this.props.CloseForm();
			})
			.catch(async (error) => {
				ErrorNotification("error", error);
			});
	};

	PortNameChanged = (value) => {
		if (value.length > 35) {
			ErrorNotification("error", "Port name cannot be more than 35 characters");
			return;
		}

		this.setState({
			PortName: value,
		});
	};

	_handleKeyDown = (key) => {
		if (key === "Enter") {
			this.Submit();
		}
	};

	render() {
		return (
			<div className="modal fade" id="modal-6">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.props.CloseFormNoChanges}
							>
								&times;
							</button>
							<h4 className="modal-title">{this.state.FormType} Fish size</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Name:
										</label>
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
											style={{ color: "red", marginLeft: 5 }}>
											*
								</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Name"
											value={this.state.fishsize.name}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														name: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Abbreviation:
										</label>
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
											
											style={{ color: "red", marginLeft: 5 }}>
											*
								</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Abbreviation"
											maxLength="3"
											value={this.state.fishsize.abbreviation}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														abbreviation: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
							<div className="row" style={{ marginTop: 10 }}>
								<div className="col-md-6">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Min Weight:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Minimum weight"
											value={this.state.fishsize.minWeight}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														minWeight: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Max Weight:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Maximum weight"
											value={this.state.fishsize.maxWeight}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														maxWeight: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
							{/* <div className="row" style={{marginTop:10}}>
                                <div className="col-md-12">
									<div className="form-group no-margin">
                                        <label htmlFor="exampleFormControlInput1" className="control-label">Weight Unit:</label>
                                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Code 3A" value={this.state.fishsize.minWeight} onChange={(e) => {
                                            this.setState({
                                                fishsize: {
                                                    ...this.state.fishspecie,
                                                    minWeight: e.target.value
                                                }
                                            });
                                        }} onKeyDown={(e) => this._handleKeyDown(e.key)} />
                                    </div>
                                </div>
                            </div> */}
							<div className="row" style={{ marginTop: 10 }}>
								<div className="col-sm-6">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Min length:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Minimum Length"
											value={this.state.fishsize.minLength}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														minLength: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Max length:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Maximum Length"
											value={this.state.fishsize.maxLength}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														maxLength: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
							<div className="row" style={{ marginTop: 10 }}>
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Pieces:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Pieces"
											value={this.state.fishsize.pieces}
											onChange={(e) => {
												this.setState({
													fishsize: {
														...this.state.fishsize,
														pieces: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={this.props.CloseFormNoChanges}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-info"
								onClick={this.Submit}
							>
								Save{" "}
							</button>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div
				className={`modal modal-bg`}
				style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
				tabIndex={-1}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					style={{ top: "30%" }}
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" style={{ float: "left" }}>
								{this.state.FormType} Site
							</h5>
							<button
								type="button"
								style={{
									float: "right",
									width: 20,
									height: 20,
									padding: 0,
									margin: 0,
								}}
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={this.CloseForm}
							>
								X
							</button>
						</div>
						<div className="modal-body custom-light-gray">
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlInput1"
									className="form-label"
								>
									Name:
								</label>
								<input
									type="text"
									className="form-control"
									id="exampleFormControlInput1"
									placeholder="Name"
									ref={(input) => (this.PortNameInputText = input)}
									value={this.state.PortName}
									onChange={(e) => this.PortNameChanged(e.target.value)}
									onKeyDown={(e) => this._handleKeyDown(e.key)}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-esip-blue py-2 px-4"
								onClick={this.Submit}
							>
								{"Save"}
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-bs-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormTemplate;

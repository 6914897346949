import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getFishSizes = async () => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/FishSize/getAllFishSizes`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {


				return response.data;
			})
			.catch((error) => {
				console.log("Error: ", error);
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const saveFishSize = async (fish, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		
		var minWeight;
		if(fish.minWeight != "")
			minWeight = parseFloat(fish.minWeight);
		var maxWeight;
		if(fish.maxWeight != "")
			maxWeight = parseFloat(fish.maxWeight);
	
		var minLength;
		if(fish.minLength != "")
			minLength = parseFloat(fish.minLength);
		
		var maxLength;
		if(fish.maxLength != "")
			maxLength = parseFloat(fish.maxLength);
			
		var fishData = {
			name: fish.name,
			id: fish.id,
			minWeight: minWeight,
			maxWeight: maxWeight,
			minLength: minLength,
			maxLength: maxLength,
			weightUnitCode: fish.weightUnitCode,
			lengthUnitCode: fish.lengthUnitCode,
			numberOfPieces: fish.pieces,
			createdBy: userid,
			modifiedBy: userid,
			deletedBy: userid,
			abbreviation: fish.abbreviation 
		};

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/FishSize/save`, fishData, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};

export const deleteFishSize = async (sizeid, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		var specieData = {
			id: sizeid,
			deletedBy: userid,
		};

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/FishSize/delete`, specieData, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					ErrorNotification(error.response.data.message);
					return reject(error);
				});
		} catch (error) {
			return reject(error);
		}
	});
};

import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getTopVessels = async (top, startdate, enddate) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
	
	
	return axios
		.get(
			`${
				process.env.REACT_APP_API_URL
			}/ExportData/getTopVessels?top=${top}&start=${
				startdate.getFullYear() +
				"/" +
				(startdate.getMonth() + 1) +
				"/" +
				startdate.getDate()
			}&end=${
				enddate.getFullYear() +
				"/" +
				(enddate.getMonth() + 1) +
				"/" +
				enddate.getDate()
			}`,
			{
				params: {
					token: localtoken,
				},
			}
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return "ERROR" + error;
		});
};

export const getTopSpecies = async (top, startdate, enddate) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
	return axios
		.get(
			`${
				process.env.REACT_APP_API_URL
			}/ExportData/getTopSpecies?top=${top}&start=${
				startdate.getFullYear() +
				"/" +
				(startdate.getMonth() + 1) +
				"/" +
				startdate.getDate()
			}&end=${
				enddate.getFullYear() +
				"/" +
				(enddate.getMonth() + 1) +
				"/" +
				enddate.getDate()
			}`,
			{
				params: {
					token: localtoken,
				},
			}
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return "ERROR" + error;
		});
};

export const getSumCatches = async (startdate, enddate) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
	return axios
		.get(
			`${process.env.REACT_APP_API_URL}/ExportData/getSumCatches?start=${
				startdate.getFullYear() +
				"/" +
				(startdate.getMonth() + 1) +
				"/" +
				startdate.getDate()
			}&end=${
				enddate.getFullYear() +
				"/" +
				(enddate.getMonth() + 1) +
				"/" +
				enddate.getDate()
			}`,
			{
				params: {
					token: localtoken,
				},
			}
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return "ERROR" + error;
		});
};

export const getStatistics = async () => {
	axios.defaults.withCredentials = true;
	const localtoken = localStorage.getItem("token");

	return axios
		.get(`${process.env.REACT_APP_API_URL}/getStatistics`, {
			params: {
				token: localtoken,
			},
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return "ERROR" + error;
		});
};

export const GraphStatistics = async () => {
	axios.defaults.withCredentials = true;
	const localtoken = localStorage.getItem("token");

	return axios
		.get(`${process.env.REACT_APP_API_URL}/Statistics`, {
			params: {
				token: localtoken,
			},
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return "ERROR" + error;
		});
};

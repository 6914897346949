import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getSettings = async () => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/AppSettings/getAppSettings`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const getUnits = async () => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/Units/getAllUnits`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const saveSettings = async (settings, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;
		try {
			const localtoken = await localStorage.getItem("token");

			// var setting = {
			// 	newZoneRadius: settings.NewZoneRadius,
			// 	newSetRadius: settings.NewSetRadius,
			// 	newZoneTime: settings.NewZoneTime,
			// 	newSetTime: settings.NewSetTime,
			// 	thresholdNotification: settings.ThresholdNotification,
			// 	createdBy: userid,
			// 	modifiedBy: userid,
			// };

			settings.modifiedBy = userid;
			console.log("settings", settings);

			return axios
				.post(`${process.env.REACT_APP_API_URL}/AppSettings/save`, settings, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					return reject(error);
				});
		} catch (error) {
			return reject(error);
		}
	});
};

//import react
import React from 'react';
import { getTemplate } from '../api/templates';
import FormGenerator from '../components/FormGenerator';
//get user
import { getUser, UserTemplatePermissions } from '../api/users';
import Swal from 'sweetalert2'
import { SubmitAnswer } from '../api/answers';
import {
    useLocation,
    useNavigate,
    useParams,
	Navigate
  } from "react-router-dom";

import { setAccessToken } from "../utils/accessToken"
  
function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class FormFilling extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            form: null,
            fid: null,
            loading: true,
            user: null,
            ComponentsValues: [],
            submitted: false
        }
        this._onValueChanged = this._onValueChanged.bind(this);
        this._onAllValueChanged = this._onAllValueChanged.bind(this);
    }

    componentDidMount = async () => {
        let user = await getUser();
        //get id from url parameters
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let fid = params.get('fid');
        let sid = params.get('sid');

        if(!fid || !sid){
            fid = this.props?.router?.location?.state?.fid;
            sid = this.props?.router?.location?.state?.sid;
        }

        // console.log("fid", fid);
        // console.log("sid", sid);
        // console.log("atob fid", atob(fid));
        // console.log("atob sid", atob(sid));

        

        this.setState({
            user: user,
            fid: fid,
            sid: sid
        })

        // console.log("user", user);
        if(user === null || user === undefined || user.length === 0){
            return; //not logged in. Login user.
        }

        await Swal.fire({
            title: "Are you "+user.username+"?",
            text: "Do you want to fill this form as <"+user.username+">?",
            icon: "question",
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showDenyButton: true,
            denyButtonText: "Logout",
            denyButtonColor:"gray"
        }).then(async (result) => {
            user.confirmation = false;
            if(result.isConfirmed){
                user.confirmation = true;
                
            }
            else if(result.isDenied){
                localStorage.removeItem('token');
                setAccessToken("");
                this.setState({
                    user: undefined,
                    loading: false
                })
                return;
            }
            else{
                //refresh page
                window.location.reload();
                return;
            }
            this.setState({
                user: user
            })
        })

        try {
            fid = parseInt(atob(fid))
            sid = parseInt(atob(sid))
        } catch (error) {
            
        }

        if(fid === null || sid === null || fid === undefined || sid === undefined){
            this.setState({
                loading: false
            })
            return;
        }

        let UserPermissions = await UserTemplatePermissions(user.id);
        // console.log("user", user);
        if(!user.isAdmin){
            let permission = null;
            if(UserPermissions !== null && UserPermissions.length > 0){
                permission = UserPermissions.find(permission => {
                    return permission.siteID === sid && permission.templateID === fid
                })
            }
            // console.log("permission", permission);

            if(permission === null || permission === undefined){
                this.setState({
                    loading: false,
                    permission: false
                })
                return;
            }
        }

        //get form
        let response = await getTemplate(fid, sid);

        // console.log("response", response);

        // console.log("response", response);
        if(response !== null){
            if(response.sites.length !== 1){
                this.setState({
                    loading: false
                })
                return;
            }

            response.csv.Body.map((item, index) => {
                switch(item.type) {
                    case "TextInput": 
                    case "NumberInput":
                    case "Image": this._onValueChanged(item.id, ""); break;
                    case "MultiTextInput": this._onValueChanged(item.id, ""); break;
                    case "Select": this._onValueChanged(item.id, (item.options !== undefined && item.options !== null && item.options.length > 0) ? 0 : 0); break;
                    case "CheckBox": this._onValueCheckBoxChanged(item.options); break;
                    case "Switch": this._onValueChanged(item.id, item.switch ?? false); break;
                    case "Rating": this._onValueChanged(item.id, 0); break;
                    case "DatePicker": this._onValueChanged(item.id, (new Date())); break;
                    case "TimePicker": this._onValueChanged(item.id, (new Date())); break;
                    case "DateTimePicker": this._onValueChanged(item.id, (new Date())); break;
                    case "Text": this._onValueChanged(item.id, item.placeholder ?? ''); break;
                    case "PhotoCamera": this._onValueChanged(item.id, ""); break;
                    // case "GPS": this.getGPSLocation(item.id); break;
                    case "GPS": this._onValueChanged(item.id, ""); break;
                    default: this._onValueChanged(item.id, ""); break;
                }
            });
        }

        this.setState({
            form: response,
            site: response?.sites[0],
            loading: false,
            permission: true,
            fid: fid,
            sid: sid
        });

    }

    _onAllValueChanged(values) {
        this.setState({
            ComponentsValues: values
        });
    }

    _onValueChanged(index, value) {

        if(this.state.ComponentsValues[index] === value)
            return;

        // console.log("_onValueChanged", index, value);

        let ComponentsValues = this.state.ComponentsValues;

        ComponentsValues[index] = value;
        
        this.setState({
            ComponentsValues: ComponentsValues
        });
    }

    SubmitForm = async () => {
        // console.log("SubmitForm", this.state.ComponentsValues);
        let answeraux = this.state.ComponentsValues;

        var data = "";
        var ExistsMandatoryNotFilled = false;
        //foreach answeraux
        for (let i = 0; i < answeraux.length; i++) {
            var item = answeraux[i];
            if(typeof answeraux[i] === 'string' && answeraux[i].includes("base64,")){
                item = answeraux[i].split(",")[1];
            }

            if(item !== null && item !== undefined) {
                if(data !== "") {
                    data += ",";
                }
                var value = item.value ?? item;

                var csvbody = this.state.form.csv.Body[i];
                if(csvbody?.mandatory &&
                    (value+"" === "" ||
                                    (
                                        (csvbody.type === 'Select' || csvbody.type === 'Rating')
                                    && value === 0
                    ))){
                    console.log("mandatory", csvbody, i);
                    ExistsMandatoryNotFilled = true;
                    break;
                }

                data += "{\"index\":\"" + i + "\",\"value\":\"" + (value+"").replace(/\n/g, '\\n') + "\"}";
            }
        }

        if(ExistsMandatoryNotFilled){
            Swal.fire({
                title: "Error",
                text: "You must fill all mandatory fields",
                icon: "error",
                showConfirmButton: true
            })
            return;
        }

        data = "[" + data + "]";

        data = JSON.parse(data);
        
        let resp = await SubmitAnswer(data, this.state.fid, this.state.sid);
        
        if((resp+"").includes("ERROR")){
            Swal.fire({
                title: "Error",
                text: "Something went wrong while submitting the form",
                icon: "error",
                showConfirmButton: true
            })
            return;
        }

        this.setState({
            submitted: true
        })
    }

    _onValueCheckBoxChanged(options) {
        let ComponentsValues = this.state.ComponentsValues;

        for (let i = 0; i < options.length; i++) {
            if(this.state.ComponentsValues[options[i].id] == options[i].default)
                continue;

                ComponentsValues[options[i].id] = options[i].default;
        }

        this.setState({
            ComponentsValues: ComponentsValues
        });
    }

    render() {

        if(this.state.user === null){
            return (
                <div style={{width:'100%', margin:'0 auto'}}>
                    <p style={{color:'#0b59b2', textAlign:'center', fontSize:24, paddingTop:50, fontWeight:'bold', letterSpacing:2}}>Loading User</p>
                    <img src={require('../assets/Gifs/LoadingFormWeb.gif')} alt="logo" style={{width: "100", height: "100", display:'block', margin:'auto'}}/>
                </div>
            )
        }
        else if(this.state.user === null || this.state.user === undefined || this.state.user.length === 0){
            return <Navigate  to="/login" state={{"fid": this.state.fid, "sid": this.state.sid}} />
        }
        else if(!this.state.user.confirmation){
            return (
                <div style={{width:'100%', margin:'0 auto'}}>
                    <p style={{color:'#0b59b2', textAlign:'center', fontSize:24, paddingTop:50, fontWeight:'bold', letterSpacing:2}}>Waiting for user confirmation</p>
                    <img src={require('../assets/Gifs/LoadingFormWeb.gif')} alt="logo" style={{width: "100", height: "100", display:'block', margin:'auto'}}/>
                </div>
            )
        }
        else if(this.state.permission !== null && this.state.permission !== undefined && !this.state.permission){
            return (
                <div style={{width:'100%', margin:'0 auto'}}>
                    <p style={{color:'#0b59b2', textAlign:'center', fontSize:24, paddingTop:50, fontWeight:'bold', letterSpacing:2}}>You dont have permission to fill this form</p>
                    {/* <img src={require('../assets/Gifs/LoadingFormWeb.gif')} alt="logo" style={{width: "100", height: "100", display:'block', margin:'auto'}}/> */}
                    <h1 style={{color:'red', fontSize:500, fontWeight:'bold', textAlign:'center'}}>X</h1>
                </div>
            )
        }

        

        if(this.state.loading){
            return (
                <div style={{width:'100%', margin:'0 auto'}}>
                    <p style={{color:'#0b59b2', textAlign:'center', fontSize:24, paddingTop:50, fontWeight:'bold', letterSpacing:2}}>Loading Form</p>
                    <img src={require('../assets/Gifs/LoadingFormWeb.gif')} alt="logo" style={{width: "100", height: "100", display:'block', margin:'auto'}}/>
                    {/* <h1 style={{color:'red', fontSize:500, fontWeight:'bold', textAlign:'center'}}>X</h1> */}
                </div>
            )
        }

        if(this.state.form === null){
            return(
                <div style={{width:'100%', margin:'0 auto'}}>
                    <p style={{color:'#0b59b2', textAlign:'center', fontSize:24, paddingTop:50, fontWeight:'bold', letterSpacing:2}}>Form not found</p>
                    {/* <img src={require('../assets/Gifs/LoadingFormWeb.gif')} alt="logo" style={{width: "100", height: "100", display:'block', margin:'auto'}}/> */}
                    <h1 style={{color:'orange', fontSize:500, fontWeight:'bold', textAlign:'center'}}>?</h1>
                </div>
            )
        }

        if(this.state.submitted){
            return(
                <div style={{width:'100%', margin:'0 auto'}}>
                    <p style={{color:'#0b59b2', textAlign:'center', fontSize:24, paddingTop:50, fontWeight:'bold', letterSpacing:2}}>Form Submitted</p>
                    {/* <img src={require('../assets/Gifs/LoadingFormWeb.gif')} alt="logo" style={{width: "100", height: "100", display:'block', margin:'auto'}}/> */}
                    <h1 style={{color:'green', fontSize:500, fontWeight:'bold', textAlign:'center'}}>&#10004;</h1>
                </div>
            )
        }

        return (
            <div style={{width:'100%', alignContent:'center', alignItems:'center', justifyContent:'center', marginTop:25}}>
                <link rel="stylesheet" href="https://unpkg.com/@djthoms/pretty-checkbox" />
                
                <h2 style={{textAlign:'center', color:'#0b59b2', fontWeight:'bold', fontSize:24}}>{"New Form"}</h2>
                <h4 style={{textAlign:'center', color:'#0b59b2', fontSize:24}}>{this.state.site.name + " / " + this.state.form.name}</h4>
                <h6 style={{textAlign:'center', color:'#0b59b2', fontSize:24, borderBottomColor:'#0b59b2', borderBottomWidth:1, height:50, borderBottomStyle:'groove'}}>{"Version " + this.state.form.version}</h6>
                {
                    this.state.form.csv.Body.map((item, index) => {
                        return (
                            <div key={index+"_div"} style={{alignContent:'center', alignItems:'center', justifyContent:'center', marginTop:25}}>
                                <FormGenerator key={index+"_component"} component={item} ValueChangedMethod={this._onValueChanged} CheckboxValueChangedMethod={this._onAllValueChanged} VarValue={item.type.toLowerCase() === 'checkbox' ? this.state.ComponentsValues :  this.state.ComponentsValues[item.id]} index={item.id} />
                            </div>
                        );
                    })
                }
                <div style={{height:200, alignContent:'center', alignItems:'center', justifyContent:'center', marginTop:25}}>
                    <div style={{width:400, height:40, margin:'0 auto'}}>
                        <button className='btn'  style={{width:400, height:40, backgroundColor:'#0b59b2', margin:'0 auto', borderRadius:10}} onClick={ () => {this.SubmitForm()} }>
                            <p style={{color:'white', fontWeight:'bold', letterSpacing:2}}>Submit</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(FormFilling);

import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getAllHatches = async () => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/Hatches/getAllHatches`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const getVesselHatches = async (vesselId) => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(
				`${process.env.REACT_APP_API_URL}/Hatches/getAllHatchesByVesselID?vesselID=${vesselId}`,
				{
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				}
			)
			.then((response) => {
				console.log("getVesselHatches", response.data);
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const saveHatch = async (hatch, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		//console.log("hatch", hatch);
		var hatchdata = {
			name: hatch.name,
			id: hatch.id,
			vesselid: hatch.vesselid,
			capacity: hatch.capacity,
			createdBy: userid,
			modifiedBy: userid,
			abbreviation: hatch.abbreviation,
			deletedBy: userid,
		};
		//console.log("hatchdata", hatchdata);

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/Hatches/save`, hatchdata, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};

export const deleteHatch = async (hatchID, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		var hatchData = {
			id: hatchID,
			deletedBy: userid,
		};

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/Hatches/delete`, hatchData, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					ErrorNotification(error.response.data.message);
					return reject(error);
				});
		} catch (error) {
			return reject(error);
		}
	});
};

//import react
import React from "react";
import logo from "./../assets/logobitcliq.png";
import "../App.css";
import { getAccessToken, setAccessToken } from "../utils/accessToken";
import { getToken } from "../api/getToken";
import Sites from "./Ports";
import Forms from "./Templates";
import Users from "./Users";
import Settings from "./Settings";
import Categories from "./Categories";
import Home from "./Home";

import Motive from "./Motives";

import Sampling from "./Samplings";


import BioSampling from "./BioSamplings";

import { getUser } from "../utils/loggedUser";
import {
	FaFish,
	FaSignOutAlt,
	FaWarehouse,
	FaCreativeCommonsSamplingPlus,
} from "react-icons/fa";

import
{
	HiOutlineDocumentReport
}
from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import {
	GiAnglerFish,
	GiWoodenPier,
	GiSailboat,
	GiFishingBoat,
	GiDatabase,
	GiFloorHatch,
	GiSchoolOfFish,
	GiInfo,
} from "react-icons/gi";
import { BiTrip } from "react-icons/bi";
import { RiUser2Line } from "react-icons/ri";
import {
	AiFillHome,
	AiFillInfoCircle,
	AiOutlineCloudDownload,
} from "react-icons/ai";
import { FiUsers } from "react-icons/fi";

import { MdSummarize } from "react-icons/md";
import { FaTemperatureHigh } from "react-icons/fa";
import apk from "../app/elogbook.apk";
import apkInternet from "../app/elogbook-internet.apk";
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
	BrowserRouter,
	Route,
	Routes,
	useHistory,
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}
	return ComponentWithRouterProp;
}

class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			selectedMenu: "Home",
			user: null,
			loadcustomjs: false,
		};
		// console.log("homepage constructor");
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.loadToken();

		// if(this.state.selectedMenu !== window.location.hash.substring(1)){
		//     this.ChangeSelectedMenu(window.location.hash.substring(1));
		// }
	};

	componentDidMount = () => {
		if (this.state.loading) this.loadToken();
		// this.props.router.setRouteLeaveHook(this.props.route, this.onLeave);

		if (window.location.pathname.split("/")[2] !== undefined) {
			this.ChangeSelectedMenu(window.location.pathname.split("/")[2]);
		}

		var script = null;

		script = document.createElement("script");
		script.id = "homepage-joinablejs";
		script.src = "/assets/js/joinable.js";
		script.async = true;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.id = "homepage-resizeablejs";
		script.src = "/assets/js/resizeable.js";
		script.async = true;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.id = "homepage-neonapijs";
		script.src = "/assets/js/neon-api.js";
		script.async = true;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.id = "homepage-neoncustomjs";
		script.src = "/assets/js/neon-custom.js";
		script.async = true;
		document.body.appendChild(script);
	};

	componentWillUnmount = () => {
		console.log("HOMEPAGE UNMOUNT!");
		window.$("#homepage-joinablejs").remove();
		window.$("#homepage-resizeablejs").remove();
		window.$("#homepage-neonapijs").remove();
		window.$("#homepage-neoncustomjs").remove();
	};

	ChangeSelectedMenu = (menu) => {
		// console.log("change selected menu", menu);

		if (menu === this.state.selectedMenu) {
			this.setState({
				refresh: this.state.refresh === undefined ? 1 : this.state.refresh + 1,
			});
			return;
		}

		this.setState({
			selectedMenu: menu,
			refresh: 1,
		});
	};

	loadToken = async () => {
		let token = getAccessToken();
		let user = getUser();

		// console.log("vars",token,user);

		if (
			token === "" ||
			token === null ||
			token === undefined ||
			user === undefined ||
			user === null
		) {
			// this.props.router.navigate("/login");
			this.setState({
				token: null,
				loading: false,
			});
			return;
		}

		this.setState({
			loading: false,
			user: user,
			token: token,
		});
	};

	render() {
		if (this.state.loading) return null;

		if (
			this.state.token === null ||
			this.state.token === undefined ||
			this.state.token === "" ||
			this.state.user === null ||
			this.state.user === undefined
		) {
			return (
				<Navigate to="/login" />
				// <p>{getAccessToken()}</p>
			);
		}
		//console.log("this.state.user", this.state.user);

		return (
			<div className="page-container">
				<div className="sidebar-menu">
					<div className="sidebar-menu-inner" style={{ height: "100%" }}>
						<header className="logo-env">
							<div className="logo">
								{/* <a href="https://demo.neontheme.com/dashboard/main/"> */}
								<img
									src={require("../assets/white_3.png")}
									width={150}
									alt="Logo"
								/>
								{/* <p style={{fontWeight:'bold', fontSize: 35, color:'white', width:120, textAlign:'center'}}>UpWISE!</p> */}
								{/* </a> */}
							</div>
							<div className="sidebar-collapse">
								<a className="sidebar-collapse-icon with-animation">
									<i className="entypo-menu"></i>
								</a>
							</div>
							<div className="sidebar-mobile-menu visible-xs">
								<a href="#" className="with-animation">
									<i className="entypo-menu"></i>
								</a>
							</div>
						</header>
						<ul id="main-menu" className="main-menu">
							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										this.props.router.navigate(`/home`);
									}}
								>
									<AiFillHome></AiFillHome>
									<span className="title marleft-22">Home</span>
								</a>
							</li>

							<li className="has-sub">
								<a href="#">
									<AiFillInfoCircle></AiFillInfoCircle>
									<span className="title marleft-22">Info</span>
								</a>
								<ul>
									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/tripsinfo`);
											}}
										>
											<BiTrip></BiTrip>
											<span className="title marleft-22">Trips Info</span>
										</a>
									</li>

									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/tripInfoDownload`);
											}}
										>
											<BiTrip></BiTrip>
											<span className="title marleft-22">Download Trips Info</span>
										</a>
									</li>

									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/summaries`);
											}}
										>
											<MdSummarize></MdSummarize>
											<span className="title marleft-22">Catches Summary</span>
										</a>
									</li>

									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/hatchesTemperatures`);
											}}
										>
											<FaTemperatureHigh></FaTemperatureHigh>
											<span className="title marleft-22">
												Hatches Temperatures
											</span>
										</a>
									</li>

									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/samplings`);
											}}
										>
											<FaCreativeCommonsSamplingPlus></FaCreativeCommonsSamplingPlus>
											<span className="title marleft-22">Samplings</span>
										</a>
									</li>

									<li  style={{display: "none"}}>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/bioSamplings`);
											}}
										>
											<FaCreativeCommonsSamplingPlus></FaCreativeCommonsSamplingPlus>
											<span className="title marleft-22">Biological Samplings</span>
										</a>
									</li>

									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/reports`);
											}}
										>
											<HiOutlineDocumentReport></HiOutlineDocumentReport>
											<span className="title marleft-22">Resume Report</span>
										</a>
									</li>

								</ul>
							</li>

							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										this.props.router.navigate(`/home/Vessels`);
									}}
								>
									<GiFishingBoat></GiFishingBoat>
									<span className="title marleft-22">Vessels</span>
								</a>
							</li>
							{/* <li className="has-sub">
								<a href="#">
									<GiFishingBoat></GiFishingBoat>
									<span className="title marleft-22">Vessels</span>
								</a>
								<ul>
									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/Vessels`);
											}}
										>
											<GiFishingBoat></GiFishingBoat>
											<span className="title marleft-22">Vessels</span>
										</a>
									</li>
									<li>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/hatch`);
											}}>
											<GiFloorHatch></GiFloorHatch>
											<span className="title marleft-22">Hatch</span>
										</a>
									</li>
								</ul>
							</li> */}
							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										this.props.router.navigate(`/home/Clients`);
									}}
								>
									<RiUser2Line></RiUser2Line>
									<span className="title marleft-22">Clients</span>
								</a>
							</li>
							<li className="has-sub">
								<a href="#">
									<GiDatabase></GiDatabase>
									<span className="title marleft-22">Metadata</span>
								</a>
								<ul>
									<li className="has-sub">
										<a href="#">
											<FaWarehouse></FaWarehouse>
											<span className="title marleft-22">Ports</span>
										</a>
										<ul>
											<li>
												<a
													href="#"
													onClick={() => {
														this.props.router.navigate(`/home/Ports`);
													}}
												>
													<FaWarehouse></FaWarehouse>
													<span className="title marleft-22">Ports</span>
												</a>
											</li>
											<li>
												<a
													href="#"
													onClick={() => {
														this.props.router.navigate(`/home/piers`);
													}}
												>
													<GiWoodenPier></GiWoodenPier>
													<span className="title marleft-22">Piers</span>
												</a>
											</li>
										</ul>
									</li>
									<li className="has-sub">
										<a href="#">
											<FaFish></FaFish>
											<span className="title marleft-22">Fish</span>
										</a>
										<ul>
											<li>
												<a
													href="#"
													onClick={() => {
														this.props.router.navigate(`/home/fishspecies`);
													}}
												>
													<GiAnglerFish></GiAnglerFish>
													<span className="title marleft-22">Species</span>
												</a>
											</li>
											<li>
												<a
													href="#"
													onClick={() => {
														this.props.router.navigate(`/home/fishsizes`);
													}}
												>
													<GiSchoolOfFish></GiSchoolOfFish>
													<span className="title marleft-22">Sizes</span>
												</a>
											</li>
										</ul>
									</li>
									<li style={{display: "none"}}>
										<a
											href="#"
											onClick={() => {
												this.props.router.navigate(`/home/motives`);
											}}
										>
											<GiInfo></GiInfo>
											<span className="title marleft-22">Motives</span>
										</a>
									</li>
								</ul>
							</li>
							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										this.props.router.navigate(`/home/Users`);
									}}
								>
									<FiUsers></FiUsers>
									<span className="title marleft-22">Users</span>
								</a>
							</li>
							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										this.props.router.navigate(`/home/Settings`);
									}}
								>
									<i className="fa fa-gear" style={{ width: 30 }}></i>
									<span className="title">Settings</span>
								</a>
							</li>
							{/* <li className="root-level">
								<a
									href="#"
									onClick={() => {
										this.props.router.navigate(`/home/settings`);
									}}
								>
									<FiSettings></FiSettings>
									<span className="title marleft-22">Settings</span>
								</a>
							</li> */}

							{/* <li className="root-level">
								<a href={apk} download="elogbook.apk">
									<AiOutlineCloudDownload></AiOutlineCloudDownload>

									<span className="title marleft-22">
										Download App (v 1.0.11)

									</span>s
									<br />
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(to use in vessel only)
								</a>
							 
							</li> */}

							<li className="root-level">
							<a href={apkInternet} download="elogbook-internet.apk">
									<AiOutlineCloudDownload></AiOutlineCloudDownload>

									<span className="title marleft-22">
										Download App (v 1.0.21)

									</span>
									 
								</a>
							 
							</li>


							<li className="root-level">
								<a
									href="#"
									onClick={() => {
										setAccessToken("");
										localStorage.removeItem("token");
										// this.props.router.navigate("/login");
										window.location.reload(false);
									}}
								>
									<FaSignOutAlt></FaSignOutAlt>
									<span className="title marleft-22">Logout</span>
								</a>
							</li>

							<li className="root-level">
								<p
									id={"BitcliqText"}
									style={{
										color: "rgb(170, 171, 174)",
										textAlign: "center",
										marginTop: 10,
									}}
								>
									2024 © Bitcliq Technologies S.A.
								</p>
								<p
									id={"AppVersion"}
									style={{
										color: "rgb(170, 171, 174)",
										textAlign: "center",
										marginTop: 10,
									}}
								>
									Version 1.0.0
								</p>
								<div
									id={"LogoSidebar"}
									style={{
										width: "100%",
										paddingTop: 5,
										paddingBottom: 5,
										display: "flex",
										justifyContent: "center",
										display: "none",
									}}
								>
									<img width={20} src={logo}></img>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div
					className="main-content"
					style={{ color: "gray", overflowX: "scroll", height: "100%" }}
				>
					{
						// window.location.pathname.split("/")[2] !== undefined ?
						<Outlet context={{ refreshstate: this.state.refresh }} />
						// :
						//     <div>
						//         <Home />
						//     </div>
					}
				</div>
			</div>
		);

		return (
			<div style={{ width: "100%", height: "100%" }}>
				{/* <div id="home" className={`main-content`}> */}
				<div className="main-content">
					<Outlet context={{ refreshstate: this.state.refresh }} />
				</div>

				{/* <div id="home" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Home" ? "content" : "d-none"}`}>
					<h1>Home</h1>
                </div>

                <div id="sites" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Sites" ? "content" : "d-none"}`}>
                    <Sites forceRefresh={this.state.refresh}/>
                </div>

                
                <div id="forms" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Forms" ? "content" : "d-none"}`}>
					<Forms/>
                </div>
                
				<div id="categories" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Categories" ? "content" : "d-none"}`}>
					<Categories/>
                </div>
				<div id="users" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Users" ? "content" : "d-none"}`} >
					<Users/>
                </div>
				<div id="settings" className={`col-md-10 p-5 overflow-auto ${this.state.selectedMenu === "Settings" ? "content" : "d-none"}`} >
					<Settings/>
                </div> */}
			</div>
			// </div>
		);
	}
}

export default withRouter(HomePage);

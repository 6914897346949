import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

export const getClients = async () => {
    axios.defaults.withCredentials = false

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/Client/getAllClients`,{
            headers: {
                'Authorization': `Bearer ${localtoken}`
            }
        })
        .then(response => {

            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log("Error: ", error);
            return [];
        });
    } catch (error) {
        return [];
    }
};

export const saveClient = async (client, userid) => {

    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false

        try {
            const localtoken = await localStorage.getItem('token');
            axios.post(`${process.env.REACT_APP_API_URL}/Client/save`,client,{
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                console.log("Xresponse", response);
                return resolve(response.data);
            })
            .catch(error => {
                console.log("X2error", error);
                ErrorNotification(error?.response?.data?.message);
                return reject(error);
            });
        } catch (error) {
            console.log("X1error", error);
            return reject("ERROR");
        }
    })
};


export const deleteClient = async (clientid, userid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false

        var specieData = {
            "id": clientid,
            "deletedBy": userid
        }

        try {
            const localtoken = await localStorage.getItem('token');
            axios.post(`${process.env.REACT_APP_API_URL}/Client/delete`,specieData,{
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                ErrorNotification(error.response.data.message);
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};
import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

export const getPorts = async () => {
    axios.defaults.withCredentials = false

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/Port/getAllPorts`,{
            headers: {
                'Authorization': `Bearer ${localtoken}`
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
};

export const savePort = async (port, userid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false
        try {
            const localtoken = await localStorage.getItem('token');
            
            var portobj = {
                "name": port.name,
                "id": port.id,
                "createdBy": userid,
                "modifiedBy": userid,
                "deletedBy":userid
            }

            // console.log("portobj: ", portobj)

            return axios.post(`${process.env.REACT_APP_API_URL}/Port/save`,portobj, {
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};


export const deletePort = async (portid, userid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false
        try {
            const localtoken = await localStorage.getItem('token');
            
            var portobj = {
                "id": portid,
                "deletedBy":userid
            }

            // console.log("portobj: ", portobj)

            return axios.post(`${process.env.REACT_APP_API_URL}/Port/delete`,portobj, {
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};
//import react
import React from 'react';
import {updateUserPassword} from '../../api/users.js';
import { ErrorNotification, SuccessNotification } from "../../utils/notifications"

class PasswordForm extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
            loading: true,
            NewPasswordConfirmation: "",
            NewPassword: "",
            CurrentPassword: ""
		}
	}

	componentDidMount = () => {
		this.LoadAll();		
	}

    componentWillUnmount = () =>{
        window.jQuery('.modal-backdrop').remove();
    }

    componentDidUpdate = () => {
        
        window.jQuery('#modal-6').modal('show', {backdrop: 'static'});
    }

	LoadAll = async () => {
        this.setState({
            loading: false
        });
	}

    ChangeCurrentPassword = async (value) => {
        this.setState({
            CurrentPassword: value
        });
    }

    ChangeNewPassword = async (value) => {
        this.setState({
            NewPassword: value
        });
    }

    ChangeNewPasswordConfirmation = async (value) => {
        this.setState({
            NewPasswordConfirmation: value
        });
    }

    CloseForm = async () => {
        //reset state
        this.setState({
            loading: true
        });

        this.props.CloseForm();
    }

    Update = async () => {
        if(this.state.NewPassword !== this.state.NewPasswordConfirmation){
            ErrorNotification("New password and confirmation do not match.");
            return;
        }
        var result = await updateUserPassword(this.state.CurrentPassword, this.state.NewPassword, this.state.NewPasswordConfirmation);
        if((result+"").includes("ERROR")){
            return;
        }
        
        this.CloseForm();
    }


    render(){
        return (
            this.state.loading ? null : 
            <div className="modal fade" id="modal-6" tabIndex={-1}>
				<div className="modal-dialog">
					<div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.CloseForm()}>&times;</button>
                            <h4 className="modal-title">Change password</h4>
                        </div>
                        <div className="modal-body custom-light-gray">
                            <h6>Current Password</h6>
                            <input className="form-control" value={this.state.CurrentPassword} type={"password"} onChange={(e) => this.ChangeCurrentPassword(e.target.value) }></input>
                            <h6 style={{marginTop: 10}}>New Password</h6>
                            <input className="form-control" value={this.state.NewPassword} type={"password"} onChange={(e) => this.ChangeNewPassword(e.target.value) }></input>
                            <h6 style={{marginTop: 10}}>New Password confirmation</h6>
                            <input className="form-control" value={this.state.NewPasswordConfirmation} type={"password"} onChange={(e) => this.ChangeNewPasswordConfirmation(e.target.value) }></input>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary py-2 px-4" data-bs-dismiss="modal" onClick={()=>this.Update()}>Update</button>
                            <button type="button" className="btn btn-secondary py-2 px-4" data-bs-dismiss="modal" onClick={() => this.CloseForm()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (PasswordForm);
//import react
import React from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { getUser } from "../../utils/loggedUser";
import PasswordForm from "./PasswordForm";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import { getSettings, saveSettings, getUnits } from "../../api/settings";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}
	return ComponentWithRouterProp;
}

class SettingsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			NewZoneRadius: 10,
			NewSetRadius: 2,
			NewZoneTime: new Date(),
			NewSetTime: new Date(),
			ThresholdNotification: new Date(),
			user: null,
			disableSave: false,
			units: [],
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	};

	LoadAll = async () => {
		try {
			var user = await getUser();
			var currentSettings = await getSettings();
			var units = await getUnits();

			console.log("currentSettings", currentSettings);
			console.log("units", units);

			this.setState({
				loading: false,
				user: user,
				currentSettings: currentSettings,
				units: units,
				// NewZoneRadius:
				// 	currentSettings?.newZoneRadius ?? this.state.NewZoneRadius,
				// NewSetRadius: currentSettings?.newSetRadius ?? this.state.NewSetRadius,
				// NewZoneTime:
				// 	new Date(
				// 		new Date().setHours(
				// 			(currentSettings?.newZoneTime + "").split(":")[0],
				// 			(currentSettings?.newZoneTime + "").split(":")[1]
				// 		)
				// 	) ?? this.state.NewZoneTime,
				// NewSetTime:
				// 	new Date(
				// 		new Date().setHours(
				// 			(currentSettings?.newSetTime + "").split(":")[0],
				// 			(currentSettings?.newSetTime + "").split(":")[1]
				// 		)
				// 	) ?? this.state.NewSetTime,
				// ThresholdNotification:
				// 	new Date(
				// 		new Date().setHours(
				// 			(currentSettings?.thresholdNotification + "").split(":")[0],
				// 			(currentSettings?.thresholdNotification + "").split(":")[1]
				// 		)
				// 	) ?? this.state.ThresholdNotification,
			});
			// console.log("user ->", user)
		} catch (error) {
			console.log("Error.", error);
		}
	};

	save = async () => {
		//#region Save Settings
		/*
		console.log(
			"this.state.currentSettings?.generatorUnitID ?? -1) == -1",
			(this.state.currentSettings?.generatorUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.waterStockUnitID ?? -1) == -1",
			(this.state.currentSettings?.waterStockUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.fuelStockUnitID ?? -1) == -1",
			(this.state.currentSettings?.fuelStockUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.engineUnitID ?? -1) == -1",
			(this.state.currentSettings?.engineUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.catchWeightUnitID ?? -1) == -1",
			(this.state.currentSettings?.catchWeightUnitID ?? -1) == -1
		);

		console.log(
			"this.state.currentSettings?.sampleWeightUnitID ?? -1) == -1",
			(this.state.currentSettings?.sampleWeightUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.sampleLenghtUnitID ?? -1) == -1",
			(this.state.currentSettings?.sampleLenghtUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.unloadingWeightUnitID ?? -1) == -1",
			(this.state.currentSettings?.unloadingWeightUnitID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.hatchTemperatureUnloadingID ?? -1) == -1",
			(this.state.currentSettings?.hatchTemperatureUnloadingID ?? -1) == -1
		);
		console.log(
			"this.state.currentSettings?.stomachFillingLevelUnitID ?? -1) == -1",
			(this.state.currentSettings?.stomachFillingLevelUnitID ?? -1) == -1
		);
		*/

		//#endregion

		if (
			(this.state.currentSettings?.generatorUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.waterStockUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.fuelStockUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.engineUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.catchWeightUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.sampleWeightUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.sampleLenghtUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.unloadingWeightUnitID ?? -1) == -1 ||
			(this.state.currentSettings?.hatchTemperatureUnloadingID ?? -1) == -1 ||
			(this.state.currentSettings?.stomachFillingLevelUnitID ?? -1) == -1
		) {
			ErrorNotification("All units must be set before saving.");
			return;
		}

		this.setState({ disableSave: true });
		saveSettings(this.state.currentSettings, this.state.user.userID)
			.then((resp) => {
				SuccessNotification("Settings saved successfully");
			})
			.catch((err) => {
				ErrorNotification("Error saving settings");
			})
			.finally(() => {
				this.setState({ disableSave: false });
			});
	};

	render() {
		if (this.state.loading) return null;

		return (
			<React.Fragment>
				<h2 className="mb-4">Settings</h2>
				<div className="d-flex py-2 px-4 mb-4 me-4">
					{/* <button key={"NewSite"} type="button" className="btn btn-esip-blue py-2 px-4 mb-4 me-4 custom-letter-spacing"><i className="fas fa-plus"></i>&nbsp;Create Site</button> */}
				</div>
				<hr />
				<div
					className="p-0"
					style={{
						borderBottom: 0,
						borderLeft: 0,
						borderRight: 0,
						height: "85%",
					}}
				>
					<div className="container">
						{/* <div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="ZoneRadiusInput">New Zone Radius (Km)</label>
									<input
										type="number"
										className="form-control"
										value={this.state?.NewZoneRadius}
										onChange={(e) => {
											var valueHasNumber = Number(e.target.value);

											if (isNaN(valueHasNumber)) {
												ErrorNotification("Zone Radius must be a number");
												return;
											}

											this.setState({
												NewZoneRadius: valueHasNumber,
											});
										}}
										id="ZoneRadiusInput"
										aria-describedby="username"
										placeholder="Username"
									></input>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mt-3">
									<label htmlFor="ZoneTimeInput">New Zone Time</label>
									<DatePicker
										selected={this.state.NewZoneTime}
										//value={this.state.Vessel?.notificationHour}

										onChange={(e) => {
											this.setState({
												NewZoneTime: e,
											});
										}}
										className="form-control"
										showTimeSelect
										timeIntervals={1}
										showTimeSelectOnly
										dateFormat="HH:mm"
										timeFormat="HH:mm"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="SetRadiusinput">New Set Radius (Km)</label>
									<input
										type="number"
										className="form-control"
										value={this.state?.NewSetRadius}
										onChange={(e) => {
											var valueHasNumber = Number(e.target.value);

											if (isNaN(valueHasNumber)) {
												ErrorNotification("Set Radius must be a number");
												return;
											}

											this.setState({
												NewSetRadius: valueHasNumber,
											});
										}}
										id="SetRadiusinput"
										aria-describedby="username"
										placeholder="Username"
									></input>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mt-3">
									<label htmlFor="SetTimeInput">New Set Time</label>
									<DatePicker
										selected={this.state.NewSetTime}
										//value={this.state.Vessel?.notificationHour}

										onChange={(e) => {
											this.setState({
												NewSetTime: e,
											});
										}}
										className="form-control"
										timeIntervals={1}
										showTimeSelect
										showTimeSelectOnly
										dateFormat="HH:mm"
										timeFormat="HH:mm"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6"></div>
							<div className="col-md-6">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Threshold Notification Time
									</label>
									<DatePicker
										selected={this.state.ThresholdNotification}
										//value={this.state.Vessel?.notificationHour}

										onChange={(e) => {
											this.setState({
												ThresholdNotification: e,
											});
										}}
										className="form-control"
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={1}
										dateFormat="HH:mm"
										timeFormat="HH:mm"
									/>
								</div>
							</div>
						</div>
						<hr /> */}
						<h2>Units</h2>
						<hr />
						<div className="row">
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">Generators Unit</label>
									<select
										defaultValue={
											this.state.currentSettings?.generatorUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.generatorUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Water Stock Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.waterStockUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.waterStockUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">Fuel Stock Unit</label>
									<select
										defaultValue={
											this.state.currentSettings?.fuelStockUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.fuelStockUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">Engine Unit</label>
									<select
										defaultValue={
											this.state.currentSettings?.engineUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.engineUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Catch Weight Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.catchWeightUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.catchWeightUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Sample Weight Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.sampleWeightUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.sampleWeightUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Sample Lenght Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.sampleLenghtUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.sampleLenghtUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Unloading Weight Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.unloadingWeightUnitID ?? -1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.unloadingWeightUnitID = Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Hatch Temperature Unloading Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.hatchTemperatureUnloadingID ??
											-1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.hatchTemperatureUnloadingID =
												Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group mt-3">
									<label htmlFor="ThresholdNotification">
										Stomach Filling Level Unit
									</label>
									<select
										defaultValue={
											this.state.currentSettings?.stomachFillingLevelUnitID ??
											-1
										}
										onChange={(e) => {
											var unitID = e.target.value;

											var currentSettings = this.state.currentSettings;

											currentSettings.stomachFillingLevelUnitID =
												Number(unitID);

											this.setState({
												currentSettings: currentSettings,
											});
										}}
										className="form-control"
									>
										{this.state.units && (
											<>
												<option value={-1}>{"Select an Option"}</option>
												{this.state.units.map((obj, index) => {
													return <option value={obj.id}>{obj.name_EN}</option>;
												})}
											</>
										)}
									</select>
								</div>
							</div>
						</div>
						<div
							className="row"
							style={{
								display: "flex",
								alignItems: "flex-end",
								justifyContent: "flex-end",
							}}
						>
							<button
								type="submit"
								className="btn btn-success"
								disabled={this.state.disableSave}
								onClick={this.save}
							>
								Save
							</button>
						</div>
					</div>
				</div>
				{this.state.passwordForm ? (
					<PasswordForm CloseForm={this.PasswordForm} />
				) : null}
			</React.Fragment>
		);
	}
}

export default withRouter(SettingsPage);

//import react
import React from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { getAccessToken, setAccessToken } from "../../utils/accessToken";
import {
    getData,
    getExcel,
    getExcelByVesselIDandTripID,
    getZipDownloadLinkByVesselAndTripNumber,
    getSummaryInfoXls,
    getSummaryInfoXlsFilters

} from "../../api/exportdata";
import { getUsers } from "../../api/users";
import { getToken } from "../../api/getToken";

import moment from "moment";
import { getUser } from "../../utils/loggedUser";
import { CSVLink, CSVDownload } from "react-csv";
import { getVessels, getVesselTrips } from "../../api/vessels";
import DatePicker from "react-datepicker";

import {
    useLocation,
    useNavigate,
    useParams,
    useOutletContext,
} from "react-router-dom";
import { textAlign } from "@mui/system";
import { ErrorNotification } from "../../utils/notifications";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let outletContext = useOutletContext();
        return (
            <Component
                {...props}
                router={{ location, navigate, params, outletContext }}
            />
        );
    }
    return ComponentWithRouterProp;
}

class ReportsExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            sites: [],
            ForcedRefresh: props.router.outletContext.refreshstate,
            loadedjs: 0,
            zipdownloadlink: "",
            excelLink: "",
            selectedAllVessels: false,
            selectedType: 1,
            selectedVessels: [],
            dateStart: null,
            dateEnd: null
        };
    }

    
    componentDidUpdate = () => {

        console.log("componentDidUpdate");
        if (
            this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate
        ) {
            this.setState({
                ForcedRefresh: this.props.router.outletContext.refreshstate,
                loading: true,
                user: null,
            });
        }

        if (this.state.loading) {
            this.LoadAll();
            return;
        }

        if (this.state.loadedjs === 0) {
            this.loadjs();
        }
    };

    componentDidMount = async () => {
        if (this.state.loading) {
            this.LoadAll();
        }

        var script = document.createElement("script");
        script.id = "TweenMax-sites";
        script.src = "/assets/js/bootstrap.js";
        document.body.appendChild(script);
    };

    componentWillUnmount = async () => {
        this.unloadjs();
    };

    unloadjs = () => {
        try {
            var script = document.createElement("script");
            script.id = "remove-scripts-sites";
            script.innerHTML = "jQuery('#table-tripsifo').DataTable().destroy();";
            document.body.appendChild(script);

            document.getElementById("custom-table-render-sites").remove();
            document.getElementById("remove-scripts-sites").remove();

            window.jQuery(".modal-backdrop")[0].remove();
        } catch (error) { }
    };

    loadjs = async () => {
        var script = document.createElement("script");
        script.id = "custom-table-render-sites";
        script.src = "/assets/js/table-custom.js";
        script.async = true;
        document.body.appendChild(script);

        this.setState({
            loadedjs: this.state.loadedjs + 1,
        });
    };

    LoadAll = async () => {
        try {
            //get user
            var user = getUser();
            var vessels = await getVessels();


            var users = await getUsers();
            this.setState({
                loading: false,
                ShowDeleteForm: false,
                user: user,
                users: users,
                vessels: vessels,
            });
        } catch (error) {
            console.log("Error.", error);
        }
    };



     




    // handleSelectAll = (e) => {
    //     if (this.state.selectedAllVessels) {
    //         this.setState({
    //             selectedAllVessels: false
    //         });
    //     }
    //     else {
    //         this.setState({
    //             selectedAllVessels: true
    //         });

    //         this.state.vessels.forEach(element => {
    //             const x = this.state.selectedVessels.push(element);

    //             this.setState({
    //                 selectedVessels: x
    //             });

    //         });    

    //     }
    // };


    handleClick = (e) => {
        //const { id, checked } = e.target;
        let id = e.target.id;
        let checked = e.target.checked;

        // setIsCheck([...isCheck, id]);

        console.log("id", id);
        console.log("checked", checked);
        if (!checked) {
            //   setIsCheck(isCheck.filter(item => item !== id));
            let index = this.state.selectedVessels.indexOf(id);

            console.log("selectedVessels 1 ---> ", this.state.selectedVessels);
            console.log("index", index);
            let x = this.state.selectedVessels.splice(index, 1);
            // this.setState({
            //     selectedVessels: x
            // });
        
            this.setState({ selectedVessels: x }, () => 
                                console.log("selectedVessels unchecked 1 ---> ", this.state.selectedVessels)
                            );

                            console.log("selectedVessels unchecked 2 ---> ", this.state.selectedVessels); 

        }
        else
        {

            console.log("selectedVessels checked 1 ---> ", this.state.selectedVessels);
            let index = this.state.selectedVessels.indexOf(id);


           // console.log("index", index);
            if (index >=0 )
            {

            }
            else{
                let arry = this.state.selectedVessels;

                arry.push(id);
                // this.setState({
                //     selectedVessels: arry
                // });


                this.setState({ selectedVessels: arry }, () => 
                    console.log("selectedVessels checkd 2 ---> ", this.state.selectedVessels)
                );

            }
        }

        console.log("selectedVessels final ---> ", this.state.selectedVessels);
    };

  
      
    downloadFile = async () => {

        // console.log("this.state", this.state); 
        // await delay(1000);
        //console.log("this.state.selectedVessels in downloads", this.state.selectedVessels);
        if (this.state.selectedType == "1" || this.state.selectedType == "2")
        {
            this.setState({
                dateStart: null,
                dateEnd: null
            });
        }
        

        getSummaryInfoXlsFilters(this.state.selectedVessels, this.state.selectedType, this.state.dateStart, this.state.dateEnd)
            .then((res) => {

                
                console.log("getSummaryInfoXlsFilters", res);

                if (res != "")
                    this.getFile(res);
                else
                    ErrorNotification("No data found");
            });

    };

    // xpto =   (id) => {

    //     console.log(" this.state.selectedVessels",  this.state.selectedVessels); 
        
    //     return this.state.selectedVessels.includes(id);
    // };


    delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

      
    getFile = async (fileName) => {
        //const response = await fetch(fileName);
        //const blob = await response.blob();
        //const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileName;
        // link.download = 'my-dynamic-file.zip'; // You can set the download filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    };

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <p style={{ textAlign: "center" }}>Loading trips info</p>
                    <div
                        className="d-flex justify-content-center"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <i
                            className="fa fa-spinner fa-spin"
                            style={{ textAlign: "center", fontSize: 35 }}
                        ></i>
                    </div>
                </div>
            );
        }

        if (this.state.denied) {
            return (
                <div>
                    <p>You do not have access to this page.</p>
                </div>
            );
        }

        if (this.state.downloadcsv) {
            this.unloadjs();

            this.setState({
                downloadcsv: false,
                loadedjs: 0,
            });

            // console.log("this.state.exceldata", this.state.exceldata)

            return (
                <CSVDownload
                    data={this.state.exceldata}
                    enclosingCharacter={``}
                    target="_blank"
                />
            );
        }

        return (
            <>
                <h2 style={{ marginBottom: 10 }}>Download Excel Trip Info</h2>
                <div style={{ marginBottom: 10, paddingTop: 40 }}>

                    <div className="row">

                        <div className="col-md-2">
                           
                           
                            {this.state.vessels &&
                                this.state.vessels.map((vessel) => {
                                    return (
                                        <>

                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id={vessel.id} name={vessel.id} value={vessel.id}
                                                    onChange={this.handleClick}
                                                     />
                                                <label class="form-check-label" for={vessel.id}>&nbsp;&nbsp;{vessel.name}</label>
                                            </div>
                                        </>
                                    );
                                })}

                        </div>
                        <div className="col-md-2">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group no-margin">
                                        <label  className="control-label">
                                            Filter:
                                        </label>
                                        <select
                                            className="form-control"
                                            style={{ maxWidth: 200 }}
                                            onChange={(e) => {
                                                var tripid = e.target.value;

                                                this.setState(
                                                    {
                                                        selectedType: tripid,
                                                    }
                                                    // ,
                                                    // () => {
                                                    // 	if (this.state.selectedType == "3" || this.state.selectedType == "4") {
                                                    // 		this.getExcelFromVesselTrip();
                                                    // 	} else {
                                                    // 		this.state.gridApi.setRowData([]);
                                                    // 		this.state.gridApi.setColumnDefs([]);
                                                    // 	}
                                                    // }
                                                );
                                            }}>
                                            <option value="1">Current trip</option>
                                            <option value="2">Last finished trip</option>
                                            <option value="3">Current trip - Date range</option>
                                            <option value="4">Finished trip - Date range</option>

                                        </select>
                                    </div></div> </div>
                        </div>
                        <div className="col-md-4">
                            {(this.state.selectedType == "3" || this.state.selectedType == "4") &&

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group no-margin">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="control-label">
                                                Start Date:
                                            </label>
                                            <DatePicker
                                                selected={this.state.dateStart}
                                                className="dpStyle"
                                                onChange={(date) => {
                                                    this.setState({ dateStart: date });
                                                   
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group no-margin">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="control-label">
                                                End Date:
                                            </label>
                                            <DatePicker
                                                selected={this.state.dateEnd}
                                                className="dpStyle"
                                                onChange={(date) => {
                                                    this.setState({ dateEnd: date });
                                                     
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            }

                        </div>
                        <div className="col-md-4" style={{ textAlign: "right" }}>
                            <button
                                key={"Full Export Excel"}
                                type="button"
                                style={{
                                    marginLeft: 15,
                                    backgroundColor: "darkgreen",
                                    color: "white",
                                }}

                                className="btn"
                                onClick={() => {
                                    //open new tab
                                    this.downloadFile();
                                }}
                            >
                                {/* icon for zip file */}
                                <i className="fa fa-download"></i> Export Trip Info (Excel)
                            </button>


                        </div>
                    </div>
                </div>



            </>
        );


    }
}

export default withRouter(ReportsExcel);

//import react
import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useNavigate,
	BrowserRouter,
} from "react-router-dom";
import LoginPage from "./containers/Login";
import HomePage from "./containers/HomePage";
import Downloads from "./Downloads";
import Privacy from "./Privacy";
import { setAccessToken } from "./utils/accessToken";
import { refreshToken } from "./api/getToken";
import { setUser, getUser } from "./utils/loggedUser";
import FormFilling from "./FormFilling";
import Ports from "./containers/Ports";
import Vessels from "./containers/Vessels";
import Forms from "./containers/Templates";
import Users from "./containers/Users";
import Settings from "./containers/Settings";
import Categories from "./containers/Categories";
import "react-notifications-component/dist/theme.css";
import Home from "./containers/Home";
import FishSpecies from "./containers/FishSpecies";
import FishSizes from "./containers/FishSizes";
import TripsInfo from "./containers/TripsInfo";
import Clients from "./containers/Clients";
import Piers from "./containers/Piers";
import Hatch from "./containers/Hatch";
import Motives from "./containers/Motives";
import Summaries from "./containers/summaries";
import HatchesTemperatures from "./containers/hatchesTemperatures";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Samplings from "./containers/Samplings";
import BioSamplings from "./containers/BioSamplings";
import ForgotPassword from "./containers/ForgotPassword";
import RestorePassword from "./containers/RestorePassword";
import Reports from "./containers/Reports";
import ReportsExcel from "./containers/ReportsExcel";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
		// console.log("APP js constructor!");
	}

	componentDidUpdate = async () => {
		// console.log("APP js componentDidUpdate!");
	};

	componentDidMount = async () => {
		// console.log("APP js componentDidMount!");
		let response = await refreshToken();

		if (
			!response.token ||
			response.token === "" ||
			(response + "").includes("ERROR")
		) {
			this.setState({
				loading: false,
			});
			return;
		}

		setAccessToken(response.token);
		setUser(response.user);

		this.setState({
			loading: false,
		});
	};

	render() {
		return !this.state.loading ? (
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<LoginPage />} />
					<Route exact path="/login" element={<LoginPage />} />
					<Route exact path="/forgotpassword" element={<ForgotPassword />} />

					<Route exact path="/restorepassword" element={<RestorePassword />} />
					<Route path="/home" element={<HomePage />}>
						<Route path="/home/ports" element={<Ports />} />
						<Route path="/home/vessels" element={<Vessels />} />
						<Route path="/home/fishspecies" element={<FishSpecies />} />
						<Route path="/home/fishsizes" element={<FishSizes />} />
						<Route path="/home/piers" element={<Piers />} />
						<Route path="/home/forms" element={<Forms />} />
						<Route path="/home/hatch" element={<Hatch />} />
						<Route path="/home/tripsinfo" element={<TripsInfo />} />
						<Route path="/home/clients" element={<Clients />} />
						<Route path="/home/categories" element={<Categories />} />
						<Route path="/home/users" element={<Users />} />
						<Route path="/home/settings" element={<Settings />} />
						<Route path="/home/motives" element={<Motives />} />
						<Route path="/home/summaries" element={<Summaries />} />
						<Route path="/home/tripInfoDownload" element={<ReportsExcel />} />
						<Route
							path="/home/hatchesTemperatures"
							element={<HatchesTemperatures />}
						/>
						<Route path="/home/samplings" element={<Samplings />} />
						<Route path="/home/bioSamplings" element={<BioSamplings />} />
						<Route path="/home/samplings" element={<Settings />} />
						<Route path="/home/reports" element={<Reports />} />
						<Route path="/home" element={<Home />} />
					</Route>
					<Route exact path="/downloads" element={<Downloads />} />
					<Route exact path="/privacy" element={<Privacy />} />
					<Route exact path="/fill" element={<FormFilling />} />

					{/* <Route
					path="*"
					element={<Navigate to="/" />}
				/> */}
				</Routes>
			</BrowserRouter>
		) : (
			<div className="d-flex justify-content-center">
				<div className="spinner-border text-primary" role="status">
					<span className="sr-only">Loading</span>
				</div>
			</div>
		);
	}
}

export default App;

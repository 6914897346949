//import react
import React from "react";
//import get user
import { getUser, UpdateProfile } from "../../api/users";
import {
	getTopSpecies,
	getTopVessels,
	getSumCatches,
} from "../../api/statistics";
import { getVesselHatchesTemperatures } from "../../api/summaries";

import { getCurrentTripsHatchesTemperatures } from "../../api/summaries";
import { getUser as GU } from "../../utils/loggedUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import { getPorts } from "../../api/ports";
import { getFishSpecies } from "../../api/fishspecies";
import { getVessels } from "../../api/vessels";
import { getClients } from "../../api/clients";
import { AgChartsReact } from "ag-charts-react";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}
	return ComponentWithRouterProp;
}

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			reload: false,
			piedata: [],
			TopVesselsTopAmount: 5,
			TopVesselsStartDate: new Date(
				new Date().setDate(new Date().getDate() - 7)
			),
			TopVesselsEndDate: new Date(),
			TopSpeciesTopAmount: 5,
			TopSpeciesStartDate: new Date(
				new Date().setDate(new Date().getDate() - 7)
			),
			TopSpeciesEndDate: new Date(),
			TopPortsTopAmount: 5,
			TopPortsStartDate: new Date(new Date().setDate(new Date().getDate() - 5)),
			TopPortsEndDate: new Date(),
			temperatureData: [],
		};

		this.chartRef = React.createRef(null);
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	componentWillUnmount = () => {
		// console.log("home unmount!")
		this.setState({
			reload: false,
		});
	};

	LoadAll = async () => {
		try {
			var user = GU();
			// var countStatistics = await getStatistics();
			// var graphStatistics = await GraphStatistics();

			var ports = await getPorts();
			var species = await getFishSpecies();
			var vessels = await getVessels();
			var clients = await getClients();

			var topvessels = await this.topvessels(false);
			var topSpecies = await this.topcatches(false);
			// var sumcatches = await this.sumcatches(false);

			this.setState({
				loading: false,
				user: user,
				countStatistics: {
					Ports: ports.length,
					Species: species.length,
					Vessels: vessels.length,
					Clients: clients.length,
				},
				topVessels: topvessels,
				topSpecies: topSpecies,
				AllVessels: vessels,
				// sumCatches: sumcatches,
				// graphStatistics: graphStatistics,
				// reload: true
			});
			// console.log("user ->", user)
		} catch (error) {
			console.log("Error.", error);
		}
	};

	// sumcatches = async (reload, startdate, enddate) => {
	// 	try {
	// 		var sd = startdate ? startdate : this.state.TopVesselsStartDate;
	// 		var ed = enddate ? enddate : this.state.TopVesselsEndDate;
	// 		var sumCatches = await getSumCatches(sd, ed);
	// 		// this.setState({
	// 		// 	sumCatches: sum
	// 		// });

	// 		var piedata = [];

	// 		for (const sumcatch of sumCatches) {
	// 			piedata.push({

	// 			});
	// 		}

	// 		if (reload) {
	// 			this.setState({
	// 				topVessels: piedata,
	// 			});
	// 		}

	// 		return sum;
	// 	} catch (error) {
	// 		console.log("Error.", error);
	// 	}
	// };

	topvessels = async (reload, startdate, enddate) => {
		var sd = startdate ? startdate : this.state.TopVesselsStartDate;
		var ed = enddate ? enddate : this.state.TopVesselsEndDate;

		var topVessels = await getTopVessels(
			this.state.TopVesselsTopAmount,
			sd,
			ed
		);

		var piedata = [];

		for (const vessel of topVessels) {
			piedata.push({
				type: vessel.Name,
				value: vessel.Quantity,
			});
		}

		if (reload) {
			this.setState({
				topVessels: piedata,
			});
		}

		return piedata;
	};

	topcatches = async (reload, startdate, enddate) => {
		var sd = startdate ? startdate : this.state.TopSpeciesStartDate;
		var ed = enddate ? enddate : this.state.TopSpeciesEndDate;

		var topSpecies = await getTopSpecies(
			this.state.TopSpeciesTopAmount,
			sd,
			ed
		);

		var piedata = [];

		for (const specie of topSpecies) {
			piedata.push({
				type: specie.Specie,
				value: specie.Quantity,
			});
		}

		if (reload) {
			this.setState({
				topSpecies: piedata,
			});
		}

		return piedata;
	};

	compare = (a, b) => {
		if (a.Hatch < b.Hatch) {
			return -1;
		}
		if (a.Hatch > b.Hatch) {
			return 1;
		}
		return 0;
	};

	vesselTemperatures = async (reload, vesselID) => {
		var temps = await getVesselHatchesTemperatures(vesselID);
		console.log("asdsalkndd", temps);

		var temperatureData = [];
		var Series = [];
		var Hatches = [];

		for (const temp of temps) {
			var tempval = Number(temp.Temperature);
			if (isNaN(tempval)) {
				continue;
			}
			var x = new Date(temp.CreatedDate);
			var xformated =
				x.getFullYear() +
				"-" +
				(x.getMonth() + 1) +
				"-" +
				x.getDate() +
				" " +
				x.getHours() +
				":" +
				x.getMinutes();

			var aux = {
				time: xformated,
			};

			aux[temp.Hatch] = tempval;

			temperatureData.push(aux);

			if (!Hatches.includes(temp.Hatch)) {
				Hatches.push(temp.Hatch);
			}
		}

		for (const hatch of Hatches) {
			var res = temperatureData.filter((x) => x[hatch] !== undefined);
			Series.push({
				data: res,
				type: "line",
				title: hatch,
				xKey: "time",
				yKey: hatch,
				marker: {
					size: 10,
					shape: "square",
				},
			});
		}

		if (reload) {
			this.setState({
				temperatures: temperatureData,
				TemperatureSeries: Series,
			});
		}

		return temperatureData;
	};

	render() {
		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "center", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className="row">
					{/* <!-- Profile Info and Notifications --> */}
					<div className="col-md-6 col-sm-8 clearfix">
						<ul className="user-info pull-left pull-none-xsm">
							{/* <!-- Profile Info --> */}
							<li className="profile-info dropdown">
								{/* <!-- add class "pull-right" if you want to place this from right --> */}

								<a href="#" className="dropdown-toggle" data-toggle="dropdown">
									{/* <img src="assets/images/thumb-1@2x.png" alt="" className="img-circle" width="44" /> */}
									{"Welcome, " + this.state.user.name}
								</a>

								<ul className="dropdown-menu" style={{ display: "none" }}>
									{/* <!-- Reverse Caret --> */}
									<li className="caret"></li>

									{/* <!-- Profile sub-links --> */}
									<li>
										<a href="extra-timeline.html">
											<i className="entypo-user"></i>
											Edit Profile
										</a>
									</li>

									<li>
										<a href="mailbox.html">
											<i className="entypo-mail"></i>
											Inbox
										</a>
									</li>

									<li>
										<a href="extra-calendar.html">
											<i className="entypo-calendar"></i>
											Calendar
										</a>
									</li>

									<li>
										<a href="#">
											<i className="entypo-clipboard"></i>
											Tasks
										</a>
									</li>
								</ul>
							</li>
						</ul>

						<ul
							className="user-info pull-left pull-right-xs pull-none-xsm"
							style={{ display: "none" }}
						>
							{/* <!-- Raw Notifications --> */}
							<li className="notifications dropdown">
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-hover="dropdown"
									data-close-others="true"
								>
									<i className="entypo-attention"></i>
									<span className="badge badge-info">6</span>
								</a>

								<ul className="dropdown-menu">
									<li className="top">
										<p className="small">
											<a href="#" className="pull-right">
												Mark all Read
											</a>
											You have <strong>3</strong> new notifications.
										</p>
									</li>

									<li>
										<ul className="dropdown-menu-list scroller">
											<li className="unread notification-success">
												<a href="#">
													<i className="entypo-user-add pull-right"></i>

													<span className="line">
														<strong>New user registered</strong>
													</span>

													<span className="line small">30 seconds ago</span>
												</a>
											</li>

											<li className="unread notification-secondary">
												<a href="#">
													<i className="entypo-heart pull-right"></i>

													<span className="line">
														<strong>Someone special liked this</strong>
													</span>

													<span className="line small">2 minutes ago</span>
												</a>
											</li>

											<li className="notification-primary">
												<a href="#">
													<i className="entypo-user pull-right"></i>

													<span className="line">
														<strong>Privacy settings have been changed</strong>
													</span>

													<span className="line small">3 hours ago</span>
												</a>
											</li>

											<li className="notification-danger">
												<a href="#">
													<i className="entypo-cancel-circled pull-right"></i>

													<span className="line">John cancelled the event</span>

													<span className="line small">9 hours ago</span>
												</a>
											</li>

											<li className="notification-info">
												<a href="#">
													<i className="entypo-info pull-right"></i>

													<span className="line">
														The server is status is stable
													</span>

													<span className="line small">
														yesterday at 10:30am
													</span>
												</a>
											</li>

											<li className="notification-warning">
												<a href="#">
													<i className="entypo-rss pull-right"></i>

													<span className="line">
														New comments waiting approval
													</span>

													<span className="line small">last week</span>
												</a>
											</li>
										</ul>
									</li>

									<li className="external">
										<a href="#">View all notifications</a>
									</li>
								</ul>
							</li>

							{/* <!-- Message Notifications --> */}
							<li className="notifications dropdown">
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-hover="dropdown"
									data-close-others="true"
								>
									<i className="entypo-mail"></i>
									<span className="badge badge-secondary">10</span>
								</a>

								<ul className="dropdown-menu">
									<li>
										<form className="top-dropdown-search">
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													placeholder="Search anything..."
													name="s"
												/>
											</div>
										</form>

										<ul className="dropdown-menu-list scroller">
											<li className="active">
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-1@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														<strong>Luc Chartier</strong>- yesterday
													</span>

													<span className="line desc small">
														This ain’t our first item, it is the best of the
														rest.
													</span>
												</a>
											</li>

											<li className="active">
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-2@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														<strong>Salma Nyberg</strong>- 2 days ago
													</span>

													<span className="line desc small">
														Oh he decisively impression attachment friendship so
														if everything.
													</span>
												</a>
											</li>

											<li>
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-3@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														Hayden Cartwright - a week ago
													</span>

													<span className="line desc small">
														Whose her enjoy chief new young. Felicity if ye
														required likewise so doubtful.
													</span>
												</a>
											</li>

											<li>
												<a href="#">
													<span className="image pull-right">
														<img
															src="assets/images/thumb-4@2x.png"
															width="44"
															alt=""
															className="img-circle"
														/>
													</span>

													<span className="line">
														Sandra Eberhardt - 16 days ago
													</span>

													<span className="line desc small">
														On so attention necessary at by provision otherwise
														existence direction.
													</span>
												</a>
											</li>
										</ul>
									</li>

									<li className="external">
										<a href="mailbox.html">All Messages</a>
									</li>
								</ul>
							</li>

							{/* <!-- Task Notifications --> */}
							<li className="notifications dropdown">
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-hover="dropdown"
									data-close-others="true"
								>
									<i className="entypo-list"></i>
									<span className="badge badge-warning">1</span>
								</a>

								<ul className="dropdown-menu">
									<li className="top">
										<p>You have 6 pending tasks</p>
									</li>

									<li>
										<ul className="dropdown-menu-list scroller">
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Procurement</span>
														<span className="percent">27%</span>
													</span>

													<span className="progress">
														<span
															style={{ width: "27%" }}
															className="progress-bar progress-bar-success"
														>
															<span className="sr-only">27% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">App Development</span>
														<span className="percent">83%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "83%" }}
															className="progress-bar progress-bar-danger"
														>
															<span className="sr-only">83% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">HTML Slicing</span>
														<span className="percent">91%</span>
													</span>

													<span className="progress">
														<span
															style={{ width: "91%" }}
															className="progress-bar progress-bar-success"
														>
															<span className="sr-only">91% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Database Repair</span>
														<span className="percent">12%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "12%" }}
															className="progress-bar progress-bar-warning"
														>
															<span className="sr-only">12% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Backup Create Progress</span>
														<span className="percent">54%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "54%" }}
															className="progress-bar progress-bar-info"
														>
															<span className="sr-only">54% Complete</span>
														</span>
													</span>
												</a>
											</li>
											<li>
												<a href="#">
													<span className="task">
														<span className="desc">Upgrade Progress</span>
														<span className="percent">17%</span>
													</span>

													<span className="progress progress-striped">
														<span
															style={{ width: "17%" }}
															className="progress-bar progress-bar-important"
														>
															<span className="sr-only">17% Complete</span>
														</span>
													</span>
												</a>
											</li>
										</ul>
									</li>

									<li className="external">
										<a href="#">See all tasks</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>

					{/* <!-- Raw Links --> */}
					<div className="col-md-6 col-sm-4 clearfix hidden-xs">
						<ul className="list-inline links-list pull-right">
							{/* <!-- Language Selector --> */}
							<li
								className="dropdown language-selector"
								style={{ display: "none" }}
							>
								Language: &nbsp;
								<a
									href="#"
									className="dropdown-toggle"
									data-toggle="dropdown"
									data-close-others="true"
								>
									<img
										src="assets/images/flags/flag-uk.png"
										width="16"
										height="16"
									/>
								</a>
								<ul className="dropdown-menu pull-right">
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-de.png"
												width="16"
												height="16"
											/>
											<span>Deutsch</span>
										</a>
									</li>
									<li className="active">
										<a href="#">
											<img
												src="assets/images/flags/flag-uk.png"
												width="16"
												height="16"
											/>
											<span>English</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-fr.png"
												width="16"
												height="16"
											/>
											<span>François</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-al.png"
												width="16"
												height="16"
											/>
											<span>Shqip</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img
												src="assets/images/flags/flag-es.png"
												width="16"
												height="16"
											/>
											<span>Español</span>
										</a>
									</li>
								</ul>
							</li>

							<li className="sep" style={{ display: "none" }}></li>

							<li style={{ display: "none" }}>
								<a href="#" data-toggle="chat" data-collapse-sidebar="1">
									<i className="entypo-chat"></i>
									Chat
									<span className="badge badge-success chat-notifications-badge is-hidden">
										0
									</span>
								</a>
							</li>

							<li className="sep" style={{ display: "none" }}></li>

							<li style={{ display: "none" }}>
								<a href="extra-login.html">
									Log Out <i className="entypo-logout right"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="row" style={{ marginTop: 25, marginBottom: 25 }}>
					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-red"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Ports`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-building"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div className="num">
								{this.state.countStatistics?.Ports ?? 0}
							</div>

							<h3>Ports</h3>
							{/* <p>created so far.</p> */}
						</div>
					</div>

					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-green"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/FishSpecies`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-bars"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div className="num">
								{this.state.countStatistics?.Species ?? 0}
							</div>

							<h3>Species</h3>
							{/* <p>this is the average value.</p> */}
						</div>
					</div>

					<div className="clear visible-xs"></div>

					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-aqua"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Vessels`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-tags"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div className="num">
								{this.state.countStatistics?.Vessels ?? 0}
							</div>

							<h3>Vessels</h3>
							<p></p>
						</div>
					</div>

					<div className="col-sm-3 col-xs-6">
						<div
							className="tile-stats tile-blue"
							style={{ height: 125 }}
							onClick={() => {
								this.props.router.navigate(`/home/Clients`);
							}}
						>
							<div
								className="icon"
								style={{ top: 0, bottom: 0, display: "flex" }}
							>
								<i
									className="fa fa-users"
									style={{ alignSelf: "center", fontSize: 75 }}
								></i>
							</div>
							<div className="num">
								{this.state.countStatistics?.Clients ?? 0}
							</div>

							<h3>Clients</h3>
							<p></p>
						</div>
					</div>
				</div>
				<hr></hr>
				<div className="row">
					<div className="col-md-6" style={{ height: 300 }}>
						<div style={{ backgroundColor: "#f5f5f5", display: "flex" }}>
							<div
								style={{
									width: "50%",
									display: "flex",
									justifyContent: "center",
									marginTop: 5,
								}}
							>
								<div style={{ width: "50%" }}>
									<DatePicker
										selected={this.state.TopVesselsStartDate}
										className="dpStyle"
										onChange={(date) => {
											this.setState({ TopVesselsStartDate: date });
											this.topvessels(true, date, this.state.TopVesselsEndDate);
										}}
									/>
								</div>
							</div>
							<div
								style={{
									width: "50%",
									display: "flex",
									justifyContent: "center",
									marginTop: 5,
								}}
							>
								<div style={{ width: "50%" }}>
									<DatePicker
										selected={this.state.TopVesselsEndDate}
										className="dpStyle"
										onChange={(date) => {
											this.setState({ TopVesselsEndDate: date });
											this.topvessels(
												true,
												this.state.TopVesselsStartDate,
												date
											);
										}}
									/>
								</div>
							</div>
						</div>
						<AgChartsReact
							options={{
								theme: {
									//https://www.ag-grid.com/react-charts/api-themes/
									overrides: {
										pie: {
											background: {
												fill: "#f5f5f5",
											},
											padding: {
												bottom: 30,
											},
										},
									},
								},
								autoSize: true,
								data: this.state.topVessels,
								title: {
									text:
										"Top " +
										this.state.TopVesselsTopAmount +
										" Most Productive Vessels",
									fontSize: 18,
								},
								legend: {
									enabled: true,
								},
								series: [
									{
										type: "pie",
										labelKey: "type",
										fillOpacity: 0.85,
										strokeWidth: 0,
										angleKey: "value",
										label: {
											enabled: true, //label a apontar para a aprte do donut com o nome
											fontSize: 12,
											fontWeight: "bold",
										},
										innerRadiusOffset: -75,
									},
								],
							}}
						/>
					</div>
					<div className="col-md-6" style={{ height: 300 }}>
						<div style={{ backgroundColor: "#f5f5f5", display: "flex" }}>
							<div
								style={{
									width: "50%",
									display: "flex",
									justifyContent: "center",
									marginTop: 5,
								}}
							>
								<div style={{ width: "50%" }}>
									<DatePicker
										selected={this.state.TopSpeciesStartDate}
										className="dpStyle"
										onChange={(date) => {
											this.setState({ TopSpeciesStartDate: date });
											this.topcatches(true, date, this.state.TopSpeciesEndDate);
										}}
									/>
								</div>
							</div>
							<div
								style={{
									width: "50%",
									display: "flex",
									justifyContent: "center",
									marginTop: 5,
								}}
							>
								<div style={{ width: "50%" }}>
									<DatePicker
										selected={this.state.TopSpeciesEndDate}
										className="dpStyle"
										onChange={(date) => {
											this.setState({ TopSpeciesEndDate: date });
											this.topcatches(
												true,
												this.state.TopSpeciesStartDate,
												date
											);
										}}
									/>
								</div>
							</div>
						</div>
						<AgChartsReact
							options={{
								theme: {
									//https://www.ag-grid.com/react-charts/api-themes/
									overrides: {
										pie: {
											background: {
												fill: "#f5f5f5",
											},
											padding: {
												bottom: 30,
											},
										},
									},
									palette: {
										fills: [
											"#5C2983",
											"#0076C5",
											"#21B372",
											"#FDDE02",
											"#F76700",
											"#D30018",
										],
										strokes: ["black"],
									},
								},
								autoSize: true,
								data: this.state.topSpecies,
								title: {
									text:
										"Top " +
										this.state.TopSpeciesTopAmount +
										" Most Caught Species",
									fontSize: 18,
								},
								legend: {
									enabled: true,
								},
								series: [
									{
										type: "pie",
										labelKey: "type",
										fillOpacity: 0.85,
										strokeWidth: 0,
										angleKey: "value",
										label: {
											enabled: true, //label a apontar para a aprte do donut com o nome
											fontSize: 12,
											fontWeight: "bold",
										},
										innerRadiusOffset: -75,
									},
								],
							}}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12" style={{ marginTop: 50 }}>
						<div style={{ backgroundColor: "#f5f5f5", display: "flex" }}>
							<div
								style={{
									width: "25%",
									display: "flex",
									justifyContent: "center",
									marginTop: 5,
								}}
							>
								<select
									type="text"
									className="form-control"
									id="vesselSelect"
									placeholder="Select Vessel"
									onChange={(e) => {
										this.vesselTemperatures(true, e.target.value);
									}}
									onKeyDown={(e) => this._handleKeyDown(e.key)}
								>
									<option value="0">Select Vessel</option>
									{this.state.AllVessels &&
										this.state.AllVessels.map((vessel) => (
											<option key={vessel.id} value={vessel.id}>
												{vessel.name}
											</option>
										))}
								</select>
							</div>
						</div>
						{this.state.TemperatureSeries?.length > 0 && (
							<AgChartsReact
								ref={this.chartRef}
								options={{
									autoSize: true,
									theme: {
										palette: {
											fills: [
												"purple",
												"orange",
												"red",
												"black",
												"fuchsia",
												"blue",
												"green",
											],
											strokes: [
												"black",
												"black",
												"black",
												"black",
												"black",
												"black",
												"black",
											],
										},
										overrides: {
											line: {
												series: {
													highlightStyle: {
														series: {
															strokeWidth: 3,
															dimOpacity: 0.2,
														},
													},
												},
											},
										},
									},
									// data: this.state.temperatures,
									title: {
										text: "Temperatures",
										fontSize: 18,
									},
									legend: {
										enabled: true,
									},
									series:
										this.state.TemperatureSeries?.length > 0
											? this.state.TemperatureSeries
											: [],
									axes: [
										{
											position: "bottom",
											type: "category",
											label: {
												rotation: -30,
											},
										},
										{
											position: "left",
											type: "number",
											title: {
												enabled: true,
												text: "Hatch Temperature",
											},
											label: {
												formatter: (params) => {
													return params.value + " ºC";
												},
											},
										},
									],
								}}
							/>
						)}
					</div>
				</div>
				<div className="row" style={{ display: "none" }}>
					<div className="col-md-12" style={{ marginTop: 50 }}>
						<AgChartsReact
							options={{
								theme: {
									overrides: {
										histogram: {
											background: {
												fill: "#f5f5f5",
											},
										},
									},
								},
								autoSize: true,
								data: [
									{
										"engine-size": 130,
									},
									{
										"engine-size": 130,
									},
									{
										"engine-size": 152,
									},
								],
								title: {
									text: "Engine size distribution (USA 1987)",
									fontSize: 18,
								},
								subtitle: {
									text: "Source: UCI",
								},
								series: [
									{
										type: "histogram",
										xKey: "engine-size",
										xName: "Engine Size",
										fillOpacity: 0.5,
									},
								],
								axes: [
									{
										position: "bottom",
										type: "number",
										title: {
											text: "Engine Size (Cubic inches)",
										},
									},
									{
										position: "left",
										type: "number",
										title: {
											text: "Frequency",
										},
									},
								],
								legend: {
									enabled: false,
								},
							}}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(Home);

import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

axios.defaults.withCredentials = true

export const getTemplates = async (old) => {

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/ListTemplates`,{
        params: {
            WithOldVersion: old,
            token: localtoken
        }})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
};

export const DeleteTemplate = async (id) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.post(`${process.env.REACT_APP_API_URL}/DeleteTemplate`,
        {
            TemplateID: id,
            token: localtoken
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }
}

export const getTemplatesBySiteID = async (siteID) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/TemplatesBySiteID`, {
            params: {
                SiteID: siteID,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            ErrorNotification("Erro ao carregar templates");
            return [];
        });
    } catch (error) {
        ErrorNotification("Erro ao carregar templates");
        return [];
    }

}

export const getSiteCategoriesTemplates = async (siteID, categoryID) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/SiteCategoriesTemplates`, {
            params: {
                SiteID: siteID,
                CategoryID: categoryID,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            ErrorNotification("Error loading templates");
            return [];
        });
    } catch (error) {
        ErrorNotification("Erro loading templates");
        return [];
    }

}

export const getAnswersCSV = async (AnswersIDList, TemplateID) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/TemplateListAnswersToCSV`,
        {
            params: {
                AnswersIDList: AnswersIDList,
                TemplateID: TemplateID,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }

}

export const getAnswersZIP = async (AnswersIDList, TemplateID) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/TemplateListAnswersToZIP`,
        {
            params: {
                AnswersIDList: AnswersIDList,
                TemplateID: TemplateID,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }

}

export const getTemplateAnswers = async (FormID, SiteID) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/TemplateAnswers`, {
            params: {
                TemplateID: FormID,
                SiteID: SiteID,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }

}


export const setTemplateCategoriesAssociation = async (templateID, categories) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.post(`${process.env.REACT_APP_API_URL}/SetTemplateAssociationCategories`,{
            TemplateID: templateID,
            Categories: categories,
            token: localtoken
        }).then(response => {
            return response.data;
        }).catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }
}

export const setTemplateSitesAssociation = async (TemplateID, Sites) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.post(`${process.env.REACT_APP_API_URL}/SetTemplateAssociatedSites`,
        {
            TemplateID: TemplateID,
            Sites: Sites,
            token: localtoken
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }
}

export const getTemplateAssociatedSites = async (id) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/GetTemplateAssociatedSites`,
        {
            params: {
                TemplateID: id,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    } catch (error) {
        return null;
    }
}


export const SubmitTemplate = async (Template) => {

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.post(`${process.env.REACT_APP_API_URL}/CreateTemplate`, {
            Template: Template,
            token: localtoken
        })
        .then(response =>{
            SuccessNotification("Template successfully created");
            return response.data;
        })
        .catch(error => {
        
            ErrorNotification("Error", error.response.data);
            return null;
        });
    
    } catch (error) {
        ErrorNotification("Error", error.response.data);
        return null;
    
    }

}

export const EditTemplate = async (Template) => {

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.post(`${process.env.REACT_APP_API_URL}/EditTemplate`, {
            Template: Template,
            token: localtoken
        })
        .then(response =>{
            SuccessNotification("Template successfully edited");
            return response.data;
        })
        .catch(error => {
        
            ErrorNotification("Error", error.response.data);
            return null;
        });
    
    } catch (error) {
        ErrorNotification("Error", error.response.data);
        return null;
    
    }
}

export const CheckIfInternalCodeIsInUse = async (InternalCode) => {
    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/CheckIfInternalCodeIsInUse`,
        {
            params: {
                InternalCode: InternalCode,
                token: localtoken
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return null;
        });
    }
    catch(error) {
        ErrorNotification("Error", error.response.data);
        return null;
    }
}


export const getTemplate = async (TemplateID, SiteID) => {

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/GetTemplate`, {
            params: {
                TemplateID: TemplateID,
                SiteID: SiteID,
                token: localtoken
            }
        })
        .then(response =>{
            return response.data;
        })
        .catch(error => {
            ErrorNotification("Error", error.response.data);
            return null;
        });
    
    } catch (error) {
        ErrorNotification("Error", error.response.data);
        return null;
    
    }

}

//import react
import React from "react";
import logo from "../../logo.svg";
import "../../App.css";
import "./index.css";
import { getAccessToken, setAccessToken } from "../../utils/accessToken";
import { getSites, addSite, deleteSite, updateSite } from "../../api/sites";
import {
	getData,
	getExcel,
	getExcelByVesselIDandTripID,
	getZipDownloadLinkByVesselAndTripNumber,
	getSummaryInfoXls
} from "../../api/exportdata";
import { getUsers } from "../../api/users";
import { getToken } from "../../api/getToken";
import FormTemplate from "./FormTemplate";
import SiteForm from "./SiteForm";
import DeleteForm from "./DeleteForm";
import {
	setFormType,
	getFormType,
	getSite,
	setSite,
} from "../../utils/sitesForm";
import moment from "moment";
import { getUser } from "../../utils/loggedUser";
import SiteUsers from "./SiteUsers";
import { CSVLink, CSVDownload } from "react-csv";
import { AgGridReact } from "ag-grid-react";
import { RiPagesFill } from "react-icons/ri";
import { getVessels, getVesselTrips } from "../../api/vessels";

import {
	useLocation,
	useNavigate,
	useParams,
	useOutletContext,
} from "react-router-dom";
import { textAlign } from "@mui/system";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		let outletContext = useOutletContext();
		return (
			<Component
				{...props}
				router={{ location, navigate, params, outletContext }}
			/>
		);
	}
	return ComponentWithRouterProp;
}

class TripsInfoPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			sites: [],
			ShowForm: false,
			ShowDeleteForm: false,
			ShowOpenSiteForm: false,
			ShowSiteUsers: false,
			SelectedSite: null,
			ForcedRefresh: props.router.outletContext.refreshstate,
			loadedjs: 0,
			exceldata: [],
			gridcolumns: [],
			gridrows: [],
			pagination: true,
			selectedVesselID: "-1",
			selectedTripID: "-1",
			zipdownloadlink: "",
			excelLink: "",
		};
	}

	componentDidUpdate = () => {
		// console.log("Refresh ", this.state.ForcedRefresh, " ->", this.props.router.outletContext.refreshstate);
		if (
			this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate
		) {
			this.setState({
				ForcedRefresh: this.props.router.outletContext.refreshstate,
				ShowForm: false,
				ShowDeleteForm: false,
				ShowOpenSiteForm: false,
				loading: true,
				user: null,
			});
		}

		if (this.state.loading) {
			this.LoadAll();
			return;
		}

		if (this.state.loadedjs === 0) {
			this.loadjs();
		}
	};

	componentDidMount = async () => {
		if (this.state.loading) {
			this.LoadAll();
		}

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	};

	componentWillUnmount = async () => {
		this.unloadjs();
	};

	unloadjs = () => {
		try {
			var script = document.createElement("script");
			script.id = "remove-scripts-sites";
			script.innerHTML = "jQuery('#table-tripsifo').DataTable().destroy();";
			document.body.appendChild(script);

			document.getElementById("custom-table-render-sites").remove();
			document.getElementById("remove-scripts-sites").remove();

			window.jQuery(".modal-backdrop")[0].remove();
		} catch (error) { }
	};

	loadjs = async () => {
		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.src = "/assets/js/table-custom.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1,
		});
	};

	LoadAll = async () => {
		try {
			//get user
			var user = getUser();
			var vessels = await getVessels();
			// var data = [];
			// await getData()
			// .then((res) => {
			// 	data = res;
			// })
			// .catch((err) => {
			// 	console.log(err);
			// });

			var users = await getUsers();
			console.log("vessels", vessels);
			this.setState({
				loading: false,
				// tripinfo: data,
				ShowDeleteForm: false,
				user: user,
				users: users,
				vessels: vessels,
			});

			// getExcel().then((res) => {
			// 	this.loadTableWithData(res);
			// });
		} catch (error) {
			console.log("Error.", error);
		}
	};

	loadTableWithData = async (res) => {
		var x = [];
		var splitted = (res + "").trim().split("\n");

		var columns = splitted[0].split(";");
		var cols = [];
		var groupcols = [];
		var lastWord = "";
		for (var i = 0; i < columns.length; i++) {
			const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
			var word = columns[i].match(wordRegex);
			var closed = false;
			if (word[0] !== lastWord) {
				if (groupcols.length > 1) {
					cols.push({
						headerName: lastWord,
						children: groupcols,
					});
				} else if (groupcols.length === 1) {
					cols.push(groupcols[0]);
				}

				groupcols = [];
				lastWord = word[0];
				closed = true;
			}

			var obj = {
				field: columns[i],
				sortable: true,
				filter: true,
				resizable: true,
				enableRowGroup: true,
				editable: false,
				columnGroupShow: closed ? "" : "open",
			};

			if (i === 0) {
				obj.rowDrag = true;
			}

			groupcols.push(obj);
		}

		var rows = [];
		var splitind = 0;
		splitted.forEach((element) => {
			if ((element + "").includes(";")) {
				// if(i!==0){
				// 	gridrows.push(element.split(";"));
				// }
				var splitelem = element.split(";");
				x.push([splitelem]);
				if (splitind !== 0) {
					var objaux = {};
					for (var i = 0; i < splitelem.length; i++) {
						try {
							if (
								splitelem[i] + "" === "" ||
								splitelem[i] === null ||
								splitelem[i] === undefined
							) {
								objaux[columns[i]] = splitelem[i];
								continue;
							}

							objaux[columns[i]] = isNaN(Number(splitelem[i]))
								? splitelem[i]
								: Number(splitelem[i]);

							var isDate = new Date(splitelem[i]);
							var isnumber = Number(splitelem[i]);
							if (!isNaN(isnumber)) {
								isDate = null;
							}

							if (isDate instanceof Date && !isNaN(isDate)) {
								var dateyear = isDate.getYear() + 1900;
								if (dateyear < 2010) {
									// console.log("" + columns[i] + " is not date ->", isDate);
								} else {
									objaux[columns[i]] =
										moment(isDate).format("DD/MM/YYYY | HH:mm");
									// console.log("" + columns[i] + " is date ->", isDate);
								}
							} else {
								// console.log("" + columns[i] + " is not date ->", isDate);
							}
						} catch (error) {
							objaux[columns[i]] = splitelem[i];
						}
					}

					rows.push(objaux);
				}
			}
			splitind++;
		});

		console.log("grid row", rows);

		this.unloadjs();
		this.setState({
			exceldata: x,
			gridcolumns: cols,
			gridrows: rows,
			loading: true,
			loadedjs: 0,
		});
	};

	getExcelFromVesselTrip = async () => {
		getExcelByVesselIDandTripID(
			this.state.selectedVesselID,
			this.state.selectedTripID
		).then((res) => {
			this.loadTableWithData(res);
		});

		getZipDownloadLinkByVesselAndTripNumber(
			this.state.selectedVesselID,
			this.state.selectedTripID
		).then((res) => {
			console.log("getZipDownloadLinkByVesselAndTripNumber", res);
			this.setState({
				zipdownloadlink: res,
			});
		});

		getSummaryInfoXls(
			this.state.selectedVesselID,
			this.state.selectedTripID
		).then((res) => {
			this.setState({
				excelLink: res,
			});
		});
	};

	CloseForm = async () => {
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseFormNoChanges = async () => {
		this.setState({
			ShowForm: false,
		});
	};

	CloseSiteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseDeleteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};
	CloseDeleteFormNoChanges = async () => {
		console.log("close->delete");
		this.setState({
			ShowDeleteForm: false,
		});
	};

	DeleteSite = async (id) => {
		//Find site
		var site = this.findSiteByID(id);

		//Set site on variable to delete
		setSite(site);

		//Show delete form
		this.setState({
			ShowDeleteForm: true,
		});
	};

	EditSite = async (id) => {
		//Find site
		var site = this.findSiteByID(id);

		//Set site on variable to delete
		setSite(site);
		setFormType("Edit");

		//Show delete form
		this.setState({
			ShowForm: true,
		});
	};

	OpenUsers = async (id) => {
		//Find site
		var site = this.findSiteByID(id);

		//Set site on variable to delete
		setSite(site);

		this.setState({
			ShowSiteUsers: true,
		});
	};

	CloseSiteUsers = async () => {
		this.unloadjs();
		this.setState({
			ShowSiteUsers: false,
		});
	};

	findSiteByID = (id) => {
		return this.state.tripinfo.find((size) => {
			return size.id === id;
		});
	};

	SelectedSiteChanged = (id) => {
		this.setState({
			SelectedSite: this.findSiteByID(id),
		});
	};

	OpenSite = () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: true,
		});
	};

	onGridReady = (params) => {
		this.setState({
			gridApi: params.api,
			gridColumnApi: params.columnApi,
		});
	};

	render() {
		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading trips info</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "center", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		if (this.state.ShowOpenSiteForm) {
			return (
				<React.Fragment>
					<SiteForm
						CloseForm={this.CloseSiteForm}
						Site={this.state.SelectedSite}
					/>
				</React.Fragment>
			);
		}

		if (this.state.denied) {
			return (
				<div>
					<p>You do not have access to this page.</p>
				</div>
			);
		}

		if (this.state.downloadcsv) {
			this.unloadjs();

			this.setState({
				downloadcsv: false,
				loadedjs: 0,
			});

			// console.log("this.state.exceldata", this.state.exceldata)

			return (
				<CSVDownload
					data={this.state.exceldata}
					enclosingCharacter={``}
					target="_blank"
				/>
			);
		}

		// console.log("sites render!")

		return (
			<>
				<h2>Trip info</h2>
				<div cstyle={{ marginBottom: 10 }}>

					<div className="row">

						<div className="col-md-2">
							<select
								className="form-control"
								style={{ maxWidth: 200, marginLeft: 15 }}
								value={this.state.selectedVesselID}
								onChange={(e) => {
									var vesselid = e.target.value;

									// limpar tabela
									this.state.gridApi.setRowData([]);
									this.state.gridApi.setColumnDefs([]);

									if (vesselid === "-1") {
										this.setState({
											selectedVesselID: vesselid,
											selectedTripID: "-1",
											trips: [],
										});
										return;
									}

									getVesselTrips(vesselid).then((res) => {
										this.setState({
											selectedVesselID: vesselid,
											selectedTripID: "-1",
											trips: res,
										});
									});
								}}
							>
								<option value="-1">Select a vessel</option>
								{this.state.vessels &&
									this.state.vessels.map((vessel) => {
										return (
											<option key={vessel.id} value={vessel.id}>
												{vessel.name}
											</option>
										);
									})}
							</select>

						
						</div>
						<div className="col-md-2">
						<select
								className="form-control"
								disabled={this.state.selectedVesselID === "-1"}
								value={this.state.selectedTripID ?? "-1"}
								style={{ maxWidth: 200, marginLeft: 15 }}
								onChange={(e) => {
									var tripid = e.target.value;

									this.setState(
										{
											selectedTripID: tripid,
										},
										() => {
											if (this.state.selectedTripID !== "-1") {
												this.getExcelFromVesselTrip();
											} else {
												this.state.gridApi.setRowData([]);
												this.state.gridApi.setColumnDefs([]);
											}
										}
									);
								}}
							>
								<option value="-1">Select a Trip</option>
								{this.state.trips &&
									this.state.trips.map((trip) => {
										return (
											<option key={trip.tripNumber} value={trip.tripNumber}>
												{trip.tripNumber}
											</option>
										);
									})}
							</select>
							</div>
						<div className="col-md-8" style={{textAlign: "right"}}>

							<button
								key={"Refresh"}
								type="button"
								style={{
									backgroundColor: "#59bfff",
									color: "white",
								}}
								className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
								onClick={() => {
									// this.unloadjs();
									// this.setState({ loading: true, loadedjs: 0 });
									if (this.state.selectedTripID !== "-1") {
										this.getExcelFromVesselTrip();
									} else {
										this.state.gridApi.setRowData([]);
										this.state.gridApi.setColumnDefs([]);
									}
								}}
							>
								<i className="fa fa-refresh"></i> Refresh
							</button>
							<button
								key={"Full Export"}
								type="button"
								style={{
									marginLeft: 15,
									backgroundColor: "darkgreen",
									color: "white",
								}}
								disabled={this.state.zipdownloadlink === ""}
								className="btn"
								onClick={() => {
									//open new tab
									window.open(this.state.zipdownloadlink, "_blank");
								}}
							>
								{/* icon for zip file */}
								<i className="fa fa-download"></i> Export Trip Info (csv files)
							</button>
							<button
								key={"Full Export Excel"}
								type="button"
								style={{
									marginLeft: 15,
									backgroundColor: "darkgreen",
									color: "white",
								}}
								disabled={this.state.excelLink === ""}
								className="btn"
								onClick={() => {
									//open new tab
									window.open(this.state.excelLink, "_blank");
								}}
							>
								{/* icon for zip file */}
								<i className="fa fa-download"></i> Export Trip Info (single excel file)
							</button>
						 

						</div>
					</div>
				</div>

				<div
					className="ag-theme-alpine"
					style={{ width: "100%", height: "90%", marginTop:10}}
				>
					<AgGridReact
						defaultColDef={{
							sortable: true,
							filter: true,
						}}
						rowDragManaged={true}
						rowDragMultiRow={true}
						suppressExcelExport={true}
						sideBar={{
							toolPanels: ["columns", "filters"],
						}}
						onGridReady={this.onGridReady}
						pivotMode={false}
						rowGroupPanelShow={"always"}
						rowSelection={"multiple"}
						pagination={this.state.pagination}
						paginationAutoPageSize={this.state.pagination}
						animateRows={true}
						rowData={this.state.gridrows}
						columnDefs={this.state.gridcolumns}
					></AgGridReact>
				</div>
				{this.state.ShowForm ? (
					<FormTemplate
						CloseForm={this.CloseForm}
						CloseFormNoChanges={this.CloseFormNoChanges}
					/>
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm
						CloseForm={this.CloseDeleteForm}
						CloseDeleteFormNoChanges={this.CloseDeleteFormNoChanges}
					/>
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</>
		);

		return (
			<>
				<h2>Trip info</h2>
				<div className="d-flex" style={{ marginBottom: 10, display: "flex" }}>
					<button
						key={"Refresh"}
						type="button"
						className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
						onClick={() => {
							this.unloadjs();
							this.setState({ loading: true, loadedjs: 0 });
						}}
					>
						<i className="fa fa-refresh"></i> Refresh
					</button>
					<button
						key={"NewPort"}
						type="button"
						style={{ marginLeft: 15 }}
						className="btn"
						onClick={() => {
							this.setState({
								downloadcsv: true,
							});
						}}
					>
						<i className="fa fa-plus-square"></i> Download CSV
					</button>
				</div>
				<table className="table table-bordered datatable" id="table-tripsifo">
					<thead>
						<tr>
							{this.state.tripinfo &&
								Object.keys(this.state.tripinfo[0]).map((trip, index) => {
									const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
									var word = trip.match(wordRegex).join(" ");
									return (
										<th key={"th_" + index} style={{ width: 500 }}>
											{word.charAt(0).toUpperCase() + word.slice(1)}
										</th>
										// <th key={"thh_"+index}>{(trip)}</th>
									);
								})}
						</tr>
					</thead>
					<tbody>
						{this.state.tripinfo &&
							this.state.tripinfo.map((trip, index) => {
								return (
									<tr
										role={"row"}
										className={index % 2 === 0 ? "odd" : "even"}
										key={"formrow_" + index}
									>
										{Object.keys(trip).map((key, index) => {
											if (
												(key + "").toLocaleLowerCase().includes("date") &&
												trip[key] !== "" &&
												trip[key] !== null &&
												trip[key] !== undefined
											) {
												// console.log("Date!", moment(new Date(value)).format("DD/MM/YYYY"))
												return (
													<td key={"td_" + index}>
														{moment(new Date(trip[key])).format(
															"DD/MM/YYYY HH:mm"
														)}
													</td>
												);
											}

											return <td key={"td_" + index}>{trip[key]}</td>;
										})}
									</tr>
								);
							})}
					</tbody>
					<tfoot>
						<tr>
							{this.state.tripinfo &&
								Object.keys(this.state.tripinfo[0]).map((trip, index) => {
									const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
									return (
										// <th key={"th_"+index} style={{width:500}}>{(trip.match(wordRegex).join(" "))}</th>
										<th key={"thf_" + index} style={{ width: 500 }}>
											{trip}
										</th>
									);
								})}
						</tr>
					</tfoot>
				</table>
				{this.state.ShowForm ? (
					<FormTemplate
						CloseForm={this.CloseForm}
						CloseFormNoChanges={this.CloseFormNoChanges}
					/>
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm
						CloseForm={this.CloseDeleteForm}
						CloseDeleteFormNoChanges={this.CloseDeleteFormNoChanges}
					/>
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</>
		);

		return (
			<React.Fragment>
				<h2 className="mb-4">Sites</h2>
				<div className="d-flex">
					{this.state.user?.isAdmin ? (
						<>
							<button
								key={"NewSite"}
								type="button"
								className="btn btn-esip-blue py-2 px-4 mb-4 me-4 custom-letter-spacing"
								onClick={() => {
									setFormType("Create");
									this.setState({
										ShowForm: true,
									});
								}}
							>
								<i className="entypo-pencil"></i>Create Site
							</button>
							<button
								key={"Refresh"}
								type="button"
								style={{ marginLeft: 10 }}
								className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
								onClick={() => {
									this.setState({ loading: true });
								}}
							>
								<i className="entypo-pencil"></i>Refresh
							</button>
						</>
					) : null}
				</div>
				<div
					className="p-0"
					style={{
						borderBottom: 0,
						borderLeft: 0,
						borderRight: 0,
						height: "85%",
					}}
				>
					<div
						className="d-flex flex-column justify-content-between align-items-center"
						style={{ height: "100%" }}
					>
						<div
							className="w-100 dataTables_wrapper no-footer"
							style={{ overflowY: "auto", marginTop: 25 }}
						>
							{/* <table className='table table-bordered table-striped datatable dataTable no-footer' style={{marginBottom:0}} key={"table"}> */}
							<table
								className="table table-bordered datatable dataTable"
								id="table-1"
								role="grid"
								aria-describedby="table-1_info"
							>
								<thead>
									<tr role={"row"}>
										<th
											data-hide="phone"
											className="sorting_desc"
											tabIndex="0"
											aria-controls="table-1"
											rowSpan="1"
											colSpan="1"
											aria-label="Rendering engine: activate to sort column ascending"
											aria-sort="descending"
										>
											Rendering engine
										</th>
										{/* <th className="sorting" tabIndex="0" aria-controls="table-2" rowSpan="1" colSpan="1" aria-label="Student Name: activate to sort column ascending" >{"Site Name"}</th> */}
										<th
											className="sorting"
											style={{ width: 200 }}
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Average Grade: activate to sort column ascending"
										>
											{"Created At"}
										</th>
										<th
											className="sorting"
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Curriculum / Occupation: activate to sort column ascending"
										>
											{"Creator"}
										</th>
										<th
											className="sorting"
											style={{ width: "25%" }}
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Actions: activate to sort column ascending"
										>
											{"Actions"}
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.sites.map((site, index) => {
										return (
											<tr
												role={"row"}
												className={index % 2 === 0 ? "odd" : "even"}
												key={"formrow_" + index}
												onClick={() => this.SelectedSiteChanged(site.id)}
												onDoubleClick={() => this.OpenSite()}
											>
												{/* <div key={"SiteRow_"+site.id} className={`d-flex align-items-center p-3 custom-border-bottom `} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite()}> */}
												<td>{site.name}</td>
												<td>
													{"" + moment(site.createdAt).format("YYYY/MM/DD")}
												</td>
												<td>
													{site.creator !== null
														? "" + site.creator?.username
														: ""}
												</td>
												<td className="sorting_1">
													<a
														href="#"
														className="btn btn-default btn-sm btn-icon icon-left"
														onClick={() => this.OpenUsers()}
													>
														<i className="entypo-pencil"></i>
														Open Users
													</a>
													<a
														href="#"
														className="btn btn-info btn-sm btn-icon icon-left"
														onClick={() => this.EditSite(site.id)}
													>
														<i className="entypo-info"></i>
														Edit Site
													</a>
													<a
														href="#"
														className="btn btn-danger btn-sm btn-icon icon-left"
														onClick={() => this.DeleteSite(site.id)}
													>
														<i className="entypo-cancel"></i>
														Delete Site
													</a>
												</td>
												{/* <td > */}
												{/* <button key={"openusers_"+site.id} type="button" title='Open Users' className="btn btn-esip-yellow delete-btn me-2" onClick={() => this.OpenUsers()} style={{ margin: "auto" }}>
														<i className="fas fa-user"></i>
													</button>
													<button key={"editsite_"+site.id} type="button" title='Edit Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.EditSite(site.id)}>
														<i className="fas fa-edit"></i>
													</button> */}
												{/* <a key={"delsite_"+site.id} type="button" title='Delete Site' className="btn btn-danger btn-sm btn-icon icon-left" onClick={() => this.DeleteSite(site.id)}>
														<i className="entypo-cancel"></i>
													</a> */}
												{/* <button key={"opensite_"+site.id} type="button" title='Open Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.OpenSite()}>
														<i className="fas fa-arrow-right"></i>
													</button> */}
												{/* </td> */}
												{/* </div> */}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{this.state.ShowForm ? (
					<FormTemplate CloseForm={this.CloseForm} />
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm CloseForm={this.CloseDeleteForm} />
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

export default withRouter(TripsInfoPage);

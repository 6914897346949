import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getExcel = async () => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.get(`${process.env.REACT_APP_API_URL}/ExportData/getDataToCSV`, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};



export const getSummaryInfoXls = async (vesselid, tripid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.get(`${process.env.REACT_APP_API_URL}/ExportData/summaryInfoXls?vesselID=${vesselid}&tripNumber=${tripid}`, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};


export const getExcelByVesselIDandTripID = async (vesselid, tripid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.get(
					`${process.env.REACT_APP_API_URL}/ExportData/getDataToCSVVesselTrip?vesselID=${vesselid}&tripNumber=${tripid}`,
					{
						headers: {
							Authorization: `Bearer ${localtoken}`,
						},
					}
				)
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};




export const getExcelSummaryReportTripsInfo = async (startdate, enddate) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		let dts = startdate.getFullYear() +
		"/" +
		(startdate.getMonth() + 1) +
		"/" +
		startdate.getDate();
		let dte = enddate.getFullYear() +
		"/" +
		(enddate.getMonth() + 1) +
		"/" +
		enddate.getDate()
		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.get(
					`${process.env.REACT_APP_API_URL}/ExportData/summaryReportTripsInfo?startDate=${dts}&endDate=${dte}`,
					{
						headers: {
							Authorization: `Bearer ${localtoken}`,
						},
					}
				)
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};




export const getExcelSummaryReportTripsInfoDataSet = async (startdate, enddate) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		let dts = startdate.getFullYear() +
		"/" +
		(startdate.getMonth() + 1) +
		"/" +
		startdate.getDate();
		let dte = enddate.getFullYear() +
		"/" +
		(enddate.getMonth() + 1) +
		"/" +
		enddate.getDate()
		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.get(
					`${process.env.REACT_APP_API_URL}/ExportData/summaryReportTripsInfoDataSet?startDate=${dts}&endDate=${dte}`,
					{
						headers: {
							Authorization: `Bearer ${localtoken}`,
						},
					}
				)
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};



export const getZipDownloadLinkByVesselAndTripNumber = async (
	vesselID,
	tripNumber
) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		try {
			const localtoken = await localStorage.getItem("token");

			axios
				.get(
					`${process.env.REACT_APP_API_URL}/ExportData/getDataToCSVVesselTripZip?vesselID=${vesselID}&tripNumber=${tripNumber}`,
					{
						headers: {
							Authorization: `Bearer ${localtoken}`,
						},
					}
				)
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};

export const getData = async () => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.get(`${process.env.REACT_APP_API_URL}/ExportData/getData`, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					// ErrorNotification(error.response.data.message);
					return reject(error);
				});
		} catch (error) {
			return reject(error);
		}
	});
};






export const getSummaryInfoXlsFilters = async (vessels, downloadType, dateStart, dateEnd) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false
        try {
            const localtoken = await localStorage.getItem('token');
            
			console.log("portobj: ", vessels, downloadType, dateStart, dateEnd)
			 
            var TripInfoExportExcelObject = {
                "vessels": vessels.join(","),
                "downloadType": downloadType,
                "dateStart": dateStart,
                "dateEnd": dateEnd 
            }

             console.log("portobj: ", TripInfoExportExcelObject)

            return axios.post(`${process.env.REACT_APP_API_URL}/ExportData/summaryInfoXlsFilters`,TripInfoExportExcelObject, {
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};



//import react
import React from "react";
import {
	getFormType,
	getSite,
} from "../../utils/sitesForm";
import { saveVessel } from "../../api/vessels";
import {
	getVesselGeneratorsByID,
	createGenerator,
	deleteGenerator,
} from "../../api/generators";
import CreatableSelect from "react-select/creatable";
import {
	ErrorNotification,
} from "../../utils/notifications";
import { getUser } from "../../utils/loggedUser";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const { REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE } = process.env;


function isNumber(value) {
	'use strict';
	value = value.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
	try {
		value = value.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
		return !isNaN(parseFloat(value)) && isFinite(value);
	}
	catch {
		return false;
	}
}

class FormTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			Vessel: {},
			SiteID: -1,
			FormType: "", // "Create" or "Edit"
			selectedGenerators: [],
			vesselGenerators: [],
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		window.jQuery("#modal-6").modal("show", { backdrop: "static" });
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		// this.SiteNameInputText.focus();
		var FormType = getFormType();

		if (FormType === "Edit") {
			var vessel = getSite();

			var vesselGenerators = await getVesselGeneratorsByID(vessel.id);

			//console.log("vesselGenerators", vesselGenerators);

			var dt = null;

			if (vessel.notificationHour !== null) {
				dt = new Date(
					new Date().setHours(
						vessel.notificationHour?.substring(0, 2),
						vessel.notificationHour?.substring(3, 5)
					)
				);
			}

			this.setState({
				Vessel: {
					Name: vessel.name,
					ID: vessel.id,
					LicenseNumber: vessel.licenseNumber,
					ImoNumber: vessel.imoNumber,
					aisCode: vessel.aisCode,
					captainName: vessel.captainName,
					vesselLength: vessel.vesselLength,
					avgTripDays: vessel.avgTripDays,
					avgFishingDays: vessel.avgFishingDays,
					avgCoastDistance: vessel.avgCoastDistance,
					crewNumber: vessel.crewNumber,
					fuelCapacity: vessel.fuelCapacity,
					waterCapacity: vessel.waterCapacity,
					generator1Capacity: vessel.generator1Capacity,
					generator2Capacity: vessel.generator2Capacity,
					notificationHour: dt,
				},
				vesselGenerators: vesselGenerators,
				selectedGenerators: vesselGenerators,
				loading: false,
				FormType: FormType,
			});
		} else {
			this.setState({
				loading: false,
				FormType: FormType,
			});
		}
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
			FormType: "", // "Create" or "Edit"
		});

		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
			document.body.appendChild(scriptx);

			document.findElementById("closemodal").remove();
		} catch (error) { }

		this.props.CloseForm();
	};

	Submit = async () => {
		await this.Save();
	};

	Save = async () => {
		if (this.state.Vessel.Name === "" || this.state.Vessel.Name === undefined) {
			ErrorNotification("error", "Vessel name is required");
			return;
		}

		if (this.state.Vessel.LicenseNumber === "") {
			ErrorNotification("error", "Vessel License Number is required");
			return;
		}

		if (this.state.Vessel.vesselLength != undefined && this.state.Vessel.vesselLength != null && this.state.Vessel.vesselLength != "") {
			var vesselLength = isNumber(this.state.Vessel.vesselLength);
			if (!vesselLength) {
				ErrorNotification("error", "Vessel length is incorrect");
				return;
			}
			this.state.Vessel.vesselLength = this.state.Vessel.vesselLength.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
	

			this.state.Vessel.vesselLength = parseFloat(this.state.Vessel.vesselLength );
			if (isNaN(this.state.Vessel.vesselLength) || this.state.Vessel.vesselLength <= 0) {
				ErrorNotification("error", "Vessel length days must be equal or greater than zero");
				return;
			}
		}
		else
			this.state.Vessel.vesselLength = null;
	
				console.log(this.state.Vessel.avgTripDays)

		if (this.state.Vessel.avgTripDays != undefined && this.state.Vessel.avgTripDays != null && this.state.Vessel.avgTripDays != "") {
			var avgtrip = isNumber(this.state.Vessel.avgTripDays);
			if (!avgtrip) {
				ErrorNotification("error", "Average trips days is incorrect");
				return;
			}
			this.state.Vessel.avgTripDays = this.state.Vessel.avgTripDays.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);
			this.state.Vessel.avgTripDays = parseFloat(this.state.Vessel.avgTripDays );
			console.log(this.state.Vessel.avgTripDays)

			if (isNaN(this.state.Vessel.avgTripDays) || this.state.Vessel.avgTripDays <= 0) {
				ErrorNotification("error", "Average trips days must be equal or greater than zero");
				return;
			}

		}
		else
			this.state.Vessel.avgTripDays = null;


		if (this.state.Vessel.avgFishingDays != undefined && this.state.Vessel.avgFishingDays != null && this.state.Vessel.avgFishingDays != "") {
			var avgFish = isNumber(this.state.Vessel.avgFishingDays);
			if (!avgFish) {
				ErrorNotification("error", "Average fishing days is incorrect");
				return;
			}
			
			this.state.Vessel.avgFishingDays = this.state.Vessel.avgFishingDays.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);

			if (isNaN(this.state.Vessel.avgFishingDays) || this.state.Vessel.avgFishingDays <= 0) {
				ErrorNotification("error", "Average fishing days must be equal or greater than zero");
				return;
			}
		}
		else
			this.state.Vessel.avgFishingDays = null;


		if (this.state.Vessel.avgCoastDistance != undefined && this.state.Vessel.avgCoastDistance != null && this.state.Vessel.avgCoastDistance != "") {
			var avgCoast = isNumber(this.state.Vessel.avgCoastDistance);
			if (!avgCoast) {
				ErrorNotification("error", "Average coast distance is incorrect");
				return;
			}
			this.state.Vessel.avgCoastDistance = this.state.Vessel.avgCoastDistance.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);

			if (isNaN(this.state.Vessel.avgCoastDistance) || this.state.Vessel.avgCoastDistance <= 0) {
				ErrorNotification("error", "Average coast distance must be equal or greater than zero");
				return;
			}
		}
		else
			this.state.Vessel.avgCoastDistance = null;

		if (this.state.Vessel.fuelCapacity != undefined && this.state.Vessel.fuelCapacity != null && this.state.Vessel.fuelCapacity != "") {
			var fuleCapacity = isNumber(this.state.Vessel.fuelCapacity);
			if (!fuleCapacity) {
				ErrorNotification("error", "Fuel Capacity is incorrect");
				return;
			}
			this.state.Vessel.fuelCapacity = this.state.Vessel.fuelCapacity.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);

			if (isNaN(this.state.Vessel.fuelCapacity) || this.state.Vessel.fuelCapacity <= 0) {
				ErrorNotification("error", "Fuel capacity must be equal or greater than zero");
				return;
			}
	
		}
		else
			this.state.Vessel.fuelCapacity = null;


		if (this.state.Vessel.waterCapacity != undefined && this.state.Vessel.waterCapacity != null && this.state.Vessel.waterCapacity != "") 
		{
			var waterCapacity = isNumber(this.state.Vessel.waterCapacity);
			if (!waterCapacity) {
				ErrorNotification("error", "Water Capacity is incorrect");
				return;
			}
			this.state.Vessel.waterCapacity = this.state.Vessel.waterCapacity.toString().replace(REACT_APP_DECIMALVALUE_TO_REPLACE, REACT_APP_DECIMALVALUE_TO_USE);

			if (isNaN(this.state.Vessel.waterCapacity) || this.state.Vessel.waterCapacity <= 0) {
					ErrorNotification("error", "Water capacity must be equal or greater than zero");
					return;
			}
		}
		else
			this.state.Vessel.waterCapacity = null;

		var crewNumber = parseInt(this.state.Vessel.crewNumber);

		if (isNaN(crewNumber) || crewNumber < 1) {
				ErrorNotification("error", "Vessel crew number > 1 is required");
				return;
		}

			await saveVessel(this.state.Vessel, getUser().userID)
				.then(async (response) => {
					//check if all items in this.state.vesselGenerators are in selectedGenerators
					var allGenerators = this.state.vesselGenerators;
					var selectedGenerators = this.state.selectedGenerators;
					var generatorsToDelete = [];
					var vesselid = -1;

					try {
						vesselid = response?.id;
					} catch (error) {
						ErrorNotification("error", error);

						console.log("error", error);
					}

					//foreach generator that currently exists in database
					for (var i = 0; i < allGenerators.length; i++) {
						var found = false;
						//check if it is still in the list of generators that the user selected
						for (var j = 0; j < selectedGenerators.length; j++) {
							if (allGenerators[i].id === selectedGenerators[j].id) {
								found = true;
								break;
							}
						}
						//if not found, then it is a generator that needs to be deleted
						if (!found) {
							generatorsToDelete.push(allGenerators[i]);
						}
					}

					//delete generators
					for (var i = 0; i < generatorsToDelete.length; i++) {
						await deleteGenerator(generatorsToDelete[i], getUser().userID);
					}

					//all generators that need to be created have the id = null
					for (var i = 0; i < selectedGenerators.length; i++) {
						if (selectedGenerators[i].ID === null) {
							//change id to -1
							selectedGenerators[i].ID = -1;

							if (!selectedGenerators[i].VesselID) {
								selectedGenerators[i].VesselID = vesselid;
							}

							await createGenerator(selectedGenerators[i]);
						}
					}

					this.props.CloseForm();
				})
				.catch(async (error) => {
					console.log("ERROR!!!", error);
				});
		};



		SiteNameChanged = (value) => {
			if (value.length > 35) {
				ErrorNotification("error", "Site name cannot be more than 35 characters");
				return;
			}

			this.setState({
				SiteName: value,
			});
		};

		_handleKeyDown = (key) => {
			if (key === "Enter") {
				this.Submit();
			}
		};

		render() {
			return (
				<div
					className="modal fade"
					id="modal-6"
					data-keyboard="false"
					data-backdrop="static">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-hidden="true"
									onClick={this.props.CloseFormNoChanges}>
									&times;
								</button>
								<h4 className="modal-title">{this.state.FormType} Vessel</h4>
							</div>

							<div className="modal-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Vessel Name
											</label>
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
												style={{ color: "red", marginLeft: 5 }}>
												*
											</label>

											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Vessel Name"
												value={this.state.Vessel.Name}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															Name: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-12">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Captain Name:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Captain Name"
												value={this.state.Vessel.captainName}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															captainName: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-12">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												License Number
											</label>
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
												style={{ color: "red", marginLeft: 5 }}>
												*
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="License Number"
												value={this.state.Vessel.LicenseNumber}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															LicenseNumber: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
											>
												IMO Number:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="IMO Number"
												value={this.state.Vessel.ImoNumber}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															ImoNumber: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
											>
												AIS Code:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="AIS Code"
												value={this.state.Vessel.aisCode}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															aisCode: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>

								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Vessel Length (Meters):
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Vessel Length (Meters)"
												value={this.state.Vessel.vesselLength}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															vesselLength: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Average Trip Days:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Average Trip Days"
												value={this.state.Vessel.avgTripDays}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															avgTripDays: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>
								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Average Fishing Days:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Average Fishing Days"
												value={this.state.Vessel.avgFishingDays}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															avgFishingDays: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
											>
												Average Coast Distance (Miles):
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Average Coast Distance (Miles)"
												value={this.state.Vessel.avgCoastDistance}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															avgCoastDistance: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>

								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Fuel Capacity:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Fuel Capacity"
												value={this.state.Vessel.fuelCapacity}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															fuelCapacity: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
											>
												Water Capacity:
											</label>
											<input
												type="text"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Water Capacity"
												value={this.state.Vessel.waterCapacity}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															waterCapacity: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>

								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-12">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
											>
												Generators :
											</label>
											<CreatableSelect
												isMulti
												isClearable
												formatOptionLabel={"teste"}
												formatCreateLabel={(inputValue) =>
													`Create new generator: ${inputValue}`
												}
												isValidNewOption={(inputValue) => true}
												placeholder="Type the generator name and press enter"
												options={this.state.selectedGenerators}
												value={this.state.selectedGenerators}
												getOptionLabel={(option) => {
													if (option.__isNew__) {
														return option.label;
													}
													return option?.name ?? option.Name ?? option.label;
												}}
												getOptionValue={(option) => {
													return (
														(option?.id ?? option.ID) +
														(option?.name ?? option.Name ?? option.label)
													);
												}}
												onCreateOption={(inputValue) => {
													if(inputValue.trim() != '')
													{
														this.setState({
															selectedGenerators: [
																...this.state.selectedGenerators,
																{
																	ID: null,
																	Name: inputValue,
																	CreatedBy: getUser().userID,
																	VesselID: this.state.Vessel.ID,
																	Deleted: false,
																},
															],
														});
													}}				
													}
													
												onChange={(selectedGenerators) => {
													console.log("onchange!", selectedGenerators);
													this.setState({
														selectedGenerators,
													});
												}}
											/>
										</div>
									</div>
								</div>
								{/* <div className="row" style={{ marginTop: 20 }}>
								<div className="col-md-6">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label">
											Generator 1 Capacity:
										</label>
										<input
											type="number"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Generator 1 Capacity"
											value={this.state.Vessel.generator1Capacity}
											onChange={(e) => {
												this.setState({
													Vessel: {
														...this.state.Vessel,
														generator1Capacity: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Generator 2 Capacity:
										</label>
										<input
											type="number"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Generator 2 Capacity"
											value={this.state.Vessel.generator2Capacity}
											onChange={(e) => {
												this.setState({
													Vessel: {
														...this.state.Vessel,
														generator2Capacity: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div> */}

								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-12">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Crew Number
											</label>
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label"
												style={{ color: "red", marginLeft: 5 }}>
												*
											</label>
											<input
												type="number"
												className="form-control"
												id="exampleFormControlInput1"
												placeholder="Crew Number"
												min={0}
												value={this.state.Vessel.crewNumber}
												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															crewNumber: e.target.value,
														},
													});
												}}
												onKeyDown={(e) => this._handleKeyDown(e.key)}
											/>
										</div>
									</div>
								</div>

								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-md-12">
										<div className="form-group no-margin">
											<label
												htmlFor="exampleFormControlInput1"
												className="control-label">
												Hour for Registering Temperatures
											</label>
											<label
												className="control-label"
												style={{ color: "red", marginLeft: 5 }}
											></label>
											<DatePicker
												selected={this.state.Vessel?.notificationHour ?? null}
												//value={this.state.Vessel?.notificationHour}

												onChange={(e) => {
													this.setState({
														Vessel: {
															...this.state.Vessel,
															notificationHour: e,
														},
													});
												}}
												className="form-control"
												showTimeSelect
												showTimeSelectOnly
												dateFormat="HH:mm"
												timeFormat="HH:mm"
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-default"
									data-dismiss="modal"
									onClick={this.props.CloseFormNoChanges}
								>
									Close
								</button>
								<button
									type="button"
									className="btn btn-info"
									onClick={this.Submit}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			);

		}
	}

export default FormTemplate;

//import react
import React from "react";

import "./css/style.css";

// import apk from './apps/WISEReports.apk'

class Downloads extends React.Component {
	render() {
		return (
			<div className="bodywise">
				<div className="container px-3 py-5">
					{/* <!-- Logo --> */}
					<div className="row m-0 justify-content-center">
						<div className="w-100 d-flex justify-content-center pb-5">
							<img
								className="logowise img-fluid"
								style={{ width: 150, aspectRatio: 1 }}
								src={require("./img/logobit.png")}
								alt=""
							/>
						</div>

						{/* <!-- Downloads --> */}
						<div
							className="col-cardwise"
							style={{
								width: "100%",
								maxWidth: "360px",
								justifyContent: "center",
							}}
						>
							<h3 className="mb-3">Downloads</h3>
							{/* <!-- App Pescador (Android) --> */}
							<div className="link-cardwise">
								{/**/}

								{/* <a href={apk} className="btn main-cardwise" style={{display: 'flex', margin: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 5, borderTopRightRadius: 5}} download="WISEReports.apk">
                            <img className="card-imgwise img-fluid" src={require('./img/iconApp.png')} alt=""/>
                            <p className="fw-bold mb-0" style={{width: '-webkit-fill-available', textAlign:'center !important'}}>UpWise<br/>(Android)</p>
                        </a> */}
								{/* <div className="sub-cardwise">
                            <p className="mb-0">Utilizador: demo@bitcliq.com</p>
                            <p className="mb-0">Senha: password</p>
                        </div> */}
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Footer --> */}
				<div
					id="footer"
					className="fixed-bottom container-fluid p-3 text-center"
				>
					<p className="mb-0">
						2022 © <span className="fw-bold">Bitcliq Technologies, SA.</span>
					</p>
				</div>
			</div>
		);
	}
}

export default Downloads;

import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getVesselGeneratorsByID = async (vesselID) => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(
				`${process.env.REACT_APP_API_URL}/Generator/getAllGeneratorsByVesselID?vesselID=${vesselID}`,
				{
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				}
			)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const createGenerator = async (generator) => {
	axios.defaults.withCredentials = false;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.post(`${process.env.REACT_APP_API_URL}/Generator/save`, generator, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const deleteGenerator = async (generator, user) => {
	axios.defaults.withCredentials = false;

	generator.deleted = true;
	generator.deletedBy = user.id;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.post(`${process.env.REACT_APP_API_URL}/Generator/delete`, generator, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getUsers = async () => {
	axios.defaults.withCredentials = false;

	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/User/getAllUsers`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const saveUser = async (user) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;
		const localtoken = await localStorage.getItem("token");
		await axios
			.post(`${process.env.REACT_APP_API_URL}/User/save`, user, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				/*SuccessNotification(
					"success",
					"User  has been successfully created."
				);*/
				return resolve(response.data);
			})
			.catch((error) => {
				console.log("saveUser", error);
				return reject(error);
			});
	});
};

export const updateUserPassword = async (
	CurrentPassword,
	NewPassword,
	NewPasswordConfirmation
) => {
	axios.defaults.withCredentials = true;
	const localtoken = await localStorage.getItem("token");

	return axios
		.post(`${process.env.REACT_APP_API_URL}/ChangePassword`, {
			CurrentPassword: CurrentPassword,
			NewPassword: NewPassword,
			ConfirmPassword: NewPasswordConfirmation,
			token: localtoken,
		})
		.then((response) => {
			SuccessNotification(
				"success",
				"User password has been successfully updated."
			);
			return response.data;
		})
		.catch((error) => {
			ErrorNotification("error", "User update failed - " + error.response.data);
			return "ERROR" + error.response.data;
		});
};

export const UpdateProfile = async (username, email) => {
	axios.defaults.withCredentials = true;
	const localtoken = await localStorage.getItem("token");

	return axios
		.post(`${process.env.REACT_APP_API_URL}/UpdateProfile`, {
			Username: username,
			Email: email,
			token: localtoken,
		})
		.then((response) => {
			SuccessNotification("success", "User updated successfuly");
			return response.data;
		})
		.catch((error) => {
			ErrorNotification(
				"error",
				"User failed to update - " + error.response.data
			);
			return "ERROR" + error;
		});
};

export const updateUser = async (user) => {
	axios.defaults.withCredentials = true;
	const localtoken = await localStorage.getItem("token");

	await axios
		.post(`${process.env.REACT_APP_API_URL}/User/save`, {
			Username: user.Username,
			Email: user.email,
			DefaultSite: user.defaultSite,
			IsAdmin: user.IsAdmin,
			IsSuperUser: user.IsSuperUser,
			IsBKOUser: user.IsBKOUser,
			IsActive: user.IsActive,
			UserID: user.UserID,
			token: localtoken,
			Categories: user.categories,
			Notes: user.notes,
			Name: user.name,
		})
		.then((response) => {
			/*SuccessNotification(
				"success",
				"User  has been successfully updated."
			);*/
			return response.data;
		})
		.catch((error) => {
			//ErrorNotification("error", "User Update Failed - " + error.response.data);
			ErrorNotification("Error", error.response?.data?.message);

			return "ERROR" + error;
		});
};

export const resetPassword = async (
	email,
	NewPassword,
	PasswordConfirmation
) => {
	axios.defaults.withCredentials = true;
	const localtoken = localStorage.getItem("token");
	return await axios
		.post(`${process.env.REACT_APP_API_URL}/ResetPassword`, {
			email: email,
			token: localtoken,
			NewPassword: NewPassword,
			PasswordConfirmation: PasswordConfirmation,
		})
		.then((response) => {
			SuccessNotification(
				"success",
				"User password has been successfully reset."
			);
			return 1;
		})
		.catch((error) => {
			ErrorNotification(
				"error",
				"User password reset failed - " + error.response.data
			);
			return "ERROR" + error;
		});
};

export const deleteUser = async (user) => {
	axios.defaults.withCredentials = false;
	const localtoken = await localStorage.getItem("token");
	return axios
		.post(`${process.env.REACT_APP_API_URL}/User/delete`, user, {
			headers: {
				Authorization: `Bearer ${localtoken}`,
			},
		})
		.then((response) => {
			SuccessNotification("success", "User deleted successfully.");
			return response.data;
		})
		.catch((error) => {
			ErrorNotification("error", "Error deleting user");
			return "ERROR" + error;
		});
};

export const getUser = async () => {
	axios.defaults.withCredentials = true;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/GetUserFromToken`, {
				params: {
					token: localtoken,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const UserTemplatePermissions = async (UserID) => {
	axios.defaults.withCredentials = true;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/UserTemplatePermissions`, {
				params: {
					token: localtoken,
					id: UserID,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const UpdateUserTemplatePermissions = async (permissions, userid) => {
	axios.defaults.withCredentials = true;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.post(`${process.env.REACT_APP_API_URL}/UpdateUserTemplatePermissions`, {
				token: localtoken,
				permissions: permissions,
				userid: userid,
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const GetUsersWithConnectionToFormAndPermissionStatus = async (
	FormID
) => {
	axios.defaults.withCredentials = true;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(
				`${process.env.REACT_APP_API_URL}/GetUsersWithConnectionToFormAndPermissionStatus`,
				{
					params: {
						token: localtoken,
						formid: FormID,
					},
				}
			)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const SetUsersWithConnectionToFormAndPermissionStatus = async (
	permissions,
	templateid
) => {
	axios.defaults.withCredentials = true;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.post(
				`${process.env.REACT_APP_API_URL}/SetUsersWithConnectionToFormAndPermissionStatus`,
				{
					token: localtoken,
					permissions: permissions,
					templateid: templateid,
				}
			)
			.then((response) => {
				SuccessNotification("success", "Permissions updated successfully.");
				return response.data;
			})
			.catch((error) => {
				ErrorNotification("error", "Error updating permissions.");
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const resetPasswordSendEmail = async (email) => {
	axios.defaults.withCredentials = false;
	try {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/User/forgotPassword`, {
				emailAddress: email,
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				// ErrorNotification("error", "Error updating permissions.");
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const restorePassword = async (uid, password) => {
	axios.defaults.withCredentials = false;
	try {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/User/changePassword`, {
				UIDChangePassword: uid,
				password: password,
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				ErrorNotification("error", "Error changing password.");
				return null;
			});
	} catch (error) {
		return null;
	}
};

//import react
import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from 'react-select';
import { ErrorNotification, SuccessNotification, InfoNotification } from "../../utils/notifications"
import { SubmitTemplate, EditTemplate, CheckIfInternalCodeIsInUse } from "../../api/templates";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const _options = [
    { value: 'Text',            label: 'Title / Separator' },
    { value: 'TextInput',       label: 'Input Text' },
    { value: 'MultiTextInput',  label: 'Input Text Multiline' },
    { value: 'NumberInput',     label: 'Input Number' },
    { value: 'Select',          label: 'List' },
    // { value: 'Image',           label: 'Image' },
    { value: 'Switch',          label: 'Toggle' },
    { value: 'CheckBox',        label: 'CheckBox' },
    { value: 'Rating',          label: 'Rating' },
    { value: 'DatePicker',      label: 'Date' },
    { value: 'TimePicker',      label: 'Time' },
    { value: 'DateTimePicker',  label: 'DateTime' },
    { value: 'Photo',           label: 'Storage Photo' },
    { value: 'PhotoCamera',     label: 'Camera Photo' },
    { value: 'AnyPhoto',        label: 'Camera or Storage Photo' },
    { value: 'GPS',             label: 'GPS Coordinates' }
  ]

var ComponentsID = 0;
let optionsIDs = 0; 

class FormTemplate extends React.Component{
    constructor(props) {
		super(props);
		this.state = {
            loading: true,
            SelectedComponent: null,
            items: [],
            SelectedListComponent: null,
            SelectedComponentIndex: null,
            page: 0,
            template: {
                name: "",
                description: "",
                internalCode: "",
                version: "1"
            },
            EditTemplate: props.EditTemplate
		}
	}

    componentDidUpdate = () => {
        if(this.state.loading)
            this.LoadAll();


        var script = document.getElementById("edit-script-modal-sites");

        if(script){
            console.log("remove script!")
            script.remove();
        }
        var scriptx = document.createElement("script");
        scriptx.id = "edit-script-modal-sites"
        scriptx.innerHTML = "jQuery('#modal-6').modal('show', {backdrop: 'static'}); jQuery('#modal-6').css('max-height', jQuery(window).height());";
        document.body.appendChild(scriptx);
    }

    componentDidMount = () => {
        if(this.state.loading)
            this.LoadAll();
    }
    
    componentWillUnmount = () =>{
        window.jQuery('.modal-backdrop').remove();
    }

    LoadAll = async () => {


        if(this.state.EditTemplate === null || this.state.EditTemplate === undefined)
        {
            this.setState({
                loading: false
            });

            return;
        }

        //se for um clone vamos copiar mas nao vamos tratar como uma cópia...
        if(this.state.EditTemplate?.clone !== undefined && this.state.EditTemplate?.clone !== null && this.state.EditTemplate?.clone === false){
            //ifs podiam estar juntos mas ficam assim para melhor leitura
            if(this.state.EditTemplate !== undefined && this.state.EditTemplate !== null && !this.state.EditTemplate.editable){
                InfoNotification("Attention!", "This Template is not editable! Please edit the last version.");
            }
        }

        // console.log("this.state.EditTemplate", this.state.EditTemplate);

        var list = this.state.items;
        
        var name= this.state.EditTemplate.name;
        var templatedescription = this.state.EditTemplate.description;
        var internalCode = this.state.EditTemplate.templateCode;
        var version = this.state.EditTemplate.version;
        
        var items = this.state.EditTemplate.templateContent;

        
        //foreach item in items
        for(var i = 0; i < items.length; i++){
            var item = items[i];

            var options = [];
            if(item.options !== undefined && item.options !== null && item.options.length > 0){
                //foreach option in item.options
                for(var j = 0; j < item.options.length; j++){
                    options.push({
                        id: item.options[j].id,
                        value: item.options[j].value,
                        type: item.options[j].type
                    }) 
                }   
            }

            var content = _options.find(x => {return x.value === item.type});

            // console.log("content->",content)

            var placeholder = item.placeholder+"";
            var description = "";
            if(item.type === "Switch"){
                try { 
                    if(placeholder.includes('|')){
                        var placeholder_array = placeholder.split('|');
                        placeholder = placeholder_array[0];
                        description = placeholder_array[1];
                    }
                } catch (error) {
                    console.log("parse error",error)
                }
            }
            

            list.push({
                id: item.id+"",
                type: item.type,
                placeholder: placeholder,
                description: description,
                options: options,
                content: content.label,
                show: item.show ?? true,
                quantity: item.quantity,
                InputOption: '',
                mandatory: item.mandatory ?? false,
            });
        }

        this.setState({
            loading: false,
            template: {
                name: name,
                description: templatedescription,
                internalCode: internalCode,
                version: this.state.EditTemplate?.clone ? 1 : version
            }
        });

        if(this.state.EditTemplate?.clone !== undefined && this.state.EditTemplate?.clone !== null && this.state.EditTemplate?.clone === true){
            this.setState({
                EditTemplate: null
            })
        }

    }

    reorderList = (list, starti, endi) => {
        const res = Array.from(list);
        const [removed] = res.splice(starti, 1);
        res.splice(endi, 0, removed);
        return res;
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = this.reorderList(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items: items
        });

        this.setState({
            SelectedListComponent: null,
            SelectedComponentIndex: null
        });
    }

    onDragEndOptions = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = this.reorderList(
            this.state.SelectedListComponent.options,
            result.source.index,
            result.destination.index
        );
        
        var SelectedListComponent = this.state.SelectedListComponent;

        SelectedListComponent.options = items;

        this.setState({
            SelectedListComponent: SelectedListComponent,
        });
    }


    CloseForm = async () => {


        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure to do this.',
        //     buttons: [
        //       {
        //         label: 'Yes',
        //         onClick: () => {alert('Click Yes')}
        //       },
        //       {
        //         label: 'No',
        //         onClick: () => {alert('Click No')}
        //       }
        //     ]
        //   });

        if(window.confirm("Are you sure you want to leave? You'll lose all changes.")){
            try {
                var scriptx = document.createElement("script");
                scriptx.id = "closemodal"
                scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
                document.body.appendChild(scriptx);

                document.findElementById("closemodal").remove();
            } catch (error) {
                
            }
            this.props.CloseForm();
        }
    }

    NewComponentChanged = (value) => {
        this.setState({
            SelectedComponent: value
        });
    }

    Back = async () => {
        this.setState({
            page: this.state.page - 1
        });
    }

    Next = async () => {
        if(this.state.page === 1){
            this.submit();
            return;
        }

        if(this.state.page === 0) // meta data
        {
            var errors = [];
            
            if(this.state.template.name === "")
            {
                errors.push("Name is required");
            }
            
            if((this.state.EditTemplate === null) && (this.state.template.internalCode === "" || this.state.valid+"" !== "1"))
            {
                errors.push("A Valid Internal Code is required");
            }

            if(this.state.template.version === "")
            {
                errors.push("Version is required");
            }

            if(errors.length > 0)
            {
                // console.log("ERRORS!", errors);
                ErrorNotification("Error", errors.join(", ")+".");
                return;
            }

        }

        this.setState({
            page: this.state.page + 1
        });        
    }

    submit = async () => {

        if(this.state.EditTemplate !== undefined && this.state.EditTemplate !== null && !this.state.EditTemplate.editable){
            ErrorNotification("Error", "This template is not editable. You cannot edit old versions.");
            return;
        }

        var componentID = 0;
        var Template = {};

        Template.name = this.state.template.name;
        Template.description = this.state.template.description;
        Template.templateCode = this.state.template.internalCode;
        Template.version = this.state.template.version;
        
        var items = [];
        
        if(this.state.items.length === 0){
            ErrorNotification("Error", "Template is empty.");
            return;
        }

        for(var i = 0; i < this.state.items.length; i++){

            var item = this.state.items[i];

            var options = [];
            if(item.options !== undefined && item.options !== null && item.options.length > 0){
                //foreach option in item.options
                for(var j = 0; j < item.options.length; j++){
                    options.push({
                        id: item.type === "Select" ? componentID+"_"+(j+1) : componentID,
                        value: item.options[j].value,
                        type: item.options[j].type,
                        default: false
                    }) 
                    if(item.type !== "Select")
                        componentID++;
                }   
            }

            var placeholder = item.placeholder+"";

            if(item.type === "Switch" && item.description !== ""){
                placeholder = placeholder+"|"+item.description;
            }

            items.push({
                id: item.type !== 'CheckBox' ? componentID : null,
                type: item.type,
                placeholder: placeholder,
                description: item.description,
                options: options,
                quantity: item.quantity,
                show: item.type === "GPS" ? item.show : null,
                mandatory: item.mandatory ?? false,
            });

            if(item.type !== 'CheckBox')
                componentID++;

        }

        Template.templateContent = items;

        // console.log(Template);
        var SubmitResponse;
        if(this.state.EditTemplate !== null){
            SubmitResponse = await EditTemplate(Template);
        }else{
            SubmitResponse = await SubmitTemplate(Template);
        }

        if(SubmitResponse !== null){
            try {
                var scriptx = document.createElement("script");
                scriptx.id = "closemodal"
                scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
                document.body.appendChild(scriptx);

                document.findElementById("closemodal").remove();
            } catch (error) {
                
            }
            
            this.props.CloseForm();
        }
        


    }

    AddComponent = async () => {
        
        if(this.state.SelectedComponent === null)
        {
            ErrorNotification("Error", "Please select a component first");
            return;
        }

        var list = this.state.items;

        list.push({
            id: "item_"+ (ComponentsID++),
            type: this.state.SelectedComponent.value,
            content: this.state.SelectedComponent.label,
            placeholder: '',
            description: '',
            quantity: 5,
            show: true,
            InputOption: '',
            mandatory: false,
        });

        this.NewComponentChanged(null);

        this.ItemSelected(list.length-1);
    }

    RemoveItem = async (index) => {
        var list = this.state.items;
        list.splice(index, 1);

        this.setState({
            items: list,
            SelectedListComponent: null,
            SelectedComponentIndex: null
        });
    }

    ItemSelected = (index) => {

        var ListComponent = this.state.items[index];

        if(ListComponent === null || ListComponent === undefined)
            return;

        
        this.setState({
            SelectedListComponent: ListComponent,
            SelectedComponentIndex: index
        });

    }

    FormNameChanged = (value) =>{
        this.setState({
            template: {
                ...this.state.template,
                name: value
            }
        });
    }

    ChangeShow = () => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];

        ListComponent.show = !ListComponent.show;
        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    FormDescriptionChanged = (value) =>{
        this.setState({
            template: {
                ...this.state.template,
                description: value
            }
        });
    }

    FormCodeChanged = (value) =>{
        //Cooker_Insp_Rpt_v1

        //check if code is valid
        
        this.setState({
            template: {
                ...this.state.template,
                internalCode: value
            },
            valid: null
        });

        this.validateCode(value);
    }

    validateCode = async (value) => {
        let result = await CheckIfInternalCodeIsInUse(value);
        this.setState({
            valid: result
        });
    }

    FormVersionChanged = (value) =>{
        //if value is not a number
        if(isNaN(parseInt(value)) || (value+"").includes('.')){
            ErrorNotification("Error", "Version must be an integer ex: 1, 2, 3...");
            return;
        }

        if((value+"").length > 9){
            ErrorNotification("Error", "Version must be 9 digits or less");
            return;
        }
        

        this.setState({
            template: {
                ...this.state.template,
                version: value
            }
        });
    }


    ChangeInputOption = (value) => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];
        
        ListComponent.InputOption = value;

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    ChangePlaceholder = (value) => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];
        
        ListComponent.placeholder = value;

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    ChangeDescription = (value) => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];

        ListComponent.description = value;

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    ChangeQuantity = (value) => {
        if(value+"" !== "" && (parseInt(value) < 1 || parseInt(value) > 10)){
            let valueint = parseInt(value);
            if(!isNaN(valueint) && valueint < 1){
                value = 1
            }
            else if (!isNaN(valueint) && valueint > 10){
                value = 10
            }else{
                value = 5;
            }
        }

        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];

        ListComponent.quantity = value;

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    ChangeDDPlaceholder = (value) => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];
        
        ListComponent.ddplaceholder = value;

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    ChangeMandatory = () => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];

        ListComponent.mandatory = !ListComponent.mandatory;

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    AddOption = async () => {
        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];
        var option = ListComponent.InputOption;

        if(option === "")
        {
            ErrorNotification("Error", "Please enter an option");
            return;
        }

        if(ListComponent.options === undefined)
        {
            ListComponent.options = [];
        }

        ListComponent.options.push({
            value: option,
            id: "option_"+ (optionsIDs++),
            type: "option"
        });

        ListComponent.InputOption = "";


        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });

    }

    RemoveOption = async (index) => {

        var List = this.state.items;
        var ListComponent = List[this.state.SelectedComponentIndex];
        var options = ListComponent.options;

        options.splice(index, 1);

        this.setState({
            items: List,
            SelectedListComponent: ListComponent
        });
    }

    render(){

        if(this.state.loading)
            return null;

        return (
			<div className="modal fade custom-width" id="modal-6">
                <div className="modal-dialog " style={{width:'100%', height:'100%', margin:0}}>
                    <div className="modal-content" style={{height:'100%'}}>
                        
                        <div className="modal-header">
							<button type="button" className="close" onClick={this.CloseForm}>&times;</button>
							<h4 className="modal-title">{this.state.EditTemplate !== null && this.state.EditTemplate !== null ? "Edit": "New"} Form</h4>
                        </div>
                        {
                            this.state.page === 0 ?
                            <div className="modal-body custom-light-gray" style={{height:'88%', alignContent:'center', justifyContent:'center', display:'flex'}}>
                                <div style={{width:'50%'}}>
                                    <div style={{display:'flex', marginTop:5, paddingBottom:5, justifyContent:'center', marginBottom:40}}>
                                        <h2>Meta Data</h2>
                                    </div>
                                    <form>
                                        <div className="form-group row">
                                            <label htmlFor="templateName" className="col-sm-4 col-form-label"><div style={{width: 'fit-content', float:'left'}}>Name</div><div style={{color:'red', float: 'left', width: 'fit-content'}}>*</div></label>
                                            <div className="col-sm-8">
                                                <input maxLength={40} type="text" className="form-control" id="templateName" placeholder={'<Form Name>'} value={this.state.template.name} onChange={(e) => this.FormNameChanged(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label htmlFor="templateDescription" className="col-sm-4 col-form-label">Description</label>
                                            <div className="col-sm-8">
                                                <input maxLength={50} type="text" className="form-control" id="templateDescription" placeholder={'<Form Description>'} value={this.state.template.description} onChange={(e) => this.FormDescriptionChanged(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3">
                                            <label htmlFor="templateCode" className="col-sm-4 col-form-label"><div style={{width: 'fit-content', float:'left'}}>Internal Code</div><div style={{color:'red', float: 'left', width: 'fit-content'}}>*</div></label>
                                            <div className="col-sm-8">
                                                <input maxLength={40} type="text" className="form-control" style={{borderColor: this.state.valid+"" === "-1" ? 'red' : this.state.valid+"" === "1" ? 'green':'#ced4da'}} id="templateCode" placeholder={'<Form Internal Code>'} disabled={this.state.EditTemplate !== null} value={this.state.template.internalCode} onChange={(e) => this.FormCodeChanged(e.target.value)} />
                                                <div style={{marginTop:5}}>
                                                {
                                                    this.state.valid === undefined ? ''
                                                    : this.state.valid === null ?
                                                        <div className="spinner-border text-primary" role="status" style={{maxWidth:20, maxHeight:20}}> {/*Loading*/}
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    : this.state.valid+"" === "1" ?
                                                    <p style={{color:'green', fontSize:12}}>{"Valid code"}</p>
                                                    : <p style={{color:'red', fontSize:12}}>{"Invalid code or already in use"}</p>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row mt-3" style ={{display:'none'}}>
                                            <label htmlFor="templateCode" className="col-sm-4 col-form-label"><div style={{width: 'fit-content', float:'left'}}>Version</div><div style={{color:'red', float: 'left', width: 'fit-content'}}>*</div></label>
                                            <div className="col-sm-8">
                                                <input type="number" className="form-control" id="templateCode" step={1} placeholder={'<Form Version>'} value={this.state.template.version} onChange={(e) => this.FormVersionChanged(e.target.value)}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>:null
                        }
                        {
                            this.state.page === 1 ?
                            <div className="modal-body custom-light-gray overflow-hidden container" style={{height:'88%', alignContent:'center', justifyContent:'center', display:'flex', width:'100%', margin: 0}}>
                                <div className='row h-100' style={{width:'100%', height:'100%'}}>
                                    {/* Add controlls to list */}
                                    <div className='col-md-4' style={{borderRightStyle: 'dotted', borderWidth: 1, borderColor: 'gray', height:'100%'}}>
                                        {/* CENTERED DIV WITH FORM (add controlls to list) */}
                                        <div className='mt-20 w-100 d-align-middle d-flex align-items-center justify-content-center'
                                            style={{justifyContent:'center', alignItems:'center', display:'flex', width:'100%'}}>
                                            <div className='col-md-8'>
                                                <div className='col-md-12 text-center'>
                                                    <p className='h5'>Component</p>
                                                </div>
                                                <div className='col-md-12 mt-3' >
                                                    <Select options={_options} value={this.state.SelectedComponent} placeholder={"Select a component"} onChange={(value) => this.NewComponentChanged(value) } />
                                                </div>
                                                <div className='col-md-12 text-center' style={{marginTop:25}}>
                                                    <button type='button' className='btn btn-success' style={{width:'25%', borderRadius: 10}} onClick={() => this.AddComponent()}>ADD</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* CENTERED with list drag and drop */}
                                    <div className='col-md-4 h-100 overflow-auto' style={{borderRightStyle: 'dotted', borderWidth: 1, borderColor: 'gray', height:'100%', overflow:'auto'}}>
                                        <DragDropContext onDragEnd={this.onDragEnd} b>
                                            <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                >
                                                {this.state.items.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        >
                                                            <div className={`border border-dark rounded mt-2 row`} style={{borderStyle:'solid', borderWidth:1, borderColor:'black', borderRadius: 10, minHeight: '50px', marginLeft: '0px', marginRight: '0px', marginTop:10, display:'flex', alignContent:'center', justifyContent:'center' }}>
                                                                <div className='col-md-11' style={{display:'flex', verticalAlign:'center'}} onClick={() => this.ItemSelected(index)} >
                                                                    <i className="fa fa-bars d-inline-block justify-content-center align-self-center" style={{fontSize: '25px', marginRight: '15px', alignSelf:'center', verticalAlign: 'middle', display:'inline-block'}}></i>
                                                                    <p className='d-inline-block float-right mb-0 justify-content-center align-self-center' style={{fontSize:'14px', display:'inline-block', alignSelf:'center', verticalAlign: 'middle', margin:0}}>{item.content} - {item.placeholder}</p>
                                                                </div>
                                                                <div className='col-md-1 align-items-end align-middle d-flex flex-row-reverse justify-content-center' style={{display:'flex', verticalAlign:'center', flexDirection:'row-reverse', justifyContent:'center', alignItems:'center'}}>
                                                                    <button type='button' className='btn btn-danger align-self-center' style={{borderRadius:10, alignSelf:'center', }} onClick={() => this.RemoveItem(index)}>X</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                </div>
                                            )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                    <div className='col-md-4 h-100 overflow-auto' style={{height:'100%'}}>
                                        {/* Edit component */}
                                        {
                                            this.state.SelectedListComponent !== null ?
                                                <div className='w-100 d-align-middle d-flex align-items-center justify-content-center'>
                                                    <div className='col-md-10'>
                                                        <div className='col-md-12 d-inline mt-2'>
                                                            {
                                                            
                                                            this.state.SelectedListComponent === null ?
                                                            null :
                                                            
                                                            this.state.SelectedListComponent.type === 'Text' ?
                                                            <>
                                                                <div className="form-group row">
                                                                    <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                    <div className="col-md-8">
                                                                        <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Label</label>
                                                                    <div className="col-md-8">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.placeholder} onChange={(e) => this.ChangePlaceholder(e.target.value)} placeholder="<label>"/>
                                                                    </div>
                                                                </div>
                                                            </>

                                                            :

                                                            (this.state.SelectedListComponent.type === 'TextInput' 
                                                            || this.state.SelectedListComponent.type === 'MultiTextInput'
                                                            || this.state.SelectedListComponent.type === 'NumberInput'
                                                            || this.state.SelectedListComponent.type === 'DatePicker'
                                                            || this.state.SelectedListComponent.type === 'DateTimePicker'
                                                            || this.state.SelectedListComponent.type === 'TimePicker'
                                                            || this.state.SelectedListComponent.type === 'Photo'
                                                            || this.state.SelectedListComponent.type === 'PhotoCamera'
                                                            || this.state.SelectedListComponent.type === 'AnyPhoto'
                                                            )
                                                            ?
                                                                <>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                        <div className="col-md-8">
                                                                            <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mt-2">
                                                                        <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Label</label>
                                                                        <div className="col-md-8">
                                                                            <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.placeholder} onChange={(e) => this.ChangePlaceholder(e.target.value)} placeholder="<label>"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mt-2 justify-content-center">
                                                                        <label htmlFor="ComponentMandatory" className="col-md-4 col-form-label">Mandatory</label>
                                                                        <div className="col-md-8 my-auto">
                                                                            <input type="checkbox" className="form-check-input" id="ComponentMandatory" checked={this.state.SelectedListComponent.mandatory} onChange={() => this.ChangeMandatory()}/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            :
                                                            this.state.SelectedListComponent.type === 'Select'
                                                            ?
                                                            <>
                                                                <div className="form-group row">
                                                                    <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                    <div className="col-md-8">
                                                                        <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-4 col-form-label">Placeholder</label>
                                                                    <div className="col-8">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.ddplaceholder} onChange={(e) => this.ChangeDDPlaceholder(e.target.value)} placeholder="<placeholder>"/>
                                                                    </div>
                                                                </div> */}
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Label</label>
                                                                    <div className="col-md-8">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.placeholder} onChange={(e) => this.ChangePlaceholder(e.target.value)} placeholder="<label>"/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2 justify-content-center">
                                                                    <label htmlFor="ComponentMandatory" className="col-md-4 col-form-label">Mandatory</label>
                                                                    <div className="col-md-8 my-auto">
                                                                        <input type="checkbox" className="form-check-input" id="ComponentMandatory" checked={this.state.SelectedListComponent.mandatory} onChange={() => this.ChangeMandatory()}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Options</label>
                                                                    <div className="col-md-5">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.InputOption} onChange={(e)=> this.ChangeInputOption(e.target.value)} placeholder="<option>"/>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <button onClick={()=>this.AddOption()} className="btn btn-success" style={{borderRadius:10}}>ADD</button>
                                                                    </div>
                                                                </div>
                                                                {

                                                                <div className='h-100 overflow-auto' style={{height:'100%', overflow:'auto'}}>
                                                                    <DragDropContext onDragEnd={this.onDragEndOptions}>
                                                                        {this.state.SelectedListComponent.options !== undefined && this.state.SelectedListComponent.options !== null ?
                                                                        <Droppable droppableId="options_droppable">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            >
                                                                            {this.state.SelectedListComponent.options.map((item, index) => (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                        >
                                                                                       
                                                                                       
                                                                                       {/* <div className='col-md-12 mt-2 justify-content-center align-self-center' style={{textAlign:'center', justifyContent:'center', verticalAlign:'middle', marginTop:5, minHeight: 50, display:'flex'}} key={index}>
                                                                                            <div className='col-md-8 d-inline-flex justify-content-center align-self-center' style={{borderWidth: 1, borderStyle: 'solid', padding:0, borderColor:'black', display:'inline-flex', borderRadius: 10, height: 50}} >
                                                                                                <div className='col-md-8 align-middle d-flex' style={{display:'flex', alignContent:'center', verticalAlign:'middle', padding: 0}}>
                                                                                                    <i className="fa fa-bars" style={{fontSize:'25px', marginLeft:'15px', verticalAlign: 'middle', display:'inline-block', justifyContent:'center', alignSelf:'center'}}></i>
                                                                                                    <p className='d-inline-block float-right mb-0 justify-content-center align-self-center' style={{display:'inline-block', marginBottom:0, justifyContent:'center', alignContent:'center', verticalAlign:'center', height:'fit-content', marginLeft:10, alignSelf:'center'}}>{item.value}</p>
                                                                                                </div>
                                                                                                <div className='col-md-4 align-items-end align-middle d-flex flex-row-reverse justify-content-center' style={{alignItems:'center', alignContent:'center', display:'flex', flexDirection:'row-reverse', justifyContent:'center'}}>
                                                                                                    <button type='button' className='btn btn-danger align-self-center' style={{alignContent:'center'}} onClick={() => this.RemoveOption(index)}>X</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div className={`border border-dark rounded mt-2 row`} style={{borderStyle:'solid', borderWidth:1, borderColor:'black', borderRadius: 10, minHeight: '50px', marginLeft: '0px', marginRight: '0px', marginTop:10, display:'flex', alignContent:'center', justifyContent:'center', width:'75%' }}>
                                                                                            <div className='col-md-8 align-middle d-flex' style={{display:'flex', alignContent:'center', verticalAlign:'middle', padding: 0}}>
                                                                                                <i className="fa fa-bars" style={{fontSize:'25px', marginLeft:'15px', verticalAlign: 'middle', display:'inline-block', justifyContent:'center', alignSelf:'center'}}></i>
                                                                                                <p className='d-inline-block float-right mb-0 justify-content-center align-self-center' style={{display:'inline-block', marginBottom:0, justifyContent:'center', alignContent:'center', verticalAlign:'center', height:'fit-content', marginLeft:10, alignSelf:'center'}}>{item.value}</p>
                                                                                            </div>
                                                                                            <div className='col-md-4 align-items-end align-middle d-flex flex-row-reverse justify-content-center' style={{alignItems:'center', alignContent:'center', display:'flex', flexDirection:'row-reverse', justifyContent:'center'}}>
                                                                                                <button type='button' className='btn btn-danger align-self-center' style={{alignContent:'center'}} onClick={() => this.RemoveOption(index)}>X</button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                        </Droppable>
                                                                        : null}
                                                                    </DragDropContext>
                                                                    </div>
                                                                    // this.state.SelectedListComponent.options !== null && this.state.SelectedListComponent.options !== undefined ?
                                                                    //     this.state.SelectedListComponent.options.map((item, index) => (
                                                                    //         <div className='col-12 mt-2' key={index}>
                                                                    //             <div className='col-12 d-flex'>
                                                                    //                 <div className='col-8 align-middle d-flex'>
                                                                    //                     <i className="fas fa-circle d-inline-block justify-content-center align-self-center" style={{fontSize:'5px' , marginRight: '15px', verticalAlign: 'middle'}}></i>
                                                                    //                     <p className='d-inline-block float-right mb-0 justify-content-center align-self-center'>{item.value}</p>
                                                                    //                 </div>
                                                                    //                 <div className='col-4 align-items-end align-middle d-flex flex-row-reverse justify-content-center'>
                                                                    //                     <button type='button' className='btn btn-danger align-self-center' onClick={() => this.RemoveOption(index)}>X</button>
                                                                    //                 </div>
                                                                    //             </div>
                                                                    //         </div>
                                                                    //     ))
                                                                    // : null
                                                                }
                                                            </>
                                                            : 
                                                            this.state.SelectedListComponent.type === 'Switch' ?
                                                                <>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                        <div className="col-md-8">
                                                                            <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mt-2">
                                                                        <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Label</label>
                                                                        <div className="col-md-8">
                                                                            <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.placeholder} onChange={(e) => this.ChangePlaceholder(e.target.value)} placeholder="<label>"/>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="form-group row mt-2 justify-content-center">
                                                                        <label htmlFor="ComponentMandatory" className="col-4 col-form-label">Mandatory</label>
                                                                        <div className="col-8 my-auto">
                                                                            <input type="checkbox" className="form-check-input" id="ComponentMandatory" checked={this.state.SelectedListComponent.mandatory} onChange={() => this.ChangeMandatory()}/>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="form-group row mt-2">
                                                                        <label htmlFor="ComponentDescription" className="col-md-4 col-form-label">Description</label>
                                                                        <div className="col-md-8">
                                                                            <input type="text" className="form-control" id="ComponentDescription" value={this.state.SelectedListComponent.description} onChange={(e) => this.ChangeDescription(e.target.value)} placeholder="<description>"/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            :
                                                            this.state.SelectedListComponent.type === 'CheckBox'
                                                            ?
                                                            <>
                                                                <div className="form-group row">
                                                                    <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                    <div className="col-md-8">
                                                                        <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Label</label>
                                                                    <div className="col-md-8">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.placeholder} onChange={(e) => this.ChangePlaceholder(e.target.value)} placeholder="<label>"/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Options</label>
                                                                    <div className="col-md-5">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.InputOption} onChange={(e)=> this.ChangeInputOption(e.target.value)} placeholder="<option>"/>
                                                                    </div>
                                                                    {/* <div className='col-md-3'>
                                                                        <button onClick={()=>this.AddOption()} className="btn btn-success"><i className="fas fa-plus d-inline-block justify-content-center align-self-center d-none"></i><p className='d-inline-block float-right mb-0 justify-content-center align-self-center'>&nbsp;{"ADD"}</p></button>
                                                                    </div> */}
                                                                    
                                                                    <div className='col-md-3'>
                                                                        <button onClick={()=>this.AddOption()} className="btn btn-success" style={{borderRadius:10}}>ADD</button>
                                                                    </div>
                                                                </div>
                                                                {

                                                                    <DragDropContext onDragEnd={this.onDragEndOptions} >
                                                                        {this.state.SelectedListComponent.options !== undefined && this.state.SelectedListComponent.options !== null ?
                                                                    <Droppable droppableId="checkbox_droppable">
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        >
                                                                        {this.state.SelectedListComponent.options.map((item, index) => (
                                                                            <Draggable key={"checkbox_"+item.id} draggableId={"checkbox_"+item.id} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                >
                                                                                    {/* <div className='col-md-12 mt-2 justify-content-center align-self-center' style={{textAlign:'center'}} key={index}>
                                                                                        <div className='col-md-8 d-inline-flex justify-content-center align-self-center' style={{borderWidth: 1, borderStyle: 'solid', borderRadius: 10, height: 50}} >
                                                                                            <div className='col-md-8 align-middle d-flex'>
                                                                                                <i className="fas fa-circle d-inline-block justify-content-center align-self-center" style={{fontSize:'5px' , marginRight: '15px', marginLeft:'15px', verticalAlign: 'middle'}}></i>
                                                                                                <p className='d-inline-block float-right mb-0 justify-content-center align-self-center'>{item.value}</p>
                                                                                            </div>
                                                                                            <div className='col-md-4 align-items-end align-middle d-flex flex-row-reverse justify-content-center'>
                                                                                                <button type='button' className='btn btn-danger align-self-center' onClick={() => this.RemoveOption(index)}>X</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    <div className={`border border-dark rounded mt-2 row`} style={{borderStyle:'solid', borderWidth:1, borderColor:'black', borderRadius: 10, minHeight: '50px', marginLeft: '0px', marginRight: '0px', marginTop:10, display:'flex', alignContent:'center', justifyContent:'center', width:'75%' }}>
                                                                                        <div className='col-md-8 align-middle d-flex' style={{display:'flex', alignContent:'center', verticalAlign:'middle', padding: 0}}>
                                                                                            <i className="fa fa-bars" style={{fontSize:'25px', marginLeft:'15px', verticalAlign: 'middle', display:'inline-block', justifyContent:'center', alignSelf:'center'}}></i>
                                                                                            <p className='d-inline-block float-right mb-0 justify-content-center align-self-center' style={{display:'inline-block', marginBottom:0, justifyContent:'center', alignContent:'center', verticalAlign:'center', height:'fit-content', marginLeft:10, alignSelf:'center'}}>{item.value}</p>
                                                                                        </div>
                                                                                        <div className='col-md-4 align-items-end align-middle d-flex flex-row-reverse justify-content-center' style={{alignItems:'center', alignContent:'center', display:'flex', flexDirection:'row-reverse', justifyContent:'center'}}>
                                                                                            <button type='button' className='btn btn-danger align-self-center' style={{alignContent:'center'}} onClick={() => this.RemoveOption(index)}>X</button>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                    </Droppable>
                                                                    : null
                                                                    }
                                                                    </DragDropContext>
                                                                    // this.state.SelectedListComponent.options !== null && this.state.SelectedListComponent.options !== undefined ?
                                                                    //     this.state.SelectedListComponent.options.map((item, index) => (
                                                                    //         <div className='col-12 mt-2' key={index}>
                                                                    //             <div className='col-12 d-flex'>
                                                                    //                 <div className='col-8 align-middle d-flex'>
                                                                    //                     <i className="fas fa-circle d-inline-block justify-content-center align-self-center" style={{fontSize:'5px' , marginRight: '15px', verticalAlign: 'middle'}}></i>
                                                                    //                     <p className='d-inline-block float-right mb-0 justify-content-center align-self-center'>{item.value}</p>
                                                                    //                 </div>
                                                                    //                 <div className='col-4 align-items-end align-middle d-flex flex-row-reverse justify-content-center'>
                                                                    //                     <button type='button' className='btn btn-danger align-self-center' onClick={() => this.RemoveOption(index)}>X</button>
                                                                    //                 </div>
                                                                    //             </div>
                                                                    //         </div>
                                                                    //     ))
                                                                    // : null
                                                                }
                                                            </> : 
                                                            this.state.SelectedListComponent.type === 'Rating' ?
                                                            <>
                                                                <div className="form-group row">
                                                                    <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                    <div className="col-md-8">
                                                                        <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Label</label>
                                                                    <div className="col-md-8">
                                                                        <input type="text" className="form-control" id="ComponentPlaceholder" value={this.state.SelectedListComponent.placeholder} onChange={(e) => this.ChangePlaceholder(e.target.value)} placeholder="<label>"/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2 justify-content-center">
                                                                    <label htmlFor="ComponentMandatory" className="col-md-4 col-form-label">Mandatory</label>
                                                                    <div className="col-md-8 my-auto">
                                                                        <input type="checkbox" className="form-check-input" id="ComponentMandatory" checked={this.state.SelectedListComponent.mandatory} onChange={() => this.ChangeMandatory()}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mt-2">
                                                                    <label htmlFor="ComponentQuantity" className="col-md-4 col-form-label">Quantity</label>
                                                                    <div className="col-md-8">
                                                                        <input type="number" className="form-control" id="ComponentQuantity" value={this.state.SelectedListComponent.quantity} onChange={(e) => this.ChangeQuantity(e.target.value)} placeholder="1-10"/>
                                                                    </div>
                                                                </div>
                                                            </> : 
                                                            this.state.SelectedListComponent.type === 'GPS' ?
                                                                <>
                                                                    <div className="form-group row">
                                                                        <label htmlFor="ComponentType" className="col-md-4 col-form-label">Component Type</label>
                                                                        <div className="col-md-8">
                                                                            <label className="col-form-label" id="ComponentType">{this.state.SelectedListComponent.content}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row mt-2 justify-content-center">
                                                                        <label htmlFor="ComponentPlaceholder" className="col-md-4 col-form-label">Show on template</label>
                                                                        <div className="col-md-8">
                                                                            <input type="checkbox" className="form-check-input" id="ComponentPlaceholder" checked={this.state.SelectedListComponent.show} onChange={() => this.ChangeShow()} placeholder="<label>"/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            :null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>:null
                        }
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary py-2 px-4" data-bs-dismiss="modal" onClick={this.CloseForm}>Close</button>
                        {
                            this.state.page > 0 ?
                                <button type="button" className="btn btn-esip-blue py-2 px-4" onClick={()=>this.Back()} >{'Back'}</button>
                            : null
                        }
                        <button type="button" className="btn btn-esip-blue py-2 px-4" onClick={()=>this.Next()} >{this.state.page === 1 ? this.state.EditTemplate !== null && this.state.EditTemplate !== null ? "Update" : "Create" : 'Next'}</button>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default (FormTemplate);
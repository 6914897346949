import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

 

export const getCatchSummary = async (vesselID, tripNumber) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/ExportData/getSummaryWeight?vesselID=${vesselID}&tripNumber=${tripNumber}`,{
        headers: {
            'Authorization': `Bearer ${localtoken}`
        }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return "ERROR" + error;
            });
};


export const getHatchesTemperatures = async (vesselID, tripNumber) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/ExportData/getHatchesTemperatures?vesselID=${vesselID}&tripNumber=${tripNumber}`,{
        headers: {
            'Authorization': `Bearer ${localtoken}`
        }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return "ERROR" + error;
            });
};

export const getAllVessels = async () => {
    axios.defaults.withCredentials = false

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/Vessel/getAllVessels`,{
            headers: {
                'Authorization': `Bearer ${localtoken}`
            }
        })
        .then(response => {


            console.log("Response: ", response.data);
            return response.data;
        })
        .catch(error => {
            console.log("Error: ", error);
            return [];
        });
    } catch (error) {
        return [];
    }
};


export const getAllTrips = async (vesselID) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/Trip/getAllTrips?vesselID=${vesselID}`,{
    headers: {
        'Authorization': `Bearer ${localtoken}`
    }
    })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return "ERROR" + error;
		});
};

export const getCurrentTrip = async (vesselID) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/Vessel/getCurrentTrip?vesselID=${vesselID}`,{
        headers: {
            'Authorization': `Bearer ${localtoken}`
        }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return "ERROR" + error;
            });
};




export const getHatchesTemperaturesAllVessels = async () => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/ExportData/getCurrentTripsHatchesTemperatures`,{
        headers: {
            'Authorization': `Bearer ${localtoken}`
        }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return "ERROR" + error;
            });
};



export const getVesselHatchesTemperatures = async (vesselID) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/ExportData/getVesselHatchesTemperatures?vesselID=${vesselID}`,{
        headers: {
            'Authorization': `Bearer ${localtoken}`
        }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return "ERROR" + error;
            });
};





//import react
import React from "react";
import logo from "../assets/white_1.svg";
import "../App.css";
import { getAccessToken, setAccessToken } from "../utils/accessToken";
import { getToken } from "../api/getToken";
import { setUser } from "../utils/loggedUser";

import { SuccessNotification } from "../utils/notifications";
import {
	useLocation,
	useNavigate,
	useParams,
	Navigate,
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}

	return ComponentWithRouterProp;
}

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			error: false,
			errormsg: "",
			loading: true,
			showpassword: false,
			forgotPassword: false,
			resetFinished: props?.router?.location?.state?.resetSuccessful ?? false,
		};
	}

	componentDidMount = async () => {
		if (this.state?.resetFinished) {
			this.setState({
				resetFinished: false,
			});

			//send notification
			SuccessNotification("Your password has been reset successfully");
		}

		// console.log("mount")
		if (this.state.loading) {
			// var storagetoken = localStorage.getItem('token');
			// setAccessToken(storagetoken);
			// console.log("mount load")
			let token = getAccessToken();

			this.setState({
				token: token,
				loading: false,
			});

			if (token !== null && token !== undefined && token !== "") {
				return;
			}
		}

		try {
			window.$("body").addClass("login-page login-form-fall");
			var scriptx = document.createElement("script");

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-neonapi";
			scriptx.src = "/assets/js/neon-api.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-validate";
			scriptx.src = "/assets/js/jquery.validate.min.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-login";
			scriptx.src = "/assets/js/neon-login.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-custom";
			scriptx.src = "/assets/js/neon-custom.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);
		} catch (error) {}
	};

	componentWillUnmount = () => {
		try {
			window.$("#neon-custom-sidebar-neonapi").remove();
			window.$("#neon-custom-sidebar-validate").remove();
			window.$("#neon-custom-sidebar-login").remove();
			window.$("#neon-custom-sidebar-custom").remove();
		} catch (error) {}
	};

	componentDidUpdate = async () => {
		// console.log("update login!")

		// console.log("update")
		if (this.state.loading) {
			// console.log("update load")
			let token = getAccessToken();
			this.setState({
				token: token,
				loading: false,
			});
		}
	};

	PasswordChanged = async (password) => {
		this.setState({
			password: password,
		});
		this.ErrorChanged(false);
	};

	EmailChanged = async (email) => {
		this.setState({
			email: email,
			EmailHasError: false,
		});
		this.ErrorChanged(false);
	};

	ErrorChanged = async (error, response) => {
		await this.setState({
			error: error,
			errormsg: response ?? "",
		});
	};

	_handleKeyDown = (key) => {
		if (key === "Enter") {
			this.handleSubmit();
		}
	};

	handleSubmit = async () => {
		try {
			// setUser({
			// 	email: "teste",
			// 	password: "teste",
			// 	username: "teste",
			// 	isAdmin: true
			// });

			// setAccessToken("xpto");

			// this.setState({
			// 	token: "xpto"
			// })

			// return;

			if (this.state.password === "") {
				this.setState({
					PasswordHasError: true,
				});
				return;
			}

			//$(".login-page").addClass('logging-in');

			try {
				var script = document.createElement("script");
				script.id = "remove-scripts-sites";
				script.innerHTML = "$('.login-page').addClass('logging-in');";
				document.body.appendChild(script);
				document.getElementById("remove-scripts-sites").remove();
			} catch (error) {}

			const response = await getToken(this.state.email, this.state.password);

			//if string response contrains error

			if (response.token) {
				//change bar to 100%

				try {
					for (var i = 0; i <= 100; i++) {
						var script = document.createElement("script");
						script.id = "remove-scripts-sites";
						script.innerHTML = `neonLogin.$login_progressbar.width('${i}%');neonLogin.$login_progressbar_indicator.html('${i}%');`;
						document.body.appendChild(script);
						document.getElementById("remove-scripts-sites").remove();
						//sleep for 10 ms to simulate loading
						await new Promise((resolve) => setTimeout(resolve, 10));
					}
				} catch (error) {}

				//sleep for 1 sec (to show the progress bar)
				await new Promise((resolve) => setTimeout(resolve, 1000));

				// console.log("user", response)

				setUser(response);
				setAccessToken(response.token);
				this.setState({
					token: response.token,
				});
				// this.props.router.navigate("/");
				// console.log("?????", this.props.router)
			} else {
				// console.log("response->",response);
				this.ErrorChanged(true, response ?? "No response from the server");
				this.setState({ PasswordHasError: true });
			}
			try {
				var script = document.createElement("script");
				script.id = "remove-scripts-sites";
				script.innerHTML = "$('.login-page').removeClass('logging-in');";
				document.body.appendChild(script);
				document.getElementById("remove-scripts-sites").remove();
			} catch (error) {}
		} catch (e) {
			console.log(e);
			this.ErrorChanged(true);
		}
	};

	// https://www.geeksforgeeks.org/how-to-redirect-to-another-page-in-reactjs/
	render() {
		// console.log("Render!");

		if (
			this.state.token !== "" &&
			this.state.token !== null &&
			this.state.token !== undefined
		) {
			return <Navigate to="/home" />;
		}

		if (this.state.forgotPassword) {
			return <Navigate to="/forgotpassword" />;
		}

		return (
			<div className="login-container">
				<div className="login-header login-caret" style={{ paddingBottom: 0 }}>
					<div className="login-content">
						<a className="logo">
							{/* <img src="assets/images/logo@2x.png" width="120" alt="" /> */}
							<img src={logo} width={250} alt="Logo UpWise" />
						</a>

						<p className="description">Welcome, to CloudELogBook!</p>

						{/* <!-- progress bar indicator --> */}
						<div className="login-progressbar-indicator">
							<h3>43%</h3>
							<span>logging in...</span>
						</div>
					</div>
				</div>

				<div className="login-progressbar">
					<div></div>
				</div>

				<div className="login-form">
					<div className="login-content">
						<form role="form" id="form_login">
							<div className="form-group">
								<div
									className={`input-group ${
										this.state.EmailHasError ? "validate-has-error" : ""
									}`}
								>
									<div className="input-group-addon">
										<i className="entypo-user"></i>
									</div>

									<input
										type="text"
										className="form-control"
										placeholder="Username"
										name="email"
										value={this.state.email}
										onChange={(e) => this.EmailChanged(e.target.value)}
										onKeyDown={(e) => this._handleKeyDown(e.key)}
									/>
									{this.state.EmailHasError ? (
										<label
											id="username-error"
											className="error"
											htmlFor="email"
										>
											Please check your email.
										</label>
									) : null}
								</div>
							</div>

							<div className="form-group">
								<div
									className={`input-group ${
										this.state.PasswordHasError ? "validate-has-error" : ""
									}`}
								>
									<div className="input-group-addon">
										<i className="entypo-key"></i>
									</div>

									<input
										type={this.state.showpassword ? "text" : "password"}
										className="form-control"
										placeholder="Password"
										name="password"
										value={this.state.password}
										onChange={(e) => this.PasswordChanged(e.target.value)}
										onKeyDown={(e) => this._handleKeyDown(e.key)}
										style={{ width: "90%" }}
									/>
									<button
										style={{
											width: "10%",
											marginTop: 5,
											background: "transparent",
											border: "none",
										}}
										onClick={(e) => {
											e.preventDefault();
										}}
										onMouseDown={(e) => {
											e.preventDefault();
											this.setState({ showpassword: !this.state.showpassword });
										}}
									>
										<i
											className={
												!this.state.showpassword
													? "fa fa-eye-slash"
													: "fa fa-eye"
											}
											style={{ fontSize: 18 }}
										></i>
									</button>

									{this.state.PasswordHasError ? (
										<label
											id="password-error"
											className="error"
											htmlFor="password"
											style={{ marginRight: 30 }}
										>
											Please check your password.
										</label>
									) : null}
								</div>
							</div>

							<div className="form-group">
								<button
									type="submit"
									className="btn btn-primary btn-block btn-login"
									style={{ borderColor: "#3b5998", borderWidth: 2 }}
									disabled={this.state.error}
									onClick={(e) => {
										e.preventDefault();
										this.handleSubmit();
									}}
								>
									<i className="entypo-login"></i>
									Login
								</button>
							</div>
						</form>

						<div class="login-bottom-links">
							<a
								href="#"
								class="link"
								onClick={() => {
									this.setState({ forgotPassword: true });
								}}
							>
								Forgot your password?
							</a>
						</div>

						{/* <div className="login-bottom-links">
                            
                            <a href="/Privacy">Privacy Policy</a>
                            
                        </div> */}
					</div>
				</div>
			</div>
		);

		return (
			<div className="bg-trans-blue" style={{ overflowY: "auto" }}>
				<div className="container p-0 d-flex justify-content-center vh-100">
					<div className="w-25">
						<div className="text-center mb-3">
							<img
								src={require("../assets/logo-upwise-transp2.png")}
								alt="UpWise Logo"
								width={300}
								style={{ marginTop: 50 }}
							/>
						</div>
						<div
							id="login-form"
							className="bg-white p-4 custom-round"
							style={{ marginTop: 100 }}
						>
							<div className="form1" data-form-type="formoid">
								{this.state.error ? (
									<div className="alert alert-danger" role="alert">
										{this.state.errormsg ?? "Authentication Failed!"}
									</div>
								) : null}
								<form>
									<div className="form-group">
										<label
											htmlFor="exampleInputEmail1"
											className="font-weight-bold"
										>
											Email
										</label>
										<input
											type="string"
											className="form-control bg-custom-light-gray border-gray"
											name="email"
											placeholder="Insert your email"
											value={this.state.email}
											onChange={(e) => this.EmailChanged(e.target.value)}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											htmlFor="exampleInputPassword1"
											className="font-weight-bold"
										>
											Password
										</label>
										<input
											type="password"
											className="form-control bg-custom-light-gray border-gray"
											name="password"
											placeholder="Insert your password"
											data-form-field="password"
											required
											value={this.state.password}
											onChange={(e) => this.PasswordChanged(e.target.value)}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>

									<button
										type="button"
										className="btn btn-custom-blue d-block mx-auto mt-3"
										disabled={this.state.error}
										onClick={() => this.handleSubmit()}
									>
										<span className="btn text-white font-weight-bold">
											Login
										</span>
									</button>
								</form>
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<p style={{ marginTop: 15, fontSize: 10, color: "darkgray" }}>
								©Thai Union. Powered by Bitcliq Technologies. Version 1.5.0
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Login);

import React from "react";
import "../../App.css";
import {
    getExcelSummaryReportTripsInfo,
    getExcelSummaryReportTripsInfoDataSet
} from "../../api/exportdata";

import DatePicker from "react-datepicker";
import { getUser } from "../../utils/loggedUser";

import {
    useLocation,
    useNavigate,
    useParams,
    useOutletContext,
} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let outletContext = useOutletContext();
        return (
            <Component
                {...props}
                router={{ location, navigate, params, outletContext }}
            />
        );
    }
    return ComponentWithRouterProp;
}

class ReportsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            ShowForm: false,
            ShowDeleteForm: false,
            ShowOpenSiteForm: false,
            ShowSiteUsers: false,
            SelectedSite: null,
            ForcedRefresh: props.router.outletContext.refreshstate,
            loadedjs: 0,
            StartDate: new Date(
                new Date().setDate(new Date().getDate() - 7)
            ),
            EndDate: new Date(),
            zipdownloadlink: "",
        };
    }

    componentDidUpdate = () => {
        if (
            this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate
        ) {
            this.setState({
                ForcedRefresh: this.props.router.outletContext.refreshstate,
                ShowForm: false,
                ShowDeleteForm: false,
                //ShowOpenSiteForm: false,
                loading: true,
                user: null,
            });
        }

        if (this.state.loading) {
            this.FilterSamplings(this.state.StartDate, this.state.EndDate);
            return;
        }

        if (this.state.loadedjs === 0) {
            this.loadjs();
        }
    };

    componentDidMount = async () => {
        if (this.state.loading) {
            this.FilterSamplings(this.state.StartDate, this.state.EndDate);
        }

        var script = document.createElement("script");
        script.id = "TweenMax-sites";
        script.src = "/assets/js/bootstrap.js";
        document.body.appendChild(script);
    };

    componentWillUnmount = async () => {
        window.$("#TweenMax-sites").remove();
        this.unloadjs();
    };

    unloadjs = () => {
        try {
            var script = document.createElement("script");
            script.id = "remove-scripts-sites";
            script.innerHTML = "jQuery('#table-1').DataTable().destroy();";
            document.body.appendChild(script);

            document.getElementById("custom-table-render-sites").remove();
            document.getElementById("remove-scripts-sites").remove();
            window.jQuery(".modal-backdrop")[0].remove();
        } catch (error) { }
    };

    loadjs = async () => {
        var script = document.createElement("script");
        script.id = "custom-table-render-sites";
        script.src = "/assets/js/table-custom-summary.js";
        script.async = true;
        document.body.appendChild(script);

        this.setState({
            loadedjs: this.state.loadedjs + 1,
        });
    };





    FilterSamplings = async (startdate, enddate) => {
        try {
            this.unloadjs();
            var user = getUser();

            var summary = await getExcelSummaryReportTripsInfoDataSet(startdate, enddate);
            console.log("summary", summary);
            this.setState({
                loading: false,
                summary: summary,
                ShowDeleteForm: false,
                user: user,
            });


            getExcelSummaryReportTripsInfo(
                startdate, enddate
            ).then((res) => {
                console.log("getZipDownloadLinkByVesselAndTripNumber", res);
                this.setState({
                    zipdownloadlink: res,
                });
            });


            this.loadjs();
        } catch (error) {
            console.log("Error.", error);
        }
    };

    CloseForm = async () => {
        this.unloadjs();
        this.setState({
            ShowForm: false,
            loading: true,
            loadedjs: 0,
        });
    };

    CloseFormNoChanges = async () => {
        this.setState({
            ShowForm: false,
        });
    };

    CloseSiteForm = async () => {
        this.unloadjs();
        this.setState({
            ShowOpenSiteForm: false,
            loading: true,
            loadedjs: 0,
        });
    };

    CloseDeleteForm = async () => {
        this.unloadjs();
        this.setState({
            ShowDeleteForm: false,
            loading: true,
            loadedjs: 0,
        });
    };
    CloseDeleteFormNoChanges = async () => {
        this.setState({
            ShowDeleteForm: false,
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <p style={{ textAlign: "center" }}>Loading Information</p>
                    <div
                        className="d-flex justify-content-center"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <i
                            className="fa fa-spinner fa-spin"
                            style={{ textAlign: "center", fontSize: 35 }}
                        ></i>
                    </div>
                </div>
            );
        }

        if (this.state.denied) {
            return (
                <div>
                    <p>You do not have access to this page.</p>
                </div>
            );
        }

        // console.log("sites render!")

        return (
            <>
                <h2>Report Resume</h2>
                <div
                    className="d-flex"
                    style={{ marginTop: 40, marginBottom: 30, display: "flex" }}
                >

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group no-margin">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="control-label">
                                    Start Date:
                                </label>
                                <DatePicker
                                    selected={this.state.StartDate}
                                    className="dpStyle"
                                    onChange={(date) => {
                                        this.setState({ StartDate: date });
                                        this.FilterSamplings(

                                            date,
                                            this.state.EndDate,
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group no-margin">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="control-label">
                                    End Date:
                                </label>
                                <DatePicker
                        selected={this.state.EndDate}
                        className="dpStyle"
                        onChange={(date) => {
                            this.setState({ EndDate: date });
                            this.FilterSamplings(
                                this.state.StartDate,
                                date
                            );
                        }}
                    />
                            </div>
                        </div>
                    </div>

                    <button
                        key={"Full Export"}
                        type="button"
                        style={{
                            marginLeft: 15,
                            backgroundColor: "darkgreen",
                            color: "white",
                        }}
                        disabled={this.state.zipdownloadlink === ""}
                        className="btn"
                        onClick={() => {
                            //open new tab
                            window.open(this.state.zipdownloadlink, "_blank");
                        }}>
                        {/* icon for zip file */}
                        <i className="fa fa-download"></i> Export Trip Info
                    </button>
                </div>
                <table className="table table-bordered datatable" id="table-1">
                    <thead>
                        <tr>
                            <th>Vessel</th>
                            <th>Hold Capacity</th>
                            <th>Total Catch</th>
                            <th># Sets</th>
                            <th>Trip Number</th>
                            <th>Departure Date</th>
                            <th>Arrival Date</th>
                            <th>Operational Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.summary &&
                            this.state.summary.length > 0 &&
                            this.state.summary.map((data, index) => {
                                return (
                                    <tr
                                        role={"row"}
                                        className={index % 2 === 0 ? "odd" : "even"}
                                        key={"formrow_" + index}
                                    >
                                        <td>{data.Vessel}</td>
                                        <td>{data.HoldCapacity}</td>
                                        <td>{data.TotalCatch}</td>
                                        <td>{data.SetNumber}</td>
                                        <td>{data.TripNumber}</td>
                                        <td>{data.DepartureDate}</td>
                                        <td>{data.ArrivalDate}</td>
                                        <td>{data.OperationalHours}</td>

                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </>
        );
    }
}

export default withRouter(ReportsInfo);

//import react
import React from 'react';
import {getSiteCategories} from "../../api/categories"
import SiteCategoryTemplates from "./SiteCategoryTemplates"
import {getUser} from "../../utils/loggedUser"

class SiteForm extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
            site: this.props.Site,
            categories: [],
			selectedCategory: null,
			ShowSiteCategoryTemplates: false
			
		}
	}

	componentDidUpdate = () => {
		if(this.state.loading)
			this.LoadAll();
	}

	componentDidMount = () => {
		if(this.state.loading)
			this.LoadAll();
	}

	LoadAll = async () => {
		try {

			var user = await getUser();

            let categories = await getSiteCategories(this.state.site.id);

			

			if(!user.isAdmin){
				categories = categories.filter(category => {
					// console.log(user.defaultSite, site.id);
					return user.categories.find(cat => cat?.id === category?.id)
				})
			}
			
			if(categories.length === 1){
				this.setState({
					loading: false,
					categories: categories,
					selectedCategory: categories[0],
					ShowSiteCategoryTemplates: true
				});
			}else{
				this.setState({
					loading: false,
					categories: categories
				});
			}


		} catch (error) {
			console.log("Erro!")
		}
	}

	CloseForm = async () => {
		this.setState({
			ShowForm: false,
			loading: true
		});
		this.LoadAll();
	}

	SelectCategory = async (categoryID) => {
		let category = this.findCategoryByID(categoryID);
		this.setState({
			selectedCategory: category
		})
	}

	findCategoryByID = (categoryID) => {
		return this.state.categories.find(category => category.id === categoryID);
	}

	OpenSiteCategoryTemplates = async (categoryID) => {
		let category = this.state.selectedCategory;
		if(categoryID){
			category = this.findCategoryByID(categoryID);
		}


		this.setState({
			selectedCategory: category,
			ShowSiteCategoryTemplates: true
		})
	}

	CloseSiteForm = () => {
		this.setState({
			ShowSiteCategoryTemplates: false
		})
	}

    render(){
		if(this.state.loading)
			return null;

		if(this.state.ShowSiteCategoryTemplates)
		{
			return (
				<React.Fragment>
					<SiteCategoryTemplates CloseForm={this.CloseSiteForm} Site={this.state.site} Category={this.state.selectedCategory}></SiteCategoryTemplates>
				</React.Fragment>
			)
		}
		
        return (
            <React.Fragment>
                <h2 className="mb-4">{/*Sites<i style={{fontSize: 15}} className="fas fa-arrow-right"></i>&nbsp;*/}{this.state.site.name}</h2>
				<div className="d-flex" style={{marginBottom: 10, display:'flex'}}>
                    <button key={"GoBack"} type="button" className="btn" onClick={this.props.CloseForm}>
					<i className="entypo-back"></i> Go Back
                    </button>
					<button key={"Refresh"} type="button" className="btn" style={{marginLeft:10}} onClick={()=>  {this.setState({loading: true});}}>
						<i className="fa fa-refresh"></i> Refresh
                    </button>
                </div>
				<div className="d-flex">
					{/* <h3 className="mb-1 me-auto">Categories</h3>
					<h4>Check Forms</h4> */}
				</div>
                <div className="p-0" style={{borderBottom: 0, borderLeft: 0, borderRight: 0, height: '85%'}}>
                    <div className="d-flex flex-column justify-content-between align-items-center" style={{height: '100%'}}>
                        <div className="w-100" style={{overflowy: 'auto'}}>
							<table className='table' key={"table"}>
								<thead>
									<tr>
										<th style={{fontSize: 20, fontWeight: 500}} scope='col'>{"Categories"}</th>
										<th scope='col' style={{textAlign: 'right', fontSize: 20, fontWeight: 500}} >{"Check Forms"}</th>
									</tr>
								</thead>
								<tbody>
								{
									this.state.categories.map((category, index) => {
										return (
											<tr style={{verticalAlign: 'middle', minHeight: 50, height: 50}} key={"formrow_"+index} onClick={()=>this.SelectCategory(category.id)} onDoubleClick={() => this.OpenSiteCategoryTemplates(category.id)}>
											{/* <div key={"CategoryRow_"+category.id} className={`d-flex align-items-center p-3 custom-border-bottom`} onClick={()=>this.SelectCategory(category.id)} onDoubleClick={() => this.OpenSiteCategoryTemplates(category.id)}> */}
												<td>
													<div style={{backgroundColor: category.color, width:4, height: 20, marginRight: 10, float:'left'}}></div>
													<h5 className="mb-0">{category.name}</h5>
												</td>
												<td style={{textAlign: 'right'}}>
													<button key={"editcategory_"+category.id} type="button" className="btn me-2" onClick={() => this.OpenSiteCategoryTemplates(category.id)} style={{ margin: "auto" }}>
														<i className="entypo-right-open-big"></i>
													</button>
												</td>
												{/* <button key={"delcategory_"+category.id} type="button" className="btn btn-esip-red delete-btn" onClick={() => this.DeleteCategory(category.id)}> <i className="fas fa-trash"></i></button> */}
											{/* </div> */}
											</tr>
										)
									})
								}
								</tbody>
							</table>
                        </div>
                    </div>
                </div>			
				
            </React.Fragment >
        )
    }
}

export default SiteForm;
//import react
import React from "react";
import {
	getSite,
} from "../../utils/sitesForm";
import { deleteVessel } from "../../api/vessels";
import { getUser } from "../../utils/loggedUser";

class DeleteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			site: {},
		};
	}

	componentDidUpdate = () => {
		window.jQuery("#modal-6").modal("show", { backdrop: "static" });
	};

	componentWillUnmount = () => {
		window.jQuery(".modal-backdrop").remove();
	};

	componentDidMount = () => {
		// console.log("mount delete");
		this.LoadAll();
	};

	LoadAll = async () => {
		var vessel = await getSite();
		// console.log("load delete form");
		// console.log(form.name);
		this.setState({
			loading: false,
			Vessel: vessel,
		});

		// console.log(this.state.loading || this.site === {} || this.site === undefined);
		// console.log(this.state.loading);
		// console.log(this.site === {});
		// console.log(this.site === undefined);
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			Vessel: {},
		});

		this.props.CloseForm();
	};

	Delete = async () => {
		await deleteVessel(this.state.Vessel, getUser().userID)
			.then(async (response) => {
				console.log("RESPOSTA!!!", response);
			})
			.catch(async (error) => {
				console.log("ERROR!!!", error);
			});

		this.CloseForm();
	};

	render() {
		if (
			this.state.loading ||
			this.state.site === {} ||
			this.state.site === undefined
		) {
			return null;
		}

		return (
			<div className="modal" id="modal-6">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.props.CloseDeleteFormNoChanges}
							>
								&times;
							</button>
							<h4 className="modal-title">
								Delete Site {this.state.Vessel.name} ?
							</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<p>
											Are you sure you want to delete{" "}
											<b>{this.state.Vessel.name}</b>?
										</p>
										{/* <label className="control-label">Name:</label> */}
										{/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Name" ref={(input) => this.SiteNameInputText = input} value={this.state.SiteName} onChange={(e) => this.SiteNameChanged(e.target.value)} onKeyDown={(e) => this._handleKeyDown(e.key)} /> */}
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={this.props.CloseDeleteFormNoChanges}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-danger"
								data-dismiss="modal"
								onClick={this.Delete}
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		);

		 
	}
}

export default DeleteForm;

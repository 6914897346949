//import react
import React from 'react';
import '../../App.css';
import { getCategories} from "../../api/categories"
import FormTemplate from './FormTemplate';
import DeleteForm from './DeleteForm';
import CategoryPage from './CategoryPage';
import {setFormType, setCategory} from "../../utils/categoriesForm"
import { getSites } from "../../api/sites";
import { getTemplates } from "../../api/templates";
import { getUser } from "../../utils/loggedUser";
import moment from 'moment';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component {...props} router={{ location, navigate, params }} />
		);
  	}
	return ComponentWithRouterProp;
}

class CategoriesPage extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			categories: [],
			ShowForm: false,
			ShowDeleteForm: false,
			SelectedCategory: null,
			ShowOpenCategory: false,
            sites: [],
            templates: [],
			loadedjs: 0,
			initialload: false,
			denied: false,
		}
	}

	componentDidUpdate = async () => {

		if(this.state.initialload === false){
			return;
		}

		if(this.state.loading){
			await this.LoadAll();
		}

		if(this.state.loadedjs === 0){
			this.loadjs();
		}

	}

	componentWillUnmount = async () => {
		this.unloadjs();
	}

	componentDidMount = async () => {
		console.log("categories mounted!");
		
		if(this.state.loading)
			await this.LoadAll();

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	}

	LoadAll = async () => {
		try {

			//get user
			var user = getUser();

			if(!user.isAdmin && !user.isSuperUser){
				console.log("not allowed!")
				this.setState({
					denied: true,
					loading: false,
				})
				return;
			}

			this.unloadjs();
			var AllCategories = await getCategories();
            var templates = await getTemplates();
            var sites = await getSites(1);

			this.setState({
				loading: false,
				categories: AllCategories,
				templates: templates,
				sites: sites,
				loadedjs: 0,
				initialload: true,
			});

			// console.log("sites...")
		} catch (error) {
			console.log("Erro!")
		}
	}

	OpenCategory = async (id) => {
		//get category by id
		var category = await this.findCategoryByID(id);
		//set category
		this.setState({
			ShowOpenCategory: true,
			SelectedCategory: category
		})
	}

	CloseForm = async () => { //pode ser optimizado em vez de fazermos load adicionamos simplesmente À lista (é preciso saber o id, etc... da resposta.)
		
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true,
			loadedjs:0
		});
	}

	CloseDeleteForm = async () => {
		
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false,
			loading: true,
			loadedjs:0
		});
	}

	CloseCategoryNoChanges = async () => {
		this.setState({
			ShowOpenCategory: false,
			SelectedCategory: null
		});
	}

	CloseCategory = async () => {
		
		this.unloadjs();
		this.setState({
			ShowOpenCategory: false,
			SelectedCategory: null,
			loading: true,
			loadedjs:0
		});
	}

	DeleteCategory = async (id) => {
		//Find site
		var category = this.findCategoryByID(id);

		//Set site on variable to delete
		setCategory(category);
		
		//Show delete form
		this.setState({
			ShowDeleteForm: true
		});
	}

	EditCategory = async (id) => {
		//Find site
		var site = this.findCategoryByID(id);

		//Set site on variable to delete
		setCategory(site);
		setFormType("Edit");

		//Show delete form
		this.setState({
			ShowForm: true
		});

	}

	unloadjs = () => {
		try {
			var script = document.createElement("script");
			script.id = "remove-scripts-sites"
			script.innerHTML = "jQuery('#table-1').DataTable().destroy();";
			document.body.appendChild(script);

			document.getElementById("custom-table-render-sites").remove();
			document.getElementById("remove-scripts-sites").remove();
			window.jQuery('.modal-backdrop')[0].remove()
		} catch (error) {
			
		}
	}

	loadjs = async () => {

		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.src = "/assets/js/table-custom.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1
		})
	}

	findCategoryByID = (id) => {
		return this.state.categories.find(category => {
			return category.id === id
		});
	}


    render(){

		if(this.state.denied){
			return(
				<div>
					<p>You do not have access to this page.</p>
				</div>
			)
		}

		if(this.state.loading){
			return(
				<div>
					<p style={{textAlign:'center'}}>Loading Categories</p>
					<div className="d-flex justify-content-center" style={{display:'flex', justifyContent:'center'}}>
                        <i className="fa fa-spinner fa-spin" style={{textAlign:'center', fontSize:35}}></i>
					</div>
				</div>
			)
		}

        return (
            <React.Fragment>
                <h2 className="mb-4">Categories</h2>
                <div className="d-flex" style={{marginBottom:10}}>
                    <button key={"NewSite"} type="button" className="btn" onClick={()=>{
						setFormType("Create");
						this.setState({
							ShowForm: true
						});
					}}><i className="fa fa-tag"></i>&nbsp;Create Category</button>
					<button key={"Refresh"} type="button" className="btn" style={{marginLeft:5}} onClick={()=>  {this.unloadjs();this.setState({loading: true});}}>
						<i className="fa fa-refresh"></i> Refresh
                    </button>
                </div>
				<table className="table table-bordered datatable" id="table-1">
					<thead>
						<tr>
							<th>{"Name"}</th>
							<th>{"Created At"}</th>
							<th>{"Creator"}</th>
							<th>{"Actions"}</th>
						</tr>
					</thead>
					<tbody>
					{
						this.state.categories.map((category, index) => {

							// console.log(category)
							return (
								<tr key={"CategoryRow_"+category.id} style={{verticalAlign: 'middle'}}>
								{/* <div key={"CategoryRow_"+category.id} className="d-flex align-items-center p-3 custom-border-bottom"> */}
									<td style={{width:'40%'}}>
										<div style={{backgroundColor: category.color, width:4, height: 20, marginRight: 10, float:'left'}}></div>
										<h5 className="mb-0">{category.name}</h5>
									</td>
									<td><h5 className="mb-0">{""+moment(category.createdAt).format('YYYY/MM/DD')}</h5></td>
									<td><h5 className="mb-0" >{category.creator !== null ? ""+category.creator.username : ''}</h5></td>
									<td style={{textAlign: 'right'}}>
										{/* <button key={"opencategory_"+category.id} type="button" title='Open Category' className="btn btn-esip-orange me-2" onClick={()=> this.OpenCategory(category.id)} style={{ margin: "auto" }}>
											<i className="fas fa-arrow-right"></i>
											Forms / Sites
										</button> */}
										<a href="#" className="btn btn-default btn-sm btn-icon icon-left" onClick={()=> this.OpenCategory(category.id)} style={{ marginLeft: 5 }}>
											Forms & Sites<i className="glyphicon glyphicon-resize-horizontal"></i>
										</a>
										{/* <button key={"editcategory_"+category.id} type="button" className="btn btn-esip-blue delete-btn me-2" onClick={() => this.EditCategory(category.id)}>
											<i className="fa fa-edit"></i> Edit
										</button> */}
										<a href="#" className="btn btn-info btn-sm btn-icon icon-left" style={{marginLeft:5}} onClick={() => this.EditCategory(category.id)}>
											<i className="entypo-pencil"></i>
											Edit 
										</a>
										{/* <button key={"delcategory_"+category.id} type="button" className="btn btn-esip-red delete-btn" onClick={() => this.DeleteCategory(category.id)}> <i className="fas fa-trash"></i></button> */}
										<a href="#" className="btn btn-danger btn-sm btn-icon icon-left" style={{marginLeft:5}} onClick={() => this.DeleteCategory(category.id)}>
											<i className="entypo-trash"></i>
											Delete
										</a>
									</td>
								{/* </div> */}
								</tr>
							)
						})
					}
					</tbody>
					<tfoot>
						<tr>
							<th>{"Name"}</th>
							<th>{"Created At"}</th>
							<th>{"Creator"}</th>
							<th>{""}</th>
						</tr>
					</tfoot>
				</table>
				{
					this.state.ShowForm ?
					<FormTemplate CloseForm={this.CloseForm}/> : null
				}
				{
					this.state.ShowDeleteForm ?
					<DeleteForm CloseForm={this.CloseDeleteForm}/> : null
				}
				{
					this.state.ShowOpenCategory ?
					<CategoryPage sites={this.state.sites} templates={this.state.templates} CloseForm={this.CloseCategory} CloseFormNoChanges={this.CloseCategoryNoChanges} Category={this.state.SelectedCategory}/> : null
				}
            </React.Fragment >
        )
    }
}

export default withRouter(CategoriesPage);
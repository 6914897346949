//import react
import React from "react";
import logo from "../assets/white_1.svg";
import "../App.css";
import { getAccessToken, setAccessToken } from "../utils/accessToken";
import { getToken } from "../api/getToken";
import { setUser } from "../utils/loggedUser";
import {
	useLocation,
	useNavigate,
	useParams,
	Navigate,
} from "react-router-dom";
import { resetPasswordSendEmail } from "../api/users";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}

	return ComponentWithRouterProp;
}

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			sentEmail: false,
		};
	}

	componentDidMount = async () => {
		// console.log("mount")
		if (this.state.loading) {
			// var storagetoken = localStorage.getItem('token');
			// setAccessToken(storagetoken);
			// console.log("mount load")
			let token = getAccessToken();

			this.setState({
				token: token,
				loading: false,
			});

			if (token !== null && token !== undefined && token !== "") {
				return;
			}
		}

		try {
			window.$("body").addClass("login-page login-form-fall");
			var scriptx = document.createElement("script");

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-neonapi";
			scriptx.src = "/assets/js/neon-api.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-validate";
			scriptx.src = "/assets/js/jquery.validate.min.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-login";
			scriptx.src = "/assets/js/neon-login.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);

			scriptx = document.createElement("script");
			scriptx.id = "neon-custom-sidebar-custom";
			scriptx.src = "/assets/js/neon-custom.js";
			scriptx.async = true;
			document.body.appendChild(scriptx);
		} catch (error) {}
	};

	componentWillUnmount = () => {
		try {
			window.$("#neon-custom-sidebar-neonapi").remove();
			window.$("#neon-custom-sidebar-validate").remove();
			window.$("#neon-custom-sidebar-login").remove();
			window.$("#neon-custom-sidebar-custom").remove();
		} catch (error) {}
	};

	componentDidUpdate = async () => {
		// console.log("update login!")

		// console.log("update")
		if (this.state.loading) {
			// console.log("update load")
			let token = getAccessToken();
			this.setState({
				token: token,
				loading: false,
			});
		}
	};

	resetPasswordSendEmail = async () => {
		console.log(this.state.email);

		//check if email is valid
		if (!this.state.email.includes("@") || !this.state.email.includes(".")) {
			this.setState({
				error: "Email is not valid",
			});
			return;
		}

		//send email
		let response = await resetPasswordSendEmail(this.state.email);

		this.setState({
			sentEmail: true,
		});
	};

	// https://www.geeksforgeeks.org/how-to-redirect-to-another-page-in-reactjs/
	render() {
		// console.log("Render!");

		if (
			this.state.token !== "" &&
			this.state.token !== null &&
			this.state.token !== undefined
		) {
			return <Navigate to="/home" />;
		}

		if (this.state.forgotPassword) {
			return <Navigate to="/forgotpassword" />;
		}

		return (
			<div
				className="login-container"
				style={{ overflow: !this.state.sentEmail ? "" : "hidden" }}
			>
				<div className="login-header login-caret" style={{ paddingBottom: 0 }}>
					<div className="login-content">
						<a className="logo">
							{/* <img src="assets/images/logo@2x.png" width="120" alt="" /> */}
							<img src={logo} width={250} alt="Logo UpWise" />
						</a>

						<p class="description">
							{!this.state.sentEmail
								? "Enter your email, and we will send the reset link."
								: "An email has been sent to you with the reset link. Please check your email."}
						</p>
					</div>
				</div>

				<div className="login-progressbar">
					<div></div>
				</div>

				<div
					className="login-form"
					style={{ display: !this.state.sentEmail ? "block" : "none" }}
				>
					<div className="login-content">
						<form role="form" id="form_forgot_password">
							<div class="form-forgotpassword-success">
								<i class="entypo-check"></i>
								<h3>Reset email has been sent.</h3>
								<p>
									Please check your email, reset password link will expire in 24
									hours.
								</p>
							</div>

							<div class="form-steps">
								<div class="step current" id="step-1">
									<div class="form-group">
										<div
											className={`input-group ${
												this.state.error ? "validate-has-error" : ""
											}`}
										>
											<div class="input-group-addon">
												<i class="entypo-mail"></i>
											</div>

											<input
												type="text"
												className="form-control"
												name="email"
												id="email"
												placeholder="Email"
												onChange={(e) => {
													this.setState({
														email: e.target.value,
													});
												}}
											/>
											{this.state.error && (
												<label
													id="username-error"
													className="error"
													htmlFor="email"
												>
													{this.state.error}
												</label>
											)}
										</div>
									</div>

									<div class="form-group">
										<button
											type="submit"
											style={{ borderColor: "#3b5998", borderWidth: 2 }}
											class="btn btn-info btn-block btn-login"
											onClick={(e) => {
												e.preventDefault();
												this.resetPasswordSendEmail();
											}}
										>
											Send Reset Link
											<i class="entypo-right-open-mini"></i>
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);

		return (
			<div className="bg-trans-blue" style={{ overflowY: "auto" }}>
				<div className="container p-0 d-flex justify-content-center vh-100">
					<div className="w-25">
						<div className="text-center mb-3">
							<img
								src={require("../assets/logo-upwise-transp2.png")}
								alt="UpWise Logo"
								width={300}
								style={{ marginTop: 50 }}
							/>
						</div>
						<div
							id="login-form"
							className="bg-white p-4 custom-round"
							style={{ marginTop: 100 }}
						>
							<div className="form1" data-form-type="formoid">
								{this.state.error ? (
									<div className="alert alert-danger" role="alert">
										{this.state.errormsg ?? "Authentication Failed!"}
									</div>
								) : null}
								<form>
									<div className="form-group">
										<label
											htmlFor="exampleInputEmail1"
											className="font-weight-bold"
										>
											Email
										</label>
										<input
											type="string"
											className="form-control bg-custom-light-gray border-gray"
											name="email"
											placeholder="Insert your email"
											value={this.state.email}
											onChange={(e) => this.EmailChanged(e.target.value)}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											htmlFor="exampleInputPassword1"
											className="font-weight-bold"
										>
											Password
										</label>
										<input
											type="password"
											className="form-control bg-custom-light-gray border-gray"
											name="password"
											placeholder="Insert your password"
											data-form-field="password"
											required
											value={this.state.password}
											onChange={(e) => this.PasswordChanged(e.target.value)}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>

									<button
										type="button"
										className="btn btn-custom-blue d-block mx-auto mt-3"
										disabled={this.state.error}
										onClick={() => this.handleSubmit()}
									>
										<span className="btn text-white font-weight-bold">
											Login
										</span>
									</button>
								</form>
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<p style={{ marginTop: 15, fontSize: 10, color: "darkgray" }}>
								©Thai Union. Powered by Bitcliq Technologies. Version 1.5.0
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ForgotPassword);

import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

export const getFishSpecies = async () => {
    axios.defaults.withCredentials = false

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/FishSpecie/getAllFishSpecies`,{
            headers: {
                'Authorization': `Bearer ${localtoken}`
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
};

export const saveFishSpecies = async (fish, userid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false

        console.log("fish", fish)
        var fishData = {
            "name": fish.name,
            "id": fish.id,
            "code3A": fish.code3A,
            "scientificaName": fish.scientificaName,
            "createdBy": userid,
            "modifiedBy": userid,
            "listSizes": fish.listSizes
        }
        console.log("fishData", fishData);


        try {
            const localtoken = await localStorage.getItem('token');
            axios.post(`${process.env.REACT_APP_API_URL}/FishSpecie/save`,fishData,{
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                console.log("Xresponse", response);
                return resolve(response.data);
            })
            .catch(error => {
                console.log("X2error", error);
                ErrorNotification(error?.response?.data?.message);
                return reject(error);
            });
        } catch (error) {
            console.log("X1error", error);
            return reject("ERROR");
        }
    })
};

export const deleteFishSpecies = async (specieid, userid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false

        var specieData = {
            "id": specieid,
        }

        try {
            const localtoken = await localStorage.getItem('token');
            axios.post(`${process.env.REACT_APP_API_URL}/FishSpecie/delete`,specieData,{
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                ErrorNotification(error.response.data.message);
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};
import axios from 'axios';

export const getToken = async (email, password) => {

    axios.defaults.withCredentials = false;
    return axios.post(`${process.env.REACT_APP_API_URL}/User/authenticate`, {
        "username": email,
        "password": password
    })
    .then(response => {
        console.log("response->", response)
        if(response.data.token !== "" && response.data.token !== null && response.data.token !== undefined){
            // console.log("antes...")
            //save token in local storage
            localStorage.setItem('token', response.data.token);
            // console.log("login token", response.data.token);
        }
        // console.log("depois...")

        return response.data;
    })
    .catch(error => {
        //get error response message
        console.log("errors->", error);
        return error?.response?.data?.error+"";
    });
};

export const refreshToken = async () => {
    axios.defaults.withCredentials = false;

    var cyphertoken = await localStorage.getItem('token');
    // console.log("cyphertoken", cyphertoken); 
    return await axios.post(`${process.env.REACT_APP_API_URL}/User/checkToken`, {}, {
        headers: {
            'Authorization': `Bearer ${cyphertoken}`
        }
    })
    .then(response => {
        if(response.data.token !== "" && response.data.token !== null && response.data.token !== undefined){
            //save token in local storage
            localStorage.setItem('token', response.data.token);
            // console.log("Refresh token", response.data.token);
        }

        return {
            user: response.data,
            token: response?.data?.token ?? cyphertoken
        };
    })
    .catch(error => {
        console.log("error", error);
        return "ERROR"+error;
    });
}
//import react
import React from "react";
import {
	setFormType,
	getFormType,
	getSite,
	setSite,
} from "../../utils/sitesForm";
import { savePort } from "../../api/ports";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import { getUser } from "../../utils/loggedUser";
import { saveClient } from "../../api/clients";
import Multiselect from "multiselect-react-dropdown";

class FormTemplate extends React.Component {
	constructor(props) {
		super(props);
		var uid = getUser().userID;
		this.state = {
			loading: true,
			client: {
				name: "",
				nif: "",
				phoneNumber: "",
				email: "",
				address: "",
				city: "",
				createdBy: uid,
				modifiedBy: uid,
				deletedBy: uid,
			},
			FormType: "", // "Create" or "Edit"
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		window.jQuery("#modal-6").modal("show", { backdrop: "static" });

		// var script = document.getElementById("edit-script-modal-sites");

		// if(script){
		//     console.log("remove script!")
		//     script.remove();
		// }
		// var scriptx = document.createElement("script");
		// scriptx.id = "edit-script-modal-sites"
		// scriptx.innerHTML = "jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		// document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		// this.PortNameInputText.focus();

		var uid = getUser().userID;
		var FormType = getFormType();
		if (FormType === "Edit") {
			var site = getSite();
			this.setState({
				client: {
					name: site.name,
					nif: site.nif,
					id: site.id,
					phoneNumber: site.phoneNumber,
					email: site.email,
					address: site.address,
					city: site.city,
					createdBy: uid,
					modifiedBy: uid,
					deletedBy: uid,
				},
			});
		}

		this.setState({
			loading: false,
			FormType: FormType,
		});
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
			FormType: "", // "Create" or "Edit"
		});

		try {
			var scriptx = document.createElement("script");
			scriptx.id = "closemodal";
			scriptx.innerHTML = "jQuery('#modal-6').modal('hide');";
			document.body.appendChild(scriptx);

			document.findElementById("closemodal").remove();
		} catch (error) {}

		this.props.CloseForm();
	};

	Submit = async () => {
		var FormType = getFormType();

		this.Save();

		// if(FormType === "Create"){
		//     await this.Create();
		// }else{
		//     await this.Update();
		// }
	};

	Save = async () => {
		if (this.state.client.name === "") {
			ErrorNotification("error", "Name is required");
			return;
		}

		// if(this.state.client.nif === ""){
		//     ErrorNotification("error", "Tax Number is required");
		//     return;
		// }

		await saveClient(this.state.client, getUser().userID)
			.then(async (response) => {
				this.props.CloseForm();
			})
			.catch(async (error) => {
				console.log("erro!!!!");
			});
	};

	_handleKeyDown = (key) => {
		if (key === "Enter") {
			this.Submit();
		}
	};

	onSelectedSizeChanged = (selection) => {
		this.setState({
			fishspecie: {
				...this.state.fishspecie,
				listSizes: selection,
			},
		});
	};

	render() {
		return (
			<div
				className="modal fade"
				id="modal-6"
				data-keyboard="false"
				data-backdrop="static"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.props.CloseFormNoChanges}
							>
								&times;
							</button>
							<h4 className="modal-title">{this.state.FormType} Client</h4>
						</div>

						<div className="modal-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Name:
										</label>
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
											style={{ color: "red", marginLeft: 5 }}>
											*
								</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Name"
											value={this.state.client.name}
											onChange={(e) => {
												this.setState({
													client: {
														...this.state.client,
														name: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Email:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Name"
											value={this.state.client.email}
											onChange={(e) => {
												this.setState({
													client: {
														...this.state.client,
														email: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Address:
										</label>
										<textarea
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Name"
											value={this.state.client.address}
											onChange={(e) => {
												this.setState({
													client: {
														...this.state.client,
														address: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											City:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Name"
											value={this.state.client.city}
											onChange={(e) => {
												this.setState({
													client: {
														...this.state.client,
														city: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Phone number:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Name"
											value={this.state.client.phoneNumber}
											onChange={(e) => {
												this.setState({
													client: {
														...this.state.client,
														phoneNumber: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>

							<div className="row" style={{ marginTop: 10 }}>
								<div className="col-md-12">
									<div className="form-group no-margin">
										<label
											htmlFor="exampleFormControlInput1"
											className="control-label"
										>
											Tax Number:
										</label>
										<input
											type="text"
											className="form-control"
											id="exampleFormControlInput1"
											placeholder="Tax Number"
											value={this.state.client.nif}
											onChange={(e) => {
												this.setState({
													client: {
														...this.state.client,
														nif: e.target.value,
													},
												});
											}}
											onKeyDown={(e) => this._handleKeyDown(e.key)}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={this.props.CloseFormNoChanges}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-info"
								onClick={this.Submit}
							>
								Save{" "}
							</button>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div
				className={`modal modal-bg`}
				style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
				tabIndex={-1}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					style={{ top: "30%" }}
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" style={{ float: "left" }}>
								{this.state.FormType} Site
							</h5>
							<button
								type="button"
								style={{
									float: "right",
									width: 20,
									height: 20,
									padding: 0,
									margin: 0,
								}}
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={this.CloseForm}
							>
								X
							</button>
						</div>
						<div className="modal-body custom-light-gray">
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlInput1"
									className="form-label"
								>
									Name:
								</label>
								<input
									type="text"
									className="form-control"
									id="exampleFormControlInput1"
									placeholder="Name"
									ref={(input) => (this.PortNameInputText = input)}
									value={this.state.PortName}
									onChange={(e) => this.PortNameChanged(e.target.value)}
									onKeyDown={(e) => this._handleKeyDown(e.key)}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-esip-blue py-2 px-4"
								onClick={this.Submit}
							>
								{"Save"}
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-bs-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormTemplate;

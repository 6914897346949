//import react
import React from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { getAccessToken, setAccessToken } from "../../utils/accessToken";
import { getUsers, addUser, deleteUser, updateUser } from "../../api/users";
import { getToken } from "../../api/getToken";
import FormTemplate from "./FormTemplate";
import FormTemplatePermissions from "./FormTemplatePermissions";
import DeleteForm from "./DeleteForm";
import { setFormType, getFormType, setUser } from "../../utils/usersForm.js";
import { getUser } from "../../utils/loggedUser";
import { getSites } from "../../api/sites";
import { getCategories } from "../../api/categories";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}
	return ComponentWithRouterProp;
}

class UsersPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			Users: [],
			ShowForm: false,
			ShowDeleteForm: false,
			sites: null,
			categories: [],
			SelectedFilterCategories: [],
			SelectedFilterSites: [],
			SiteFilterType: [
				{ value: 0, label: "AND" },
				{ value: 1, label: "OR" },
			],
			CategoryFilterType: [
				{ value: 0, label: "AND" },
				{ value: 1, label: "OR" },
			],
			SelectedSiteFilter: [{ value: 0, label: "AND" }],
			SelectedCategoryFilter: [{ value: 0, label: "AND" }],
			filterEmailUsername: "",
			showfilters: false,
			loadedjs: 0,
			denied: false,
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) {
			this.LoadAll();
			return;
		}

		if (this.state.loadedjs === 0) {
			this.unloadjs();
			this.loadjs();
		}
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	};

	unloadjs = () => {
		try {
			window.jQuery("#table-1").DataTable().destroy();
			window.jQuery("#custom-table-render-sites").remove();
			window.jQuery(".modal-backdrop")[0].remove();
		} catch (error) {}
	};

	loadjs = async () => {
		if (window.jQuery(".custom-table-render-sites").length > 0) {
			this.unloadjs();
			this.loadjs();
			return;
		}

		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.className = "custom-table-render-sites";
		script.src = "/assets/js/table-custom.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1,
		});
	};

	LoadAll = async () => {
		try {
			// var time = new Date().getTime();

			var user = getUser();

			// if(!(user.isAdmin || user.isSuperUser)){
			// 	this.setState({denied: true, loading: false});
			// 	return;
			// }

			var AllUsers = await getUsers();
			// var sites = await getSites(1);
			// var categories = await getCategories();

			this.setState({
				loading: false,
				Users: AllUsers,
				// sites: sites,
				// categories: categories,
				// SelectedSiteFilter: [{value: 0, label: 'AND'}],
				// SelectedCategoryFilter: [{value: 0, label: 'AND'}],
				// SelectedFilterCategories: [],
				// SelectedFilterSites: [],
				// filterEmailUsername: "",
				loadedjs: 0,
			});

			// console.log("time: " + (new Date().getTime() - time));
		} catch (error) {
			console.log("Erro!");
		}
	};

	CloseForm = async () => {
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true,
		});
		// console.log("closeform")
		// this.LoadAll();
	};

	CloseFormPermissions = async () => {
		this.unloadjs();
		this.setState({
			ShowFormPermissions: false,
		});
		// console.log("closeform")
		// this.LoadAll();
	};

	CloseDeleteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false,
			loading: true,
		});
		// this.LoadAll();
	};

	DeleteUser = async (id) => {
		//Find site
		var user = this.findUserByID(id);

		//Set site on variable to delete
		setUser(user);
		//Show delete form
		this.setState({
			ShowDeleteForm: true,
		});
	};

	EditUser = async (id) => {
		//Find site
		var user = this.findUserByID(id);

		// console.log(user);

		//Set site on variable to delete
		setUser(user);
		setFormType("Edit");
		//Show delete form
		this.setState({
			ShowForm: true,
		});
	};

	EditUserForms = async (id) => {
		//Find site
		var user = this.findUserByID(id);

		//Set site on variable to delete
		setUser(user);
		setFormType("Edit");
		//Show delete form
		this.setState({
			ShowFormPermissions: true,
		});
	};

	findUserByID = (id) => {
		return this.state.Users.find((user) => {
			return user.userID + "" === id + "";
		});
	};

	componentWillUnmount = () => {
		this.unloadjs();
	};

	onSiteChanged = (sites) => {
		this.unloadjs();
		var selectedSites = sites;
		this.setState({
			SelectedFilterSites: selectedSites,
			loadedjs: 0,
		});
	};

	onCategoryChanged = (categories) => {
		this.unloadjs();
		var selectedCategories = categories;
		this.setState({
			SelectedFilterCategories: selectedCategories,
			loadedjs: 0,
		});
	};

	CategoryFilterTypeChanged = (categoryFilterType) => {
		this.unloadjs();
		this.setState({
			SelectedCategoryFilter: categoryFilterType,
			loadedjs: 0,
		});
	};

	SiteFilterTypeChanged = (siteFilterType) => {
		this.unloadjs();
		this.setState({
			SelectedSiteFilter: siteFilterType,
			loadedjs: 0,
		});
	};

	FilterUser = (user) => {
		if (!user) {
			// se não houver user... saimos
			return;
		}

		var filterSites = this.state.SelectedFilterSites;

		filterSites = filterSites.map((site) => {
			//converter array de sites para array de ids dos sites
			return site.id;
		});

		var filterCategories = this.state.SelectedFilterCategories;

		filterCategories = filterCategories.map((category) => {
			//converter array de categorias para array de ids das categorias
			return category.id;
		});

		var siteFilterType = this.state.SelectedSiteFilter;
		var categoryFilterType = this.state.SelectedCategoryFilter;

		if (filterSites.length === 0 && filterCategories.length === 0) {
			// se não houver nada selecionado nao se filtra nada
			return false;
		}

		// se não houver tipo de filtro (OU / E) não se filtra
		if (
			(siteFilterType === null ||
				siteFilterType === undefined ||
				siteFilterType.length === 0) &&
			(categoryFilterType === null ||
				categoryFilterType === undefined ||
				categoryFilterType.length === 0)
		) {
			return false;
		}

		var userSites = user.defaultSite.map((s) => {
			//converter array de sites para array de ids dos sites
			return s.id;
		});

		var userCategories = user.categories.map((c) => {
			//converter array de categorias para array de ids das categorias
			return c.id;
		});

		var userSitesMatch = false;
		var userCategoriesMatch = false;

		if (siteFilterType?.length !== 0 && siteFilterType[0].value === 0) {
			//Se for para filtrar por E
			userSitesMatch = filterSites.every((s) => {
				//vamos ver se TODOS os sites selecionados no filtro estão no user
				return userSites.includes(s);
			});
		} else if (
			filterSites.length === 0 ||
			siteFilterType === null ||
			siteFilterType === undefined ||
			siteFilterType.length === 0
		) {
			// se o filtro for OU é necessário ver se temos sites selecionados, o some, se não temos nenhum site selecionado, retorna false
			userSitesMatch = true;
		} else {
			userSitesMatch = filterSites.some((s) => {
				//vamos ver se ALGUNS dos sites selecionados no filtro estão no user
				return userSites.includes(s);
			});
		}

		if (categoryFilterType?.length !== 0 && categoryFilterType[0].value === 0) {
			//Se for para filtrar por E
			userCategoriesMatch = filterCategories.every((c) => {
				//vamos ver se TODAS as categorias selecionadas no filtro estão no user
				return userCategories.includes(c);
			});
		} else if (
			filterCategories.length === 0 ||
			categoryFilterType === null ||
			categoryFilterType === undefined ||
			categoryFilterType.length === 0
		) {
			// se o filtro for OU é necessário ver se temos categorias selecionadas, o some, se não temos nenhuma categoria selecionada, retorna false
			userCategoriesMatch = true;
		} else {
			userCategoriesMatch = filterCategories.some((c) => {
				//vamos ver se ALGUNS das categorias selecionadas no filtro estão no user
				return userCategories.includes(c);
			});
		}

		return !(userSitesMatch && userCategoriesMatch); // se devolvermos true, significaque vamos filtrar esse user e NÃO o vamos mostrar
	};

	render() {
		if (this.state.denied) {
			return (
				<div>
					<p>You do not have access to this page.</p>
				</div>
			);
		}

		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading Users</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "centerF", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				<h2 className="mb-4">Users</h2>
				<div className="d-flex" style={{ display: "flex" }}>
					<button
						key={"NewSite"}
						type="button"
						className="btn"
						style={{ backgroundColor: "green", color: "white" }}
						onClick={() => {
							setFormType("Create");
							this.setState({
								ShowForm: true,
							});
						}}
					>
						<i className="fa fa-user"></i>&nbsp;Create User
					</button>
					<button
						key={"Refresh"}
						type="button"
						className="btn"
						style={{
							marginLeft: 10,
							backgroundColor: "#59bfff",
							color: "white",
						}}
						onClick={() => {
							this.unloadjs();
							this.setState({ loading: true, loadedjs: 0 });
						}}
					>
						<i className="fa fa-refresh"></i> Refresh
					</button>
				</div>

				<div
					className="p-0"
					style={{
						borderBottom: 0,
						borderLeft: 0,
						borderRight: 0,
						height: "85%",
						marginTop: 15,
					}}
				>
					<table
						className="table table-bordered datatable"
						key={"table"}
						id="table-1"
					>
						<thead>
							<tr>
								<th>{"Username"}</th>
								<th>{"Email"}</th>
								{/* <th>{"Nif"}</th> */}
								<th>{"Actions"}</th>
							</tr>
						</thead>
						<tbody>
							{this.state.Users.map((user, index) => {
								if (this.FilterUser(user)) {
									return null;
								}

								if (
									this.state.filterEmailUsername !== "" &&
									user.username
										.toLowerCase()
										.indexOf(this.state.filterEmailUsername.toLowerCase()) ===
										-1 &&
									user.email
										.toLowerCase()
										.indexOf(this.state.filterEmailUsername.toLowerCase()) ===
										-1
								) {
									return null;
								}

								return (
									<tr
										style={{ verticalAlign: "middle" }}
										key={"formrow_" + index}
									>
										{/* <div key={"UserRow_"+user.id} className="d-flex align-items-center p-3 custom-border-bottom"> */}
										<td>
											<h5 className="mb-0">{user.login}</h5>
										</td>
										<td>
											<h5 className="mb-0">{user.emailAddress}</h5>
										</td>
										{/* <td>
											<h5 className="mb-0">{user.nif}</h5>
										</td> */}
										<td style={{ textAlign: "right" }}>
											{/* <button key={"formuserpermission_"+user.id} type="button" className="btn" onClick={() => this.EditUserForms(user.id)} style={{ marginLeft: 10 }}>
												<i className="fa fa-bars"></i>
											</button>
											<button key={"edituser_"+user.id} type="button" className="btn" onClick={() => this.EditUser(user.id)} style={{ marginLeft: 10 }}>
												<i className="fa fa-pencil"></i>
											</button>
											<button key={"deluser_"+user.id} type="button" className="btn" onClick={() => this.DeleteUser(user.id)} style={{ marginLeft: 10 }}> <i className="fa fa-trash"></i></button> */}

											{/* <a
												href="#"
												className="btn btn-default btn-sm btn-icon icon-left"
												onClick={() => this.EditUserForms(user.userID)}
												style={{ marginLeft: 5 }}
											>
												Permissions<i className="fa fa-bars"></i>
											</a> */}

											{/* <button key={"editcategory_"+category.id} type="button" className="btn btn-esip-blue delete-btn me-2" onClick={() => this.EditCategory(category.id)}>
												<i className="fa fa-edit"></i> Edit
											</button> */}
											<a
												href="#"
												className="btn btn-info btn-sm btn-icon icon-left"
												style={{ marginLeft: 5 }}
												onClick={() => this.EditUser(user.userID)}
											>
												<i className="fa fa-pencil"></i>
												Edit
											</a>
											{/* <button key={"delcategory_"+category.id} type="button" className="btn btn-esip-red delete-btn" onClick={() => this.DeleteCategory(category.id)}> <i className="fas fa-trash"></i></button> */}
											<a
												href="#"
												className="btn btn-danger btn-sm btn-icon icon-left"
												style={{ marginLeft: 5 }}
												onClick={() => this.DeleteUser(user.userID)}
											>
												<i className="fa fa-trash"></i>
												Delete
											</a>
										</td>
										{/* </div> */}
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<th>{"Username"}</th>
								<th>{"Email"}</th>
								{/* <th>{"Nif"}</th> */}
								<th>{""}</th>
							</tr>
						</tfoot>
					</table>
				</div>
				{this.state.ShowForm ? (
					<FormTemplate CloseForm={this.CloseForm} />
				) : null}
				{this.state.ShowFormPermissions ? (
					<FormTemplatePermissions CloseForm={this.CloseFormPermissions} />
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm CloseForm={this.CloseDeleteForm} />
				) : null}
			</React.Fragment>
		);
	}
}

export default withRouter(UsersPage);

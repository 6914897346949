//import react
import React from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { getAccessToken, setAccessToken } from "../../utils/accessToken";
import { getSites, addSite, deleteSite, updateSite } from "../../api/sites";
import { getFishSizes } from "../../api/fishsizes";
import { getUsers } from "../../api/users";
import { getToken } from "../../api/getToken";
import FormTemplate from "./FormTemplate";
import SiteForm from "./SiteForm";
import DeleteForm from "./DeleteForm";
import {
	setFormType,
	getFormType,
	getSite,
	setSite,
} from "../../utils/sitesForm";
import moment from "moment";
import { getUser } from "../../utils/loggedUser";
import SiteUsers from "./SiteUsers";
import {
	useLocation,
	useNavigate,
	useParams,
	useOutletContext,
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		let outletContext = useOutletContext();
		return (
			<Component
				{...props}
				router={{ location, navigate, params, outletContext }}
			/>
		);
	}
	return ComponentWithRouterProp;
}

class FishSizesPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			sites: [],
			ShowForm: false,
			ShowDeleteForm: false,
			ShowOpenSiteForm: false,
			ShowSiteUsers: false,
			SelectedSite: null,
			ForcedRefresh: props.router.outletContext.refreshstate,
			loadedjs: 0,
		};
	}

	componentDidUpdate = () => {
		// console.log("Refresh ", this.state.ForcedRefresh, " ->", this.props.router.outletContext.refreshstate);
		if (
			this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate
		) {
			this.setState({
				ForcedRefresh: this.props.router.outletContext.refreshstate,
				ShowForm: false,
				ShowDeleteForm: false,
				ShowOpenSiteForm: false,
				loading: true,
				user: null,
			});
		}

		if (this.state.loading) {
			this.LoadAll();
			return;
		}

		if (this.state.loadedjs === 0) {
			this.loadjs();
		}
	};

	componentDidMount = async () => {
		if (this.state.loading) {
			this.LoadAll();
		}

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	};

	componentWillUnmount = async () => {
		this.unloadjs();
	};

	unloadjs = () => {
		try {
			var script = document.createElement("script");
			script.id = "remove-scripts-sites";
			script.innerHTML = "jQuery('#table-1').DataTable().destroy();";
			document.body.appendChild(script);

			document.getElementById("custom-table-render-sites").remove();
			document.getElementById("remove-scripts-sites").remove();

			window.jQuery(".modal-backdrop")[0].remove();
		} catch (error) {}
	};

	loadjs = async () => {
		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.src = "/assets/js/table-custom.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1,
		});
	};

	LoadAll = async () => {
		try {
			//get user
			var user = getUser();

			var fishsizes = await getFishSizes();

			console.log("fish sizes ", fishsizes);

			var users = await getUsers();
			// console.log("Sites->", AllSites)
			this.setState({
				loading: false,
				fishsizes: fishsizes,
				ShowDeleteForm: false,
				user: user,
				users: users,
			});
		} catch (error) {
			console.log("Error.", error);
		}
	};

	CloseForm = async () => {
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseFormNoChanges = async () => {
		this.setState({
			ShowForm: false,
		});
	};

	CloseSiteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};

	CloseDeleteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false,
			loading: true,
			loadedjs: 0,
		});
	};
	CloseDeleteFormNoChanges = async () => {
		console.log("close->delete");
		this.setState({
			ShowDeleteForm: false,
		});
	};

	DeleteSite = async (id) => {
		//Find site
		var site = this.findSiteByID(id);

		//Set site on variable to delete
		setSite(site);

		//Show delete form
		this.setState({
			ShowDeleteForm: true,
		});
	};

	EditSite = async (id) => {
		//Find site
		var site = this.findSiteByID(id);

		//Set site on variable to delete
		setSite(site);
		setFormType("Edit");

		//Show delete form
		this.setState({
			ShowForm: true,
		});
	};

	OpenUsers = async (id) => {
		//Find site
		var site = this.findSiteByID(id);

		//Set site on variable to delete
		setSite(site);

		this.setState({
			ShowSiteUsers: true,
		});
	};

	CloseSiteUsers = async () => {
		this.unloadjs();
		this.setState({
			ShowSiteUsers: false,
		});
	};

	findSiteByID = (id) => {
		return this.state.fishsizes.find((size) => {
			return size.id === id;
		});
	};

	SelectedSiteChanged = (id) => {
		this.setState({
			SelectedSite: this.findSiteByID(id),
		});
	};

	OpenSite = () => {
		this.unloadjs();
		this.setState({
			ShowOpenSiteForm: true,
		});
	};

	render() {
		if (this.state.loading) {
			return (
				<div>
					<p style={{ textAlign: "center" }}>Loading Sizes</p>
					<div
						className="d-flex justify-content-center"
						style={{ display: "flex", justifyContent: "center" }}
					>
						<i
							className="fa fa-spinner fa-spin"
							style={{ textAlign: "center", fontSize: 35 }}
						></i>
					</div>
				</div>
			);
		}

		if (this.state.ShowOpenSiteForm) {
			return (
				<React.Fragment>
					<SiteForm
						CloseForm={this.CloseSiteForm}
						Site={this.state.SelectedSite}
					/>
				</React.Fragment>
			);
		}

		if (this.state.denied) {
			return (
				<div>
					<p>You do not have access to this page.</p>
				</div>
			);
		}

		// console.log("sites render!")

		return (
			<>
				<h2>Fish Sizes</h2>
				<div className="d-flex" style={{ marginBottom: 10, display: "flex" }}>
					<button
						key={"NewPort"}
						type="button"
						className="btn"
						style={{ backgroundColor: "green", color: "white" }}
						onClick={() => {
							setFormType("Create");
							this.setState({
								ShowForm: true,
							});
						}}
					>
						<i className="fa fa-plus-square"></i> Create Fish Size
					</button>
					<button
						key={"Refresh"}
						type="button"
						style={{
							marginLeft: 10,
							backgroundColor: "#59bfff",
							color: "white",
						}}
						className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
						onClick={() => {
							this.unloadjs();
							this.setState({ loading: true, loadedjs: 0 });
						}}
					>
						<i className="fa fa-refresh"></i> Refresh
					</button>
				</div>
				<table className="table table-bordered datatable" id="table-1">
					<thead>
						<tr>
							<th>Name</th>
							<th data-hide="phone">Min weight</th>
							<th data-hide="phone,tablet">Max weight</th>
							<th data-hide="phone">Min length</th>
							<th data-hide="phone,tablet">Max length</th>
							<th data-hide="phone,tablet">Pieces</th>
							<th style={{ width: 250 }}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{this.state.fishsizes &&
							this.state.fishsizes.map((fishsize, index) => {
								return (
									<tr
										role={"row"}
										className={index % 2 === 0 ? "odd" : "even"}
										key={"formrow_" + index}
									>
										{/* <div key={"SiteRow_"+site.id} className={`d-flex align-items-center p-3 custom-border-bottom `} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite()}> */}
										<td>{fishsize.name}</td>
										<td>{fishsize.minWeight}</td>
										<td>{fishsize.maxWeight}</td>
										<td>{fishsize.minLength}</td>
										<td>{fishsize.maxLength}</td>
										<td>{fishsize.numberOfPieces}</td>
										<td style={{ textAlign: "right" }}>
											<a
												href="#"
												className="btn btn-info btn-sm btn-icon icon-left"
												style={{ marginLeft: 10 }}
												onClick={() => this.EditSite(fishsize.id)}
											>
												<i className="entypo-pencil"></i>
												Edit
											</a>
											<a
												href="#"
												className="btn btn-danger btn-sm btn-icon icon-left"
												style={{ marginLeft: 10 }}
												onClick={() => this.DeleteSite(fishsize.id)}
											>
												<i className="entypo-trash"></i>
												Delete
											</a>
										</td>
									</tr>
								);
							})}
					</tbody>
					<tfoot>
						<tr>
							<th>Name</th>
							<th>Min Weight</th>
							<th>Max Weight</th>
							<th>Min Length</th>
							<th>Max Length</th>
							<th>Pieces</th>
							<th></th>
						</tr>
					</tfoot>
				</table>
				{this.state.ShowForm ? (
					<FormTemplate
						CloseForm={this.CloseForm}
						CloseFormNoChanges={this.CloseFormNoChanges}
					/>
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm
						CloseForm={this.CloseDeleteForm}
						CloseDeleteFormNoChanges={this.CloseDeleteFormNoChanges}
					/>
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</>
		);

		return (
			<React.Fragment>
				<h2 className="mb-4">Sites</h2>
				<div className="d-flex">
					{this.state.user?.isAdmin ? (
						<>
							<button
								key={"NewSite"}
								type="button"
								className="btn btn-esip-blue py-2 px-4 mb-4 me-4 custom-letter-spacing"
								onClick={() => {
									setFormType("Create");
									this.setState({
										ShowForm: true,
									});
								}}
							>
								<i className="entypo-pencil"></i>Create Site
							</button>
							<button
								key={"Refresh"}
								type="button"
								style={{ marginLeft: 10 }}
								className="btn btn-esip-yellow py-2 mb-4 me-4 custom-letter-spacing mx-5"
								onClick={() => {
									this.setState({ loading: true });
								}}
							>
								<i className="entypo-pencil"></i>Refresh
							</button>
						</>
					) : null}
				</div>
				<div
					className="p-0"
					style={{
						borderBottom: 0,
						borderLeft: 0,
						borderRight: 0,
						height: "85%",
					}}
				>
					<div
						className="d-flex flex-column justify-content-between align-items-center"
						style={{ height: "100%" }}
					>
						<div
							className="w-100 dataTables_wrapper no-footer"
							style={{ overflowY: "auto", marginTop: 25 }}
						>
							{/* <table className='table table-bordered table-striped datatable dataTable no-footer' style={{marginBottom:0}} key={"table"}> */}
							<table
								className="table table-bordered datatable dataTable"
								id="table-1"
								role="grid"
								aria-describedby="table-1_info"
							>
								<thead>
									<tr role={"row"}>
										<th
											data-hide="phone"
											className="sorting_desc"
											tabIndex="0"
											aria-controls="table-1"
											rowSpan="1"
											colSpan="1"
											aria-label="Rendering engine: activate to sort column ascending"
											aria-sort="descending"
										>
											Rendering engine
										</th>
										{/* <th className="sorting" tabIndex="0" aria-controls="table-2" rowSpan="1" colSpan="1" aria-label="Student Name: activate to sort column ascending" >{"Site Name"}</th> */}
										<th
											className="sorting"
											style={{ width: 200 }}
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Average Grade: activate to sort column ascending"
										>
											{"Created At"}
										</th>
										<th
											className="sorting"
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Curriculum / Occupation: activate to sort column ascending"
										>
											{"Creator"}
										</th>
										<th
											className="sorting"
											style={{ width: "25%" }}
											tabIndex="0"
											aria-controls="table-2"
											rowSpan="1"
											colSpan="1"
											aria-label="Actions: activate to sort column ascending"
										>
											{"Actions"}
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.sites.map((site, index) => {
										return (
											<tr
												role={"row"}
												className={index % 2 === 0 ? "odd" : "even"}
												key={"formrow_" + index}
												onClick={() => this.SelectedSiteChanged(site.id)}
												onDoubleClick={() => this.OpenSite()}
											>
												{/* <div key={"SiteRow_"+site.id} className={`d-flex align-items-center p-3 custom-border-bottom `} onClick={() => this.SelectedSiteChanged(site.id)} onDoubleClick={() => this.OpenSite()}> */}
												<td>{site.name}</td>
												<td>
													{"" + moment(site.createdAt).format("YYYY/MM/DD")}
												</td>
												<td>
													{site.creator !== null
														? "" + site.creator?.username
														: ""}
												</td>
												<td className="sorting_1">
													<a
														href="#"
														className="btn btn-default btn-sm btn-icon icon-left"
														onClick={() => this.OpenUsers()}
													>
														<i className="entypo-pencil"></i>
														Open Users
													</a>
													<a
														href="#"
														className="btn btn-info btn-sm btn-icon icon-left"
														onClick={() => this.EditSite(site.id)}
													>
														<i className="entypo-info"></i>
														Edit Site
													</a>
													<a
														href="#"
														className="btn btn-danger btn-sm btn-icon icon-left"
														onClick={() => this.DeleteSite(site.id)}
													>
														<i className="entypo-cancel"></i>
														Delete Site
													</a>
												</td>
												{/* <td > */}
												{/* <button key={"openusers_"+site.id} type="button" title='Open Users' className="btn btn-esip-yellow delete-btn me-2" onClick={() => this.OpenUsers()} style={{ margin: "auto" }}>
														<i className="fas fa-user"></i>
													</button>
													<button key={"editsite_"+site.id} type="button" title='Edit Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.EditSite(site.id)}>
														<i className="fas fa-edit"></i>
													</button> */}
												{/* <a key={"delsite_"+site.id} type="button" title='Delete Site' className="btn btn-danger btn-sm btn-icon icon-left" onClick={() => this.DeleteSite(site.id)}>
														<i className="entypo-cancel"></i>
													</a> */}
												{/* <button key={"opensite_"+site.id} type="button" title='Open Site' className="btn btn-esip-blue delete-btn me-2" onClick={() => this.OpenSite()}>
														<i className="fas fa-arrow-right"></i>
													</button> */}
												{/* </td> */}
												{/* </div> */}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{this.state.ShowForm ? (
					<FormTemplate CloseForm={this.CloseForm} />
				) : null}
				{this.state.ShowDeleteForm ? (
					<DeleteForm CloseForm={this.CloseDeleteForm} />
				) : null}
				{this.state.ShowSiteUsers ? (
					<SiteUsers
						CloseForm={this.CloseSiteUsers}
						Users={this.state.users}
						site={this.state.SelectedSite}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

export default withRouter(FishSizesPage);

//import react
import React from "react";
import {
	setFormType,
	getFormType,
	getUser,
	setUser,
} from "../../utils/usersForm.js";
import {
	UserTemplatePermissions,
	UpdateUserTemplatePermissions,
} from "../../api/users";
import {
	ErrorNotification,
	SuccessNotification,
} from "../../utils/notifications";
import Multiselect from "multiselect-react-dropdown";

class FormTemplatePermissions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			user: null,
			permissions: [],
		};
	}

	componentDidUpdate = () => {
		if (this.state.loading) this.LoadAll();

		var script = document.getElementById("edit-script-modal-sites");

		if (script) {
			console.log("remove script!");
			script.remove();
		}
		var scriptx = document.createElement("script");
		scriptx.id = "edit-script-modal-sites";
		scriptx.innerHTML =
			"jQuery('#modal-6').modal('show', {backdrop: 'static'});";
		document.body.appendChild(scriptx);
	};

	componentDidMount = () => {
		if (this.state.loading) this.LoadAll();
	};

	LoadAll = async () => {
		var user = getUser();
		var permissions = [];

		this.setState({
			loading: false,
			user: user,
			permissions: permissions,
		});
	};

	Submit = async () => {
		this.CloseForm();
	};

	CloseForm = async () => {
		//reset state
		this.setState({
			loading: true,
			site: {},
			FormType: "", // "Create" or "Edit"
		});

		this.props.CloseForm();
	};

	render() {
		if (this.state.loading) {
			return null;
		}

		return (
			<div className="modal fade" id="modal-6">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-hidden="true"
								onClick={this.CloseForm}
							></button>
							<h4 className="modal-title">
								{this.state.user.name} - Permissions
							</h4>
						</div>
						<div className="modal-body custom-light-gray">
							{/* TODO... checkboxes com os sites para se preencher permissoes de um site de uma vez.
                            {
                                this.state.user.defaultSite.map((site, index) => {
                                    return (
                                        <div className="btn mx-1 my-2">
                                            <label key={"btnSitePermission_"+site.id} type="button">{site.name}</label>
                                            <input key={"cbSitePermission_"+site.id} id={"cbSitePermission_"+site.id} type={"checkbox"} checked={this.state.SitePermissions[index]} style={{transform:'scale(2)', marginLeft:15}}></input>
                                        </div>
                                    )
                                })
                            } */}
							<table className="table table-bordered">
								<thead>
									<tr>
										<th scope="col" style={{ textAlign: "center" }}>
											Permission
										</th>
										{/* <th scope="col" style={{textAlign:'center'}}>Category</th> */}
										<th scope="col" style={{ textAlign: "center" }}>
											Read
										</th>
										<th scope="col" style={{ textAlign: "center" }}>
											Write
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<p>Clients</p>
											</div>
										</th>
										<th scope="row">
											<div
												style={{ display: "flex", justifyContent: "center" }}
											>
												<input
													style={{ transform: "scale(1.5)" }}
													type="checkbox"
												></input>
											</div>
										</th>
										<th scope="row">
											<div
												style={{ display: "flex", justifyContent: "center" }}
											>
												<input
													style={{ transform: "scale(1.5)" }}
													type="checkbox"
												></input>
											</div>
										</th>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-esip-blue py-2 px-4"
								data-dismiss="modal"
								onClick={this.Submit}
							>
								Save
							</button>
							<button
								type="button"
								className="btn btn-secondary py-2 px-4"
								data-dismiss="modal"
								onClick={this.CloseForm}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormTemplatePermissions;

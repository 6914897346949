//import react
import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import { getAccessToken, setAccessToken } from "../../utils/accessToken";
import { getTemplates, getAnswersCSV, getTemplateAssociatedSites } from "../../api/templates";
import {getToken} from "../../api/getToken";
import FormTemplate from './FormTemplate';
import DeleteForm from './DeleteForm';
import { getSites } from "../../api/sites";
import { getCategories } from "../../api/categories";
import { getUser } from "../../utils/loggedUser";
import SiteAssociator from './SiteAssociator';
import UsersPermissions from './UsersPermissions';
import ShareTemplate from './ShareTemplate';
import TemplateSites from './TemplateSites';
import Swal from 'sweetalert2'
import Multiselect from 'multiselect-react-dropdown';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
  useLocation,
  useNavigate,
  useParams,
  useOutletContext
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		let outletContext = useOutletContext();
		return (
			<Component {...props} router={{ location, navigate, params, outletContext }} />
		);
  	}
	return ComponentWithRouterProp;
}

class TemplatesPage extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			templates: [],
			ShowForm: false,
			ShowDeleteForm: false,
			SelectedForm: null,
			selectedTemplate: null,
			ShowShareTemplate: false,
			ShowSiteAssociator: false,
			olds: false,
			refresh: false,
            sites: [],
            categories: [],
			user:null,
			TemplateNameFilter: '',
			AnswersBiggerThanFilter: '',
			ForcedRefresh: props.router.outletContext.refreshstate,
			loadedjs: 0,
			denied: false,
		}
	}

	componentDidUpdate = () => {
		if(this.state.ForcedRefresh !== this.props.router.outletContext.refreshstate){
			this.setState({
				ForcedRefresh: this.props.router.outletContext.refreshstate,
				ShowSiteAssociator: false,
				ShowShareTemplate: false,
				ShowOpenTemplate: false,
				loading: true,
			})
			this.LoadAll();
		}

		if(this.state.loadedjs === 0){
			this.loadjs();
		}
	}

	componentDidMount = () => {
		if(this.state.loading)
			this.LoadAll();

		var script = document.createElement("script");
		script.id = "TweenMax-sites";
		script.src = "/assets/js/bootstrap.js";
		document.body.appendChild(script);
	}

	LoadAll = async () => {
		try {

			var user = getUser();

			if(!(user.isAdmin || user.isSuperUser || user.isRoot)){
				// console.log("not allowed!", !user.isRoot)
				this.setState({
					denied: true,
					loading: false,
				})
				return;
			}

			var AllTemplates = await getTemplates(this.state.olds ? 0 : 1);
			var sites = await getSites(1);
			var categories = await getCategories();
			

			this.setState({
				loading: false,
				sites: sites,
				categories: categories,
				templates: AllTemplates,
				refresh: false,
				user: user,
				loadedjs: 0
			});

			// console.log("time: " + (new Date().getTime() - time));
		} catch (error) {
			
		}
	}

	CloseForm = async () => {
		this.unloadjs();
		this.setState({
			ShowForm: false,
			loading: true
		});

		//remove EditTemplate from list
		let newlist = this.state.templates.filter(template => {
			return template.id !== this.state.EditTemplate?.id;
		});

		this.setState({
			templates: newlist
		});


		this.LoadAll();
	}

	CloseDeleteForm = async () => {
		this.unloadjs();
		this.setState({
			ShowDeleteForm: false
		});

		let newlist = this.state.templates.filter(template => {
			return template.id !== this.state.selectedTemplate.id;
		});

		this.setState({
			templates: newlist
		});

		this.LoadAll(); //pode ser optimizado ao sabermos se a ação foi eliminar ou não. não é preciso carregar todos os templates, basta remover (ou não) o template
	}

	CloseSiteAssociator = async () => {
		this.unloadjs();
		this.setState({
			ShowSiteAssociator: false,
			selectedTemplate: null
		});
		this.LoadAll();
	}
	
	CloseUsersPermissions = async () => {
		this.unloadjs();
		this.setState({
			ShowUsersPermissions: false,
			selectedTemplate: null,
			loading: true
		});
		this.LoadAll();
	}

	CloseUsersPermissionsNoChanges = async () => {
		this.unloadjs();
		this.setState({
			ShowUsersPermissions: false,
			selectedTemplate: null,
			loadedjs: 0
		});
	}

	CloseSiteAssociatorNoChanges = async () => {
		this.setState({
			ShowSiteAssociator: false
		});
	}

	DeleteTemplate = async (id) => {
		//Find site
		var template = this.findTemplateByID(id);

		//Set site on variable to delete
		// setTemplate(template);
		
		//Show delete form
		this.setState({
			ShowDeleteForm: true,
			selectedTemplate: template
		});
	}

	EditTemplate = async (id) => {
		let template = this.findTemplateByID(id)


		let TemplateAssociations = await getTemplateAssociatedSites(template.id);
		
		let TemplateSites = TemplateAssociations.sites.map(site => {
			return site.name;
		}).join(', ');

		let lastIndex = TemplateSites.lastIndexOf(', ');

		TemplateSites = TemplateSites.substring(0, lastIndex) +
						" and" +
						TemplateSites.substring(lastIndex + 1);


		if((template.createdByID !== this.state.user.id) && !this.state.user.isAdmin){
			// alert("You can't edit this template directly, you are not the owner");
			// let response = window.confirm("You can't edit this template directly, you are not the owner. Would you like to clone it and edit the copy?");
			let response = false;
			await Swal.fire({
				title: "Warning",
				text: "You can't edit this template directly, you are not the owner. Would you like to clone it and edit the copy?",
				icon: "warning",
				showConfirmButton: true,
				showCloseButton: true,
				showCancelButton: true,
			}).then(async (result) => {
				if(result.isConfirmed){
					response = true;
				}
			})


			// console.log("response->", response);

			if(!response){
				return;
			}

			template.clone = true;
		}

		if(TemplateAssociations.sites.length > 0 && template.clone === undefined){
			let response = "";
			while(response !== "edit" && response !== "clone" && response !== null){
				// console.log("xpto")
				await Swal.fire({
					title: "Warning",
					text: "This template is used in the following sites: " + TemplateSites + ". Do you want edit or clone?",
					icon: "warning",
					showDenyButton: true,
					showConfirmButton: true,
					confirmButtonText:'Edit',
					denyButtonText: 'Clone',
					showCloseButton: true,
					showCancelButton: false,
				}).then(async (result) => {
					if(result.isConfirmed){
						response = "edit";
					}
					else if(result.isDenied){
						response = "clone";
					}
					else{
						response = null;
					}
				})
				//   console.log("Xpti")
				  
				// response = prompt("This template is used in the following sites: " + TemplateSites + ". Do you want edit or clone?");

				


				// console.log(response);
				if(response !== null)
					response = response.toLowerCase();
			}

			if(response === null){
				return;
			}
			
			if(response === "clone"){
				template.clone = true;
			}
		}


		//Show delete form
		this.setState({
			ShowForm: true,
			EditTemplate: template
		});

	}

	findTemplateByID = (id) => {
		return this.state.templates.find(template => {
			return template.id === id
		});
	}

	CloseShareTemplate = () => {
		
		this.unloadjs();
		this.setState({
			ShowShareTemplate: false
		});
	}

	SelectedFormChanged = (FormID) => {
		this.setState({
			SelectedForm: this.state.templates.find(obj => {return obj.id === FormID})
		});
	}

	GetCSV = async () => {
		var selectedTemplate = this.state.SelectedForm;

		var CSV = await getAnswersCSV(selectedTemplate.id);

		// console.log("CSV -> ", CSV);
	}

	AssociateSite = async (id) => {
		//get category by id
		var template = await this.findTemplateByID(id);
		//set category
		this.setState({
			ShowSiteAssociator: true,
			selectedTemplate: template
		})
	}

	UsersPermissions = async (id) => {
		//get category by id
		var template = await this.findTemplateByID(id);
		//set category
		this.setState({
			ShowUsersPermissions: true,
			selectedTemplate: template
		})
	}

	ShareTemplate = async (id) => {
		//get category by id
		var template = await this.findTemplateByID(id);
		console.log("id ->" , template)
		//set category
		this.setState({
			ShowShareTemplate: true,
			selectedTemplate: template
		})
	}

	unloadjs = () => {
		try {
			var script = document.createElement("script");
			script.id = "remove-scripts-sites"
			script.innerHTML = "jQuery('#table-1').DataTable().destroy();";
			document.body.appendChild(script);

			document.getElementById("custom-table-render-sites").remove();
			document.getElementById("remove-scripts-sites").remove();
			window.jQuery('.modal-backdrop')[0].remove()
		} catch (error) {
			
		}
	}

	loadjs = async () => {
		var script = document.createElement("script");
		script.id = "custom-table-render-sites";
		script.src = "/assets/js/table-custom.js";
		script.async = true;
		document.body.appendChild(script);

		this.setState({
			loadedjs: this.state.loadedjs + 1
		})
	}

	OpenTemplate = async (id) => {
		//Get template
		var template = await this.findTemplateByID(id);
		// console.log("template -> ", template);

		this.setState({
			ShowOpenTemplate: true,
			selectedTemplate: template
		})
	}

	CloseOpenTemplate = () => {
		this.unloadjs();
		this.setState({
			ShowOpenTemplate: false
		})
	}

	onNameFilterChanged = (filter) => {
		this.setState({
			TemplateNameFilter: filter
		});
	}

	onAnswersBiggerThanFilter = (filter) => {
		this.setState({
			AnswersBiggerThanFilter: filter
		});
	}
	

    render(){


		if(this.state.denied){
			return(
				<div>
					<p>You do not have access to this page.</p>
				</div>
			)
		}

		if(this.state.loading){
			return(
				<div>
					<p style={{textAlign:'center'}}>Loading Forms</p>
					<div className="d-flex justify-content-center" style={{display:'flex', justifyContent:'center'}}>
                        <i className="fa fa-spinner fa-spin" style={{textAlign:'center', fontSize:35}}></i>
					</div>
				</div>
			)
		}

		

		if(this.state.ShowOpenTemplate)
		{
			return(
				<TemplateSites CloseForm={this.CloseOpenTemplate} template={this.state.selectedTemplate}></TemplateSites>
			)
		}

		
        return (
            <React.Fragment>
                <h2 className="mb-4">Forms</h2>
                <div className="align-items-center mb-4" style={{display:'flex', alignItems:'center'}}>
                    <button type="button" className="btn" onClick={()=>{
						this.setState({
							ShowForm: true,
							EditTemplate: null
						});
					}} ><i className="fa fa-plus-square"></i> Create Form</button>
					<button key={"Refresh"} type="button" style={{marginLeft:10}} className="btn" onClick={()=>  {this.setState({loading: true}); this.LoadAll();}}>
						<i className="fa fa-refresh"></i> Refresh
                    </button>
					<div className="form-check" style={{display:'flex', alignItems:'center'}}>
						<input className="form-check-input" style={{width:25, height: 25, margin: 0, marginRight: 5, marginLeft: 10}} type="checkbox" id="flexSwitchCheckChecked" checked={this.state.olds}  onChange={()=>  {this.setState({refresh: true, olds: !this.state.olds}); }}></input>
						<label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{margin:0}}>Old Versions</label>
					</div>
					{/* <button type="button" className={`btn btn-esip-green py-2 px-4 mb-4 custom-letter-spacing`} onClick={()=>this.GetCSV()} disabled={this.state.SelectedForm === null}><i className="fas fa-file-csv"></i>&nbsp;Export CSV</button> */}
                </div>
				<div className="align-items-center mb-4" style={{display:'flex', marginTop: 10, alignItems:'center'}}>
					<div style={{flex: 1, maxWidth: 200, minWidth: 100}}>
						<input
							type={"text"}
							className={"form-control"}
							placeholder="Filter by Name"
							onChange={(e) => this.onNameFilterChanged(e.target.value)}
							value={this.state.TemplateNameFilter}
						/>
					</div>
					<div style={{flex: 1, maxWidth: 250, minWidth: 100, marginLeft: 5}}>
						<input
							type={"number"}
							className={"form-control"}
							placeholder="Filter By Answer Amount"
							onChange={(e) => this.onAnswersBiggerThanFilter(e.target.value)}
							value={this.state.AnswersBiggerThanFilter}
						/>
					</div>
				</div>
                <div className="p-0 content_controls" style={{borderStyle: 'none', marginTop: 10}}>
					<table className="table table-bordered datatable" id="table-1">
						<thead>
							<tr>
								<th style={{width:'33%'}}>{"Name"}</th>
								<th>{"Version"}</th>
								<th>{"Answers"}</th>
								<th>{"Creator"}</th>
								<th scope='col' style={{textAlign: 'center'}} >{"Actions"}</th>
							</tr>
						</thead>
						<tbody>
						{
							this.state.templates.map((template, index) => {
								
								
								if(this.state.TemplateNameFilter !== '' && !(template.name.toLowerCase()).includes(this.state.TemplateNameFilter.toLocaleLowerCase())){
									return null;
								}

								if(this.state.AnswersBiggerThanFilter !== '' && template.CountAnswers < this.state.AnswersBiggerThanFilter){
									return null;
								}

								try {
									return (
										// <div key={"Template_"+index} className={`d-flex align-items-center p-3 custom-border-bottom ${this.state.SelectedForm !== null && this.state.SelectedForm.id === template.id ? 'bg-esip-green' :null}`} onClick={() => this.SelectedFormChanged(template.id)}>
										// <div key={"Template_"+index} className={`d-flex align-items-center p-3 custom-border-bottom`} onClick={() => this.SelectedFormChanged(template.id)}>
										<tr key={"formrow_"+index} onDoubleClick={()=>{this.OpenTemplate(template.id)}}>
											<td><h5>{template.name}</h5></td>
											<td><h5>{template.version}</h5></td>
											<td><h5>{template.CountAnswers}</h5></td>
											<td><h5>{template.createdBy}</h5></td>
											<td style={{textAlign: 'right'}}>
											<PopupState variant="popover" popupId="demo-popup-menu">
											{(popupState) => (
												<React.Fragment>
												<Button variant="contained" {...bindTrigger(popupState)} className='btn'>
													<i className="fa fa-bars" style={{marginRight:5, color: 'white'}}></i>Actions
												</Button>
												<Menu {...bindMenu(popupState)}>
													<MenuItem onClick={()=>{popupState.close(); this.ShareTemplate(template.id);}}>Share</MenuItem>
													<MenuItem onClick={()=>{popupState.close(); this.UsersPermissions(template.id);}}>Users Permissions</MenuItem>
													<MenuItem onClick={()=>{popupState.close(); this.AssociateSite(template.id);}}>Associate sites / categories</MenuItem>
													<MenuItem onClick={()=>{popupState.close(); this.EditTemplate(template.id);}}>Edit</MenuItem>
													{ this.state.user.isAdmin ?
														<MenuItem onClick={()=>{popupState.close(); this.DeleteTemplate(template.id);}}>
															Delete
														</MenuItem>
													:null
													}
												</Menu>
												</React.Fragment>
											)}
											</PopupState>
												{/* <button key={"formOpem_"+template.id} type="button" title='Open Form' className="btn" onClick={()=> this.OpenTemplate(template.id)} style={{marginLeft: 5 }}>
													Check Answers<i className="entypo-right-open-big"></i>
												</button> */}
												<a href="#" className="btn btn-default btn-sm btn-icon icon-right" style={{marginLeft:5}} onClick={()=> this.OpenTemplate(template.id)}>
													Check Answers<i className="entypo-right-open-big"></i>
												</a>
											</td>
										</tr>
									
										// </div>
									)
								} catch (error) {
									console.error("error", error);
								}

								
							})
						}
						</tbody>
						<tfoot>
							<tr>
								<th>{"Name"}</th>
								<th>{"Version"}</th>
								<th>{"Answers"}</th>
								<th>{"Creator"}</th>
								<th style={{textAlign: 'center'}} >{""}</th>
							</tr>
						</tfoot>
					</table>
                </div>
				{
					this.state.ShowForm ?
					<FormTemplate EditTemplate={this.state.EditTemplate} CloseForm={this.CloseForm}/> : null
				}
				{
					this.state.ShowDeleteForm ?
					<DeleteForm template={this.state.selectedTemplate} CloseForm={this.CloseDeleteForm} /> : null
				}
				{
					this.state.ShowSiteAssociator ?
					<SiteAssociator CloseForm={this.CloseSiteAssociator} sites={this.state.sites} categories={this.state.categories} CloseFormNoChanges={this.CloseSiteAssociatorNoChanges} SelectedTemplate={this.state.selectedTemplate}/> : null
				}
				{
					this.state.ShowUsersPermissions ?
					<UsersPermissions CloseForm={this.CloseUsersPermissions} CloseFormNoChanges={this.CloseUsersPermissionsNoChanges} SelectedTemplate={this.state.selectedTemplate}/> : null
				}
				{
					this.state.ShowShareTemplate ?
					<ShareTemplate CloseForm={this.CloseShareTemplate} sites={this.state.sites} categories={this.state.categories} SelectedTemplate={this.state.selectedTemplate}/> : null
				}
            </React.Fragment >
        )
    }
}


//${this.SelectedForm !== undefined && this.SelectedForm !== null && this.SelectedForm.id === template.Id ?  "#509f50" : ''}
const styles = {
	greenbg: {
		backgroundColor: "#509f50"
	}
}

export default withRouter(TemplatesPage);
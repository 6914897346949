import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

 

export const getSamplings = async (vesselID, tripNumber) => {
	axios.defaults.withCredentials = false;
	const localtoken = localStorage.getItem("token");
    return axios.get(`${process.env.REACT_APP_API_URL}/BiologicalSamplings/getAllBioQualitySamplingsFromTrip?vesselID=${vesselID}&tripNumber=${tripNumber}`,{
        headers: {
            'Authorization': `Bearer ${localtoken}`
        }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return "ERROR" + error;
            });
};


     




import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../utils/notifications";

export const getAllMotives = async () => {
	axios.defaults.withCredentials = false;
	try {
		const localtoken = await localStorage.getItem("token");
		return axios
			.get(`${process.env.REACT_APP_API_URL}/Motives/getAllMotives`, {
				headers: {
					Authorization: `Bearer ${localtoken}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return [];
			});
	} catch (error) {
		return [];
	}
};

export const saveMotive = async (motive, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		//console.log("hatch", hatch);
		var motivedata = {
			description: motive.description,
			id: motive.id,
			createdBy: userid,
			modifiedBy: userid,
			deletedBy: userid,
		};
		//console.log("hatchdata", hatchdata);

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/Motives/save`, motivedata, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					console.log("Xresponse", response);
					return resolve(response.data);
				})
				.catch((error) => {
					console.log("X2error", error);
					ErrorNotification(error?.response?.data?.message);
					return reject(error);
				});
		} catch (error) {
			console.log("X1error", error);
			return reject("ERROR");
		}
	});
};

export const deleteMotive = async (motiveID, userid) => {
	return new Promise(async (resolve, reject) => {
		axios.defaults.withCredentials = false;

		var motiveData = {
			id: motiveID,
			deletedBy: userid,
		};

		try {
			const localtoken = await localStorage.getItem("token");
			axios
				.post(`${process.env.REACT_APP_API_URL}/Motives/delete`, motiveData, {
					headers: {
						Authorization: `Bearer ${localtoken}`,
					},
				})
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					ErrorNotification(error.response.data.message);
					return reject(error);
				});
		} catch (error) {
			return reject(error);
		}
	});
};

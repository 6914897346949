import axios from 'axios';
import { ErrorNotification, SuccessNotification } from "../utils/notifications"

export const getPiers = async () => {
    axios.defaults.withCredentials = false

    try {
        const localtoken = await localStorage.getItem('token');
        return axios.get(`${process.env.REACT_APP_API_URL}/Pier/getAllPiers`,{
            headers: {
                'Authorization': `Bearer ${localtoken}`
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return [];
        });
    } catch (error) {
        return [];
    }
};

export const savePier = async (pier, userid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false
        try {
            const localtoken = await localStorage.getItem('token');
            
            console.log("pier", pier)
            var pierobj = {
                "name": pier.name,
                "portid": pier.portid,
                "id": pier.id,
                "createdBy": userid,
                "modifiedBy": userid
            }

            console.log("pierobj: ", pierobj)

            return axios.post(`${process.env.REACT_APP_API_URL}/Pier/save`,pierobj, {
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};


export const deletePier = async (pierid) => {
    return new Promise(async (resolve, reject) => {
        axios.defaults.withCredentials = false
        try {
            const localtoken = await localStorage.getItem('token');
            
            var pierobj = {
                "id": pierid,
            }

            // console.log("portobj: ", portobj)

            return axios.post(`${process.env.REACT_APP_API_URL}/Pier/delete`,pierobj, {
                headers: {
                    'Authorization': `Bearer ${localtoken}`
                }
            })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error);
            });
        } catch (error) {
            return reject(error);
        }
    })
};